import { useSuspenseQuery } from '@tanstack/react-query';
import { Role } from '../types';
import { PermissionService } from './service';

const queryKey = ['get-executor-role-permissions'];
export const useExecutorRolePermissions = (permissionService: PermissionService, executorRole: Role) => {
  return useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      return permissionService.getExecutorRolePermissions(executorRole);
    },
  });
};
