import { GraphQlRequestService } from '../../../api/graphql-request-service';
import { Granularity } from '../../common/components/timeslider/types';
import { Domains } from '../../constants';
import { DataTypes } from '../../types';
import { Role } from '../types';
import { TimeRange } from './types';

export interface TimeRangesService {
  getTimeRanges: () => Promise<Partial<Record<DataTypes, Record<Granularity, TimeRange>>> | null>;
}

export class BackendTimeRangesService implements TimeRangesService {
  constructor(
    readonly graphQlRequestService: GraphQlRequestService,
    readonly domain: Domains,
    readonly simulateRole: Role | null
  ) {}

  getTimeRanges = async (): Promise<Partial<Record<DataTypes, Record<Granularity, TimeRange>>> | null> => {
    const result = await this.graphQlRequestService.graphQlSdk.availableTimeRanges({
      domain: this.domain,
      simulateRole: this.simulateRole?.id ?? null,
    });
    return result.availableTimeranges
      ? Object.fromEntries(
          result.availableTimeranges.flatMap((atr) => {
            const monthlyTimeRange = atr.availableTimerange.calendarYearMonthly?.map((d) => new Date(d));
            const yearlyTimeRange = atr.availableTimerange.calendarYearYearly;
            const finYearlyTimeRange = atr.availableTimerange.financialYearYearly;
            const finQuarterlyTimeRange = atr.availableTimerange.financialYearQuarterly;
            if (monthlyTimeRange && yearlyTimeRange && finYearlyTimeRange && finQuarterlyTimeRange) {
              return [
                [
                  atr.dataType as unknown as DataTypes,
                  {
                    [Granularity.MONTH]: {
                      min: monthlyTimeRange.first(),
                      max: monthlyTimeRange.last(),
                      all: monthlyTimeRange,
                    },
                    [Granularity.YEAR]: {
                      min: yearlyTimeRange.first(),
                      max: yearlyTimeRange.last(),
                      all: yearlyTimeRange,
                    },
                    [Granularity.FINYEAR]: {
                      min: finYearlyTimeRange.first(),
                      max: finYearlyTimeRange.last(),
                      all: finYearlyTimeRange,
                    },
                    [Granularity.FINQUARTER]: {
                      min: finQuarterlyTimeRange.first(),
                      max: finQuarterlyTimeRange.last(),
                      all: finQuarterlyTimeRange,
                    },
                  },
                ],
              ];
            } else {
              return [];
            }
          })
        )
      : null;
  };
}
