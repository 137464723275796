import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, useLocation } from 'react-router';
import LoadingOverlay from '../../common/components/utils/LoadingOverlay';
import { rootStore } from '../../common/store/root-store';

export const AuthGuard: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  const { isLoggedIn, loading, emailNeedsVerification, isSaml } = rootStore.authStore;
  const location = useLocation();
  if (loading) {
    return <LoadingOverlay />;
  } else if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: location.pathname,
          },
        }}
      />
    );
  } else if (isLoggedIn && emailNeedsVerification && isSaml) {
    return <Redirect to={{ pathname: '/login-error', state: { reason: 'nonVerifiedEmail' } }} />;
  } else {
    return <>{children}</>;
  }
});
