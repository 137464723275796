import i18n from '../../../../i18n';
import { RegretNonRegretValues, VoluntaryInvoluntaryValues } from '../../../common/constants/constant-backend-values';
import { EmployeeDataFields } from '../../../common/constants/constants';
import { AttritionSecondCheckboxConfig, AttritionSecondCheckboxField } from './types';

const regretAttritionCheckboxConfig: AttritionSecondCheckboxConfig = {
  dataField: EmployeeDataFields.REGRET_ATTRITION,
  values: [
    {
      value: 'Total',
      label: i18n.t('pages:dashboards.attrition.dashboard.attritionFactBox.totalRegretNonRegret'),
    },
    {
      value: RegretNonRegretValues.Regret,
      label: i18n.t('pages:dashboards.attrition.dashboard.attritionFactBox.regret'),
    },
    {
      value: RegretNonRegretValues.NonRegret,
      label: i18n.t('pages:dashboards.attrition.dashboard.attritionFactBox.nonRegret'),
    },
  ],
};

const volInvolAttritionCheckboxConfig: AttritionSecondCheckboxConfig = {
  dataField: EmployeeDataFields.VOLUNTARY_OR_INVOLUNTARY_ATTRITION,
  values: [
    {
      value: 'Total',
      label: i18n.t('pages:dashboards.attrition.dashboard.attritionFactBox.totalVolInvol'),
    },
    {
      value: VoluntaryInvoluntaryValues.Voluntary,
      label: i18n.t('pages:dashboards.attrition.dashboard.attritionFactBox.voluntary'),
    },
    {
      value: VoluntaryInvoluntaryValues.Involuntary,
      label: i18n.t('pages:dashboards.attrition.dashboard.attritionFactBox.involuntary'),
    },
  ],
};

export const attritionSecondCheckboxConfigs: Record<AttritionSecondCheckboxField, AttritionSecondCheckboxConfig> = {
  [EmployeeDataFields.REGRET_ATTRITION]: regretAttritionCheckboxConfig,
  [EmployeeDataFields.VOLUNTARY_OR_INVOLUNTARY_ATTRITION]: volInvolAttritionCheckboxConfig,
};
