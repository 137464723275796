import { AuthService } from '../auth/auth-service';
import { CompanyStore } from '../company/company-store';
import { Domains } from '../constants/constants';
import { environmentService } from '../environment/environment-service';
import { GenerateSignedUrlQuery, ListUploadedFilesQuery } from '../graphql/generated/graphql-sdk';
import { GraphQlRequestService } from './graphql-request-service';
import { RequestService } from './request-service';
import { handleGQLErrors } from './utils';
import { rootStore } from '../store/root-store';

export class DataUploadService {
  private graphQlRequestService: GraphQlRequestService;
  private companyStore: CompanyStore;
  private requestService: RequestService;
  private authService: AuthService;

  constructor(
    graphqlRequestService: GraphQlRequestService,
    companyStore: CompanyStore,
    requestService: RequestService,
    authService: AuthService
  ) {
    this.graphQlRequestService = graphqlRequestService;
    this.companyStore = companyStore;
    this.requestService = requestService;
    this.authService = authService;
  }

  public fileUpload = async (domain: string, fileName: string, file: any): Promise<any> => {
    const url = environmentService.formatUrlNewBackend('fileupload');
    const token = await this.authService.getAccessToken();
    url.searchParams.set('domain', domain);
    url.searchParams.set('filename', fileName);
    if (token) {
      return this.requestService.postFile(url.toString(), this.companyStore.domain as Domains, token, file, {
        headers: {},
      });
    } else {
      throw Error('Access Token not found');
    }
  };

  public listUploadedFiles = async (domain: string): Promise<ListUploadedFilesQuery> => {
    return (await handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.listUploadedFiles({ domain, simulateRole: null })
    )) as ListUploadedFilesQuery;
    // TODO: handleGQLErrors shouldn't return ''
  };

  public generateSignedUrl = async (domain: string, objectName: string): Promise<GenerateSignedUrlQuery> => {
    const simulateRole = rootStore.permissionsStore.getSimulateRoleId();
    return (await handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.generateSignedUrl({ domain, objectName, simulateRole })
    )) as GenerateSignedUrlQuery;
  };

  public createBucketWithDefaultStructure = async (domain: string) => {
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.createBucketWithDefaultStructure({ domain, simulateRole: null })
    );
  };

  public deleteUploadedFile = async (domain: string, path: string) => {
    return this.graphQlRequestService.graphQlSdk.deleteUploadedFile({ domain, path, simulateRole: null });
  };
}
