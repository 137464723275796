export enum ErrorTypes {
  AUTHENTICATION = 'authentication',
  REQUEST_PASSWORD_EMAIL_NOT_FOUND = 'requestPasswordEmailNotFound',
  DOMAIN_ACCESS_DENIED = 'domainAccessDenied',
  MINIMUM_PERMISSIONS_NOT_PRESENT = 'minimumPermissionsNotPresent',
  EXECUTOR_ROLE_SETUP_ERROR = 'executorRoleSetupError',
  PERMISSIONS_LOADING_FAILED = 'permissionsLoadingFailed',
  USER_CREATION_FAILED = 'userCreationFailed',
  POTENTIAL_SYNC_ISSUE = 'potentialSyncIssue',
  FETCHING_PERMISSIONS_FOR_ROLES_FAILED = 'fetchingPermissionsForRolesFailed',
  // ^This is sometimes due to backend sync issue due to which roleIds sent in request are not recognized
  // if they were recently created
  METRIC_MISMATCH = 'metricMismatch',
}

export enum ErrorGroups {
  GENERAL = 'general',
  API = 'api',
}
