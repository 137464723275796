export interface Theme {
  font: {
    normal: string;
  };
  fontSize: {
    normal: number;
  };
  color: {
    primary: string;
    secondary: string;
    light: string;
    orange: string;
    clientPrimary: string;
    clientSecondary: string;
    info: string;
  };
}

export const defaultTheme: Theme = {
  font: {
    normal: 'ObjectiveMK1-Rg',
  },
  fontSize: {
    normal: 14,
  },
  color: {
    primary: '#00a599',
    clientPrimary: '#00a599',
    secondary: '#ffffff',
    light: '#ffffff',
    orange: '#f6891f', // Should be standardized later
    clientSecondary: 'rgba(27, 35, 126)',
    info: '#1B716B',
  },
};
