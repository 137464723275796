import { ApiMasterDataQueryMeasure } from '../api/api-interfaces';
import { ManagerOrIcValues } from '../constants/constant-backend-values';
import {
  DataTypes,
  EmployeeDataFields as EDF,
  EmploymentTemporality as ET,
  ManagerDataField,
  Operations,
} from '../constants/constants';
import { dateManagerService } from '../date-manager/date-manager-service';
import { ApiMasterDataQueryFilterItem } from '../v2/types';
import { EmployeeDataFields } from './../constants/constants';

export const getTemporalityFilter = (temporalities: ET[]): ApiMasterDataQueryFilterItem => ({
  dataType: DataTypes.EMPLOYEE,
  operation: Operations.EQUAL,
  property: EDF.EMPLOYMENT_TEMPORALITY,
  values: temporalities,
});

export const PRESENT_TEMPORALITY_FILTER: ApiMasterDataQueryFilterItem = getTemporalityFilter([ET.PRESENT]);

export const PAST_TEMPORALITY_FILTER: ApiMasterDataQueryFilterItem = getTemporalityFilter([ET.PAST]);

export const PAST_PRESENT_TEMPORALITY_FILTER: ApiMasterDataQueryFilterItem = getTemporalityFilter([
  ET.PRESENT,
  ET.PAST,
]);

export const ALL_TEMPORALITY_FILTER: ApiMasterDataQueryFilterItem = getTemporalityFilter([
  ET.PAST,
  ET.PRESENT,
  ET.FUTURE,
]);

export const COUNT_MEASURE: ApiMasterDataQueryMeasure = {
  dataType: DataTypes.EMPLOYEE,
  operation: Operations.COUNT,
  property: EDF.EMPLOYEE_ID,
};

export const VERSION_ID_DIMENSION = { dataType: DataTypes.EMPLOYEE, property: EDF.VERSION_ID };

export const getVersionFilterItems = (date: string): ApiMasterDataQueryFilterItem[] => {
  return [
    {
      operation: Operations.EQUAL,
      property: EmployeeDataFields.VERSION_ID,
      values: [date],
      dataType: DataTypes.EMPLOYEE,
    },
    PRESENT_TEMPORALITY_FILTER,
  ];
};

export const getVersionFilter = (
  date: string,
  operation: Operations = Operations.EQUAL,
  dataType: DataTypes = DataTypes.EMPLOYEE
): ApiMasterDataQueryFilterItem => {
  if (!dateManagerService.parseApiDate(date).isValid()) {
    throw new Error(`Invalid date ${date} provided in getVersionFilter function`);
  }
  return {
    operation,
    property: EmployeeDataFields.VERSION_ID,
    values: [date],
    dataType,
  };
};

export const getVersionsFilter = (
  dates: string[],
  operation: Operations = Operations.EQUAL,
  dataType: DataTypes = DataTypes.EMPLOYEE
): ApiMasterDataQueryFilterItem => {
  dates.forEach((date) => {
    if (!dateManagerService.parseApiDate(date).isValid()) {
      throw new Error(`Invalid date ${date} provided in getVersionFilter function`);
    }
  });
  return {
    operation: operation,
    property: EmployeeDataFields.VERSION_ID,
    values: dates,
    dataType,
  };
};

export const totalPrimaryAttritionFilter = {
  operation: Operations.LESS_THAN_OR_EQUAL_TO,
  property: EmployeeDataFields.DAYS_IN_COMPANY,
  values: ['Total'],
  dataType: EmployeeDataFields.EMPLOYEE_ID,
};

export const getIsNotManagerFilter = (managerProperty: ManagerDataField) => {
  return {
    dataType: DataTypes.EMPLOYEE,
    operation: Operations.EQUAL,
    property: managerProperty,
    values: [ManagerOrIcValues.Ic],
  };
};
