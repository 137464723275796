import { observer } from 'mobx-react';
import React from 'react';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from '../../common/components/utils/LoadingOverlay';
import { rootStore } from '../../common/store/root-store';

export const PermissionsGuard: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  const { permissionsReady, couldNotFetchPermissions } = rootStore.permissionsStore;
  if (couldNotFetchPermissions) {
    return <Redirect to={{ pathname: '/login-error', state: { reason: couldNotFetchPermissions } }} />;
  }

  if (!permissionsReady()) {
    return <LoadingOverlay />;
  } else {
    return <>{children}</>;
  }
});
