import moment from 'moment';
import { rootStore } from '../../store/root-store';
import { Mixpanel } from '../mixpanel';

const getCurrentTime = () => {
  return moment().format('MMMM Do YYYY, h:mm:ss');
};

/**
 * Include with current location for tracking events
 */
export const baseTracker = (event: string, data = {}) => {
  const domain = rootStore.companyStore.domain;
  Mixpanel.people().set({ domain });
  Mixpanel.track(event, {
    ...data,
    timeStamp: getCurrentTime(),
    location: window.location,
    domain,
  });
};

/**
 * Include with current location and current user for tracking events
 */
export const baseAuthedTracker = (event: string, data = {}) => {
  const { currentUser } = rootStore.userStore;

  // Avoid spamming with techuser events
  if (currentUser?.email !== 'techuser@panalyt.com') {
    baseTracker(event, {
      ...data,
      $email: currentUser?.email,
    });
  }
};
