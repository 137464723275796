import { observer } from 'mobx-react';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import LoadingOverlay from './common/components/utils/LoadingOverlay';
import { publicRoutes } from './common/routes';
import { rootStore } from './common/store/root-store';
import { PrivateRoutes } from './private-routes/PrivateRoutes';

export const RenderRoutes = observer(() => {
  const location = useLocation();
  const [isPublicRoute, setIsPublicRoute] = useState(true);

  useEffect(() => {
    setIsPublicRoute(publicRoutes.some((route) => route.path?.includes(location.pathname)));
  }, [location.pathname]);

  useEffect(() => {
    if (isPublicRoute) {
      rootStore.intercomStore.updateSettings({ hide_default_launcher: false });
    } else {
      rootStore.intercomStore.updateSettings({ hide_default_launcher: true });
    }
  }, [isPublicRoute]);

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Switch>
        {publicRoutes.map((route, i) => (
          <Route key={i} {...route} />
        ))}
        <PrivateRoutes />
      </Switch>
    </Suspense>
  );
});
