import InfoIcon from '@mui/icons-material/Info';
import { Checkbox, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mediumGray, teal2 } from '../theme/color-constants';

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: unset;
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${teal2};
`;

export const StyledInfoIcon = styled(InfoIcon)`
  color: ${mediumGray};
  font-size: 1.4em;

  &:hover {
    color: ${teal2};
  }
`;

export const WhiteTextField = styled(TextField)`
  input {
    color: #fff;
  }
  & .MuiInput-underline::before {
    border-bottom-color: #fff;
  }
  & .MuiInput-underline::after {
    border-bottom-color: #fff;
  }
  & .MuiInput-underline:hover::before {
    border-bottom-color: #fff;
  }
`;
