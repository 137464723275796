import { useSuspenseQuery } from '@tanstack/react-query';
import { BackendCompanyService } from './service';

const queryKey = ['get-domains'];
export const useDomains = (companyService: BackendCompanyService) => {
  return useSuspenseQuery({
    queryKey,
    queryFn: () => {
      return companyService.getDomains();
    },
  });
};
