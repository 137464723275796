import { getFilterKey } from '../api/utils';
import { Filter } from '../common/components/filter/filterbar/types';
import { Domains } from '../constants';
import { DataTypes, EmployeeDataFields, Operations } from '../types';

export const domainToDefaultFiltersMap: Partial<Record<Domains, Filter[]>> = {
  [Domains.MIXI_COMPANY_WIDE]: [
    {
      key: getFilterKey({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE }, ['正社員']),
      property: EmployeeDataFields.EMPLOYMENT_TYPE,
      operation: Operations.EQUAL,
      values: [['正社員']],
      dataType: DataTypes.EMPLOYEE,
      label: '正社員',
    },
    {
      key: getFilterKey({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE }, [
        '正社員（出向）',
      ]),
      property: EmployeeDataFields.EMPLOYMENT_TYPE,
      operation: Operations.EQUAL,
      values: [['正社員（出向）']],
      dataType: DataTypes.EMPLOYEE,
      label: '正社員（出向）',
    },
  ],
  [Domains.J_OILMILLS]: [
    {
      key: getFilterKey({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION }, [
        '(株)J-ｵｲﾙﾐﾙｽﾞ',
      ]),
      dataType: DataTypes.EMPLOYEE,
      property: EmployeeDataFields.ORGANIZATION,
      operation: Operations.EQUAL,
      values: [['(株)J-ｵｲﾙﾐﾙｽﾞ']],
      label: '(株)J-ｵｲﾙﾐﾙｽﾞ',
    },
    {
      key: getFilterKey({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION }, [
        '株式会社J-パック',
      ]),
      dataType: DataTypes.EMPLOYEE,
      property: EmployeeDataFields.ORGANIZATION,
      operation: Operations.EQUAL,
      values: [['株式会社J-パック']],
      label: '株式会社J-パック',
    },
    {
      key: getFilterKey({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION }, ['JNP']),
      dataType: DataTypes.EMPLOYEE,
      property: EmployeeDataFields.ORGANIZATION,
      operation: Operations.EQUAL,
      values: [['JNP']],
      label: 'JNP',
    },
    {
      key: getFilterKey({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION }, ['JWS']),
      dataType: DataTypes.EMPLOYEE,
      property: EmployeeDataFields.ORGANIZATION,
      operation: Operations.EQUAL,
      values: [['JWS']],
      label: 'JWS',
    },
  ],
};
