import { observer } from 'mobx-react';
import React from 'react';
import { useLocation } from 'react-router';
import { rootStore } from '../../store/root-store';
import MainContentFallbackComponent from './MainContentErrorFallback';

/**
 * This guard should be used for errors that are on the main-content level, i.e. not impacting the header and related elements (such as filtertray)
 * but the main content window such as the whole dashboard view, the whole permission ui view etc.
 * It should optimally not be used if only *parts* of the views above are not working/functional.
 */
export const MainContentGuard: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  const location = useLocation();

  // Currently we only show this inline-error on dashboard-pages and mypanalyt. At some point, this should be abstracted and used more generally
  const IsMainContentMyPanalytOrDashboard =
    location.pathname.startsWith('/my-panalyt') || location.pathname.startsWith('/dashboard');

  if (IsMainContentMyPanalytOrDashboard) {
    if (rootStore.employeeDataStore.oldestStartDateNotFound) {
      return MainContentFallbackComponent('noOldestStartDateFound'); // Missing data
    } else if (rootStore.permissionsStore.permissionsError) {
      return MainContentFallbackComponent('insufficientPermissions'); // Missing permissions
    } else if (rootStore.domainDependencyStore.couldNotFetchDomainDependencies) {
      return MainContentFallbackComponent(rootStore.domainDependencyStore.couldNotFetchDomainDependencies);
    } else {
      return <>{children}</>;
    }
  } else {
    return <>{children}</>;
  }
});
