import { User } from 'firebase/auth';
import { ApiUser } from '../../api/api-interfaces';
import { Mixpanel } from '../mixpanel';
import { baseAuthedTracker, baseTracker } from './baseTracker';

/**
 * Firebase user actions
 */

export const trackFirebaseLogin = (user: User, userInfo: ApiUser | null) => {
  const { email, displayName } = user;

  if (email) {
    Mixpanel.register({ TokenEmail: email, $name: displayName });
    Mixpanel.identify(email);
    baseTracker('[User] Signed In (3rd party Auth)', {
      uid: user.email,
      $email: userInfo?.email ?? user.email,
    });
  }
};

export const trackFirebaseLogout = (user: User) => {
  if (user) {
    baseAuthedTracker('[User] Signed Out (3rd party Auth)', {
      uid: user.email,
    });
  }
};

/**
 * App user actions. Difference from Firebase user actions is that e.g. a firebase login might succeed but the app doesn't know the user so the app login fails.
 */

export const trackAppLogin = (email: string | undefined) => {
  if (email) {
    Mixpanel.register({ TokenEmail: email });
    Mixpanel.identify(email);
    baseTracker('[User] Signed In', {
      uid: email,
      $email: email,
    });
  }
};
