import { ReadData } from '../../../graphql/generated/graphql-sdk';
import { Domains } from '../../constants';
import { DataFields, DataFieldWithDataType } from '../../types';
import { AssignedPermissions, Permission, PermissionTypes } from '../permission/types';
import { getFilterFieldFromTag } from '../permission/utils';
import { isHierarchical } from '../utils';

export interface AuthorizationService {
  isFieldVisible: (field: DataFieldWithDataType) => boolean;
  isFilterAllowed: (field: DataFieldWithDataType) => boolean;
  isFieldAllowedForExecutorRole: (field: DataFieldWithDataType) => boolean;
}

export class BackendAuthorizationService implements AuthorizationService {
  constructor(readonly domain: Domains, readonly executorRolePermissions: AssignedPermissions<Permission>) {}

  public isFieldVisible = (field: DataFieldWithDataType): boolean => {
    if (isHierarchical(field)) {
      return this.isFieldAllowedForExecutorRole({
        dataType: field.dataType,
        dataField: `${field.dataField}_LEVEL_1` as DataFields,
      });
    } else {
      return this.isFieldAllowedForExecutorRole(field);
    }
  };

  public isFilterAllowed = (field: DataFieldWithDataType): boolean => {
    const { dataType, dataField } = field;
    const useFilterPermission = this.executorRolePermissions
      .validFor(this.domain)
      .filterByType(PermissionTypes.UseFilters)
      .first()?.permission;

    if (!useFilterPermission) {
      return false;
    } else {
      return (
        useFilterPermission.allowedTags
          ?.map((t) => getFilterFieldFromTag(t))
          .deepCompareContains({ dataType, dataField }) ?? true
      );
    }
  };

  public isFieldAllowedForExecutorRole = (field: DataFieldWithDataType): boolean => {
    return this.isFieldAllowedInPermission(
      field,
      this.executorRolePermissions.filterByType(PermissionTypes.ReadData).first()?.permission
    );
  };

  private isFieldAllowedInPermission = (field: DataFieldWithDataType, permission?: ReadData) => {
    const hasFullAccess = permission?.access === null ?? false;
    const hasSpecificFieldAccess =
      permission?.access?.some(
        (a) =>
          a.datatype === field.dataType &&
          (a.fields?.specificDatafields?.some((f) => f.datafield === field.dataField) ?? false)
      ) ?? false;
    return hasFullAccess || hasSpecificFieldAccess;
  };
}
