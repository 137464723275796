import { RegretNonRegretValues, VoluntaryInvoluntaryValues } from '../../../common/constants/constant-backend-values';
import { EmployeeDataFields } from '../../../common/constants/constants';

export type AttritionSecondCheckboxField =
  | EmployeeDataFields.REGRET_ATTRITION
  | EmployeeDataFields.VOLUNTARY_OR_INVOLUNTARY_ATTRITION;

export interface AttritionCheckboxValue {
  value: string;
  label: string;
}

export type AttritionSecondCheckboxFieldValues = [
  AttritionCheckboxValue,
  AttritionCheckboxValue,
  AttritionCheckboxValue
];

export interface AttritionSecondCheckboxConfig {
  dataField: AttritionSecondCheckboxField;
  values: AttritionSecondCheckboxFieldValues;
}

export const attritionTypes = {
  TOTAL: 'Total',
  THREE_MONTH: '3 Month',
  ONE_YEAR: '1 Year',
  THREE_YEAR: '3 Year',
  REGRET: RegretNonRegretValues.Regret,
  NON_REGRET: RegretNonRegretValues.NonRegret,
  VOLUNTARY: VoluntaryInvoluntaryValues.Voluntary,
  INVOLUNTARY: VoluntaryInvoluntaryValues.Involuntary,
};

export enum AttritionTypes {
  ANNUALISED_ATTRITION = 'annualisedAttrition',
  MONTHLY_ATTRITION = 'monthlyAttrition',
  TTM_ATTRITION = 'trailingTwelveMonthsAttrition',
  STARTING_POP_ATTRITION = 'startingPopAttrition',
}
