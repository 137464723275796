import { observer } from 'mobx-react';
import React from 'react';
import { Redirect } from 'react-router';
import { DomainSelector } from '../../common/components/utils/DomainSelector';
import { rootStore } from '../../common/store/root-store';

export const DomainGuard: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  const { domain, domainSettingsError } = rootStore.companyStore;
  if (domainSettingsError) {
    return <Redirect to="/setup-error" />;
  }
  if (!domain) {
    return <DomainSelector />;
  } else {
    return <>{children}</>;
  }
});
