import { action, autorun, observable } from 'mobx';
import moment from 'moment';
import { compareFilterToDim, DataFieldWithDataType } from '../../../common-types';
import { ApiMasterDataAdvancedQuery } from '../api/api-interfaces';
import { BenchmarkStore } from '../benchmark/benchmark-store';
import { InternalBenchmarkTypes } from '../benchmark/types';
import { DataTypes, EmployeeDataFields } from '../constants/constants';
import { Granularity, Months } from '../date-manager/date-manager-constants';
import { localStore } from '../local-store';
import { GetResponseForAdvancedQueryService } from '../services/GetResponseForQueryService';
import { DomainDependencyStore } from '../startup/domain-dependency-store';
import { TimeSliderStore } from '../timeslider/timeslider-store';
import { ApiMasterDataQueryFilterItem } from '../v2/types';
import { getVersionFilter } from './common-filters';

export type NonHierarchicalFilterValue = string | null;

export type HierarchicalFilterValue = NonHierarchicalFilterValue[];

export type FilterValue = HierarchicalFilterValue | NonHierarchicalFilterValue;

export type PrefetchedEmployeeFields =
  | EmployeeDataFields.EMPLOYEE_ID
  | EmployeeDataFields.COMPANY_EMAIL
  | EmployeeDataFields.FULL_NAME
  | EmployeeDataFields.LOCAL_FULL_NAME
  | EmployeeDataFields.MANAGER_ID
  | EmployeeDataFields.JOB_TITLE
  | EmployeeDataFields.ORGANIZATION_LEVEL_1
  | EmployeeDataFields.ORGANIZATION_LEVEL_2
  | EmployeeDataFields.ORGANIZATION_LEVEL_3
  | EmployeeDataFields.LOCATION_LEVEL_1
  | EmployeeDataFields.LOCATION_LEVEL_2
  | EmployeeDataFields.LOCATION_LEVEL_3
  | EmployeeDataFields.FUNCTION_LEVEL_1
  | EmployeeDataFields.FUNCTION_LEVEL_2
  | EmployeeDataFields.FUNCTION_LEVEL_3;

type PrefetchedEmployeeData = Record<string, Record<PrefetchedEmployeeFields, string | number>>;

export interface TimeSliderConfig {
  startDate: string;
  endDate: string;
  granularity: Granularity;
  filterItemProperty?: (startDate: moment.Moment, endDate: moment.Moment) => ApiMasterDataQueryFilterItem[];
  rangeStartDate: string | null;
  rangeEndDate: string | null;
  specificPeriodBenchmarkStartDate: string | null;
  specificPeriodBenchmarkEndDate: string | null;
  firstMonthOfYear: Months;
}
export interface PeriodRange {
  startDate: string | null;
  endDate: string | null;
}

export class FilterStore {
  @observable
  public benchmarkFilters: ApiMasterDataQueryFilterItem[] = [];

  @observable
  public employeeData: PrefetchedEmployeeData | null = null;

  private benchmarkStore: BenchmarkStore;
  private timeSliderStore: TimeSliderStore;
  private domainDependencyStore: DomainDependencyStore;

  public constructor(
    benchmarkStore: BenchmarkStore,
    timeSliderStore: TimeSliderStore,
    domainDependencyStore: DomainDependencyStore
  ) {
    this.benchmarkStore = benchmarkStore;
    this.timeSliderStore = timeSliderStore;
    this.domainDependencyStore = domainDependencyStore;

    let firstRun = true;
    autorun(() => {
      const storeData = JSON.stringify({
        timeSliderConfig: this.timeSliderStore.timeSliderConfig,
      });

      if (!firstRun) {
        localStore.setAsync('filterStore', storeData);
      }
      firstRun = false;
    });
  }

  @action
  public loadEmployeeData = async () => {
    const latestVersion = this.domainDependencyStore.getLatestVersion(DataTypes.EMPLOYEE);
    if (!latestVersion) {
      throw new Error(`latest version not found for datatype employee`);
    }
    const employeeDataQuery: ApiMasterDataAdvancedQuery = {
      dataType: DataTypes.EMPLOYEE,
      dimensions: [
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.EMPLOYEE_ID },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.COMPANY_EMAIL },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.MANAGER_ID },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FULL_NAME },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCAL_FULL_NAME },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.JOB_TITLE },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ORGANIZATION_LEVEL_2 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ORGANIZATION_LEVEL_3 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCATION_LEVEL_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCATION_LEVEL_2 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCATION_LEVEL_3 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FUNCTION_LEVEL_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FUNCTION_LEVEL_2 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FUNCTION_LEVEL_3 },
      ],
      filterItems: [getVersionFilter(latestVersion)],
    };
    const result = await GetResponseForAdvancedQueryService(employeeDataQuery);
    const employeeData = result?.dataPoints?.reduce((acc: PrefetchedEmployeeData, dp) => {
      const key: string = dp.dimensions[0].value;
      (acc[key] as any) = Object.fromEntries(dp.dimensions.map((dim) => [dim.property, dim.value]));
      return acc;
    }, {});
    this.employeeData = employeeData;
  };

  @action
  public applyDrillDownFilters({
    filters,
    baseDim,
    addMultipleFiltersV2,
  }: {
    filters: ApiMasterDataQueryFilterItem[];
    baseDim: DataFieldWithDataType;
    afterApplyFilters?: any;
    addMultipleFiltersV2?: (filters: ApiMasterDataQueryFilterItem[]) => void;
  }) {
    const { selectedBenchmark } = this.benchmarkStore;
    const applyBenchmarkFilters = selectedBenchmark && selectedBenchmark.value === InternalBenchmarkTypes.MyPop;

    if (addMultipleFiltersV2) {
      addMultipleFiltersV2(filters);
    }

    if (applyBenchmarkFilters) {
      this.benchmarkFilters = this.benchmarkFilters.filter((f) => !compareFilterToDim(f, baseDim));
      this.benchmarkFilters = [...this.benchmarkFilters, ...filters];
    }
  }
}
