import { DataFieldWithDataType } from '../../../common-types';
import {
  MetricCategoryId,
  MetricFormatTypeId,
  MetricGroupId,
  MetricId,
  MetricType,
} from '../graphql/generated/graphql-sdk';

export enum MetricLifecycleStageEnum {
  MetricLifecycleStageActive = 'MetricLifecycleStageActive',
  MetricLifecycleStageDeprecated = 'MetricLifecycleStageDeprecated',
  MetricLifecycleStageDevelopment = 'MetricLifecycleStageDevelopment',
  MetricLifecycleStageDisabled = 'MetricLifecycleStageDisabled',
}

export interface MetricDetails {
  id: MetricId;
  metricGroupId: MetricGroupId;
  metricType: MetricType;
  formatType: MetricFormatTypeId;
  underlyingFields: DataFieldWithDataType[] | null;
  defaultPosition: number;
  segmentationPercAllowed: boolean;
  lifecycleStage: MetricLifecycleStageEnum | undefined;
}

export interface MetricGroupDetails {
  id: MetricGroupId;
  defaultCategory: MetricCategoryId;
  dimensions: MetricDetails[];
  defaultPosition: number;
  defaultCategoryPosition: number;
}
