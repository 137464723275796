import * as Sentry from '@sentry/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DOMAIN_KEY } from './common/api/api-interfaces';
import { environmentService } from './common/environment/environment-service';
import { rootStore } from './common/store/root-store';
import FallbackComponent from './ErrorFallback';

export const ErrorBoundary = (props: { children?: React.ReactNode; tags?: Record<string, string | null> }) => {
  const { t } = useTranslation();
  const { authStore, userStore } = rootStore || {};
  const { currentUser: authCurrentUser } = authStore || {};
  const { currentUser } = userStore || {};
  const domain = localStorage.getItem(DOMAIN_KEY);
  const subDomain = environmentService.getSubDomain();

  const dialogOptions = {
    title: t('pages:errorBoundary.dialog.title'),
    subtitle: t('pages:errorBoundary.dialog.subtitle'),
    subtitle2: t('pages:errorBoundary.dialog.subtitle2'),
    labelName: t('pages:errorBoundary.dialog.labelName'),
    labelEmail: t('pages:errorBoundary.dialog.labelEmail'),
    labelComments: t('pages:errorBoundary.dialog.labelComments'),
    labelClose: t('pages:errorBoundary.dialog.labelClose'),
    labelSubmit: t('pages:errorBoundary.dialog.labelSubmit'),
    errorGeneric: t('pages:errorBoundary.dialog.errorGeneric'),
    errorFormEntry: t('pages:errorBoundary.dialog.errorFormEntry'),
    successMessage: t('pages:errorBoundary.dialog.successMessage'),
    user:
      currentUser && authCurrentUser
        ? {
            email: currentUser.email ?? authCurrentUser.email ?? 'UNKNOWN',
            name: authCurrentUser.displayName ?? 'UNKNOWN',
          }
        : {},
  };

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setUser({
          ...scope.getUser(),
          email: currentUser?.email ?? authCurrentUser?.email ?? 'UNKNOWN',
          username: authCurrentUser?.displayName ?? undefined,
        });
        scope.setTag('panalyt.errorType', 'errorBoundary');
        scope.setTag('panalyt.domain', domain ?? 'UNKNOWN');
        scope.setTag('panalyt.subDomain', subDomain);
        scope.setTags(props.tags ?? {});
      }}
      fallback={<FallbackComponent />}
      showDialog
      dialogOptions={dialogOptions}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
};
