import { ApiHierarchyItem } from '../api/api-interfaces';
import { isNullValue } from './validators';

export enum Ordering {
  GT = 1,
  LT = -1,
  EQ = 0,
}

export interface Ord<A> {
  compare(x: A, y: A): Ordering;
}

export const apiHierarchyItemOrd: Ord<ApiHierarchyItem> = {
  compare: (a: ApiHierarchyItem, b: ApiHierarchyItem): Ordering => {
    if (isNullValue(a.name)) {
      return Ordering.GT;
    } else if (isNullValue(b.name)) {
      return Ordering.LT;
    } else {
      // Configuration for Natual ordering of strings
      // So [x1,x11,x12,x2,x3] => [x1,x2,x3,x11,x12]
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      return collator.compare(a.name, b.name);
    }
  },
};
