import { enumValues } from '../utilFunctions/pure-utils';

export enum Granularity { // These should be the same as moment's precision
  MONTH = 'month',
  YEAR = 'year',
  DAY = 'day',
  WEEK = 'week',
  QUARTER = 'quarter',
  FINQUARTER = 'finquarter',
  FINYEAR = 'finYear',
}

export enum Months {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}
export const TIMEZONE_FORMAT = 'timezoneFormat';
export const API_FORMAT = 'apiFormat';
export const dateFormats = [...enumValues(Granularity), API_FORMAT, TIMEZONE_FORMAT] as const;

export const API_DATE_FORMAT = 'YYYY-MM-DD';
