import moment from 'moment';
import { API_DATE_FORMAT } from '../date-manager-constants';
import { DateFormatUtilityMethods } from '../date-manager-types';

export const apiDateFormatUtilityMethods: DateFormatUtilityMethods = {
  formatter: (date) => {
    return date.format(API_DATE_FORMAT);
  },
  parser: (formattedDate) => {
    return moment(formattedDate, API_DATE_FORMAT, true);
  },
};
