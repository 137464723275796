import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  SnackbarContent,
  TextField,
} from '@mui/material';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

export interface ReportBugProps {
  open: boolean;
  onClose: () => void;
}

const StyledSnackBarContent = styled(SnackbarContent as any)<{
  background: string;
}>`
  && {
    background-color: ${(props) => props.background};
    & span {
      display: flex;
      align-items: center;
    }
  }
`;

export const ReportBug = observer((props: ReportBugProps) => {
  const { open, onClose } = props;
  const [acknowledgementMessage, setAcknowledgementMessage] = React.useState('');
  const sendBugReport = () => {
    // Send Report to the endpoint here
    window.setTimeout(() => {
      runInAction(() => setAcknowledgementMessage(''));
      onClose();
    }, 1000);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <Trans i18nKey={'pages:reportBug.heading'} />
        </DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" id="name" label="Report Issue" multiline rows="4" fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            <Trans i18nKey={'pages:reportBug.cancelButton'} />
          </Button>
          <Button onClick={sendBugReport} color="primary">
            <Trans i18nKey={'pages:reportBug.submitButton'} />
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={Boolean(acknowledgementMessage)}
        autoHideDuration={6000}
        onClose={() => setAcknowledgementMessage('')}
      >
        <StyledSnackBarContent message={acknowledgementMessage} background="green" />
      </Snackbar>
    </>
  );
});
