import { ApiMasterDataQueryResponseDataPoint } from '../../../common/api/api-interfaces';
import {
  ComparisonOperations,
  DataTypes,
  TimeAndAttendanceMonthlyDataFields,
} from '../../../common/constants/constants';
import { FormatTypes } from '../../../common/utilFunctions/formatters';
import { getValue, getValueForMeasure, ratioNumber, sumBy } from '../../../common/utilFunctions/utils';
import { timeAndAttendanceStore, WorkHoursFactboxGranularity } from './time-and-attendance-store';

export interface DaysAndHours {
  numOfDaysWorked: number;
  numOfHoursWorked: number;
}

export const getMonthlyAvgValues = (dataPoints: ApiMasterDataQueryResponseDataPoint[]): DaysAndHours => {
  if (!dataPoints) {
    return {
      numOfDaysWorked: 0,
      numOfHoursWorked: 0,
    };
  }
  const totalNumberOfMonths = dataPoints.length;
  let monthlyAverageDaysForAllMonths = 0;
  let monthlyAverageHoursForAllMonths = 0;
  dataPoints.forEach((dp) => {
    const numberOfEmployeesInMonth = getValueForMeasure(dp.measures, {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      dataField: TimeAndAttendanceMonthlyDataFields.EMPLOYEE_ID,
    });
    const totalNumDaysForAllEmployeesByMonth = getValueForMeasure(dp.measures, {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      dataField: TimeAndAttendanceMonthlyDataFields.NUM_DAYS_WORKED,
    });
    const totalNumHoursForAllEmployeesByMonth = getValueForMeasure(dp.measures, {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      dataField: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
    });
    monthlyAverageDaysForAllMonths += ratioNumber(
      totalNumDaysForAllEmployeesByMonth,
      numberOfEmployeesInMonth,
      FormatTypes.NUMBER
    );
    monthlyAverageHoursForAllMonths += ratioNumber(
      totalNumHoursForAllEmployeesByMonth,
      numberOfEmployeesInMonth,
      FormatTypes.NUMBER
    );
  });

  const averageOfMonthlyAverageHours = monthlyAverageHoursForAllMonths / totalNumberOfMonths;
  const averageOfMonthlyAverageDays = monthlyAverageDaysForAllMonths / totalNumberOfMonths;
  return {
    numOfDaysWorked: averageOfMonthlyAverageDays,
    numOfHoursWorked: averageOfMonthlyAverageHours,
  };
};
export const getTotalValues = (dataPoints: ApiMasterDataQueryResponseDataPoint[]): DaysAndHours => {
  if (!dataPoints) {
    return {
      numOfDaysWorked: 0,
      numOfHoursWorked: 0,
    };
  }
  const numOfDaysWorked = sumBy(dataPoints, (dp) =>
    getValueForMeasure(dp.measures, {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      dataField: TimeAndAttendanceMonthlyDataFields.NUM_DAYS_WORKED,
    })
  );
  const numOfHoursWorked = sumBy(dataPoints, (dp) =>
    getValueForMeasure(dp.measures, {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      dataField: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
    })
  );

  return {
    numOfDaysWorked,
    numOfHoursWorked,
  };
};

export const getDailyAverageValue = (
  numOfDaysWorked: number,
  numberOfDaysInMonth: number,
  numberOfEmployeesInMonth: number
) => {
  return numOfDaysWorked / numberOfDaysInMonth / numberOfEmployeesInMonth;
};
export const getDailyAvgInPeriod = (dataPoints: ApiMasterDataQueryResponseDataPoint[]): DaysAndHours => {
  if (!dataPoints)
    return {
      numOfDaysWorked: 0,
      numOfHoursWorked: 0,
    };
  const totalNumberOfMonths = dataPoints.length;
  let dailyAverageDaysForAllMonths = 0;
  let dailyAverageHoursForAllMonths = 0;
  dataPoints.forEach((dp) => {
    const numberOfDaysInMonth = getValue(dp.dimensions, {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      dataField: TimeAndAttendanceMonthlyDataFields.NUM_WORKING_DAYS,
    });
    const numberOfEmployeesInMonth = getValueForMeasure(dp.measures, {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      dataField: TimeAndAttendanceMonthlyDataFields.EMPLOYEE_ID,
    });
    const totalNumDaysForAllEmployeesByMonth = getValueForMeasure(dp.measures, {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      dataField: TimeAndAttendanceMonthlyDataFields.NUM_DAYS_WORKED,
    });
    const totalNumHoursForAllEmployeesByMonth = getValueForMeasure(dp.measures, {
      dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
      dataField: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
    });
    dailyAverageDaysForAllMonths += getDailyAverageValue(
      totalNumDaysForAllEmployeesByMonth,
      numberOfDaysInMonth,
      numberOfEmployeesInMonth
    );
    dailyAverageHoursForAllMonths += getDailyAverageValue(
      totalNumHoursForAllEmployeesByMonth,
      numberOfDaysInMonth,
      numberOfEmployeesInMonth
    );
  });

  const averageOfMonthlyAverageHours = dailyAverageHoursForAllMonths / totalNumberOfMonths;
  const averageOfMonthlyAverageDays = dailyAverageDaysForAllMonths / totalNumberOfMonths;
  return {
    numOfDaysWorked: averageOfMonthlyAverageDays,
    numOfHoursWorked: averageOfMonthlyAverageHours,
  };
};

export const getAvgValues = (
  dataPoints: ApiMasterDataQueryResponseDataPoint[],
  selectedFactboxGranularity: WorkHoursFactboxGranularity
): DaysAndHours => {
  const { factBoxGranularityToMultiplicationFactorMap } = timeAndAttendanceStore;
  const multiplicationFactor: number = factBoxGranularityToMultiplicationFactorMap[selectedFactboxGranularity];
  const { numOfHoursWorked: dailyAverageOfHours, numOfDaysWorked: dailyAverageOfDays } =
    getDailyAvgInPeriod(dataPoints);
  return {
    numOfDaysWorked: dailyAverageOfDays * multiplicationFactor,
    numOfHoursWorked: dailyAverageOfHours * multiplicationFactor,
  };
};

export const getWorkHoursFilter = (hours: number, comparator: ComparisonOperations) => {
  return {
    dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
    operation: comparator,
    property: TimeAndAttendanceMonthlyDataFields.NUM_HOURS_WORKED,
    values: [hours],
  };
};

export const getOtHoursFilter = (hours: number, comparator: ComparisonOperations) => {
  return {
    dataType: DataTypes.TIMEANDATTENDANCEMONTHLY,
    operation: comparator,
    property: TimeAndAttendanceMonthlyDataFields.NUM_OVERTIME_HOURS,
    values: [hours],
  };
};
