import { GraphQlRequestService } from '../../../api/graphql-request-service';
import {
  DomainDependenciesQuery,
  QueryCohortMetricsQuery,
  QueryMetricsQuery,
} from '../../../graphql/generated/graphql-sdk';
import { Domains } from '../../constants';
import { Role } from '../types';
import { handleGQLErrors } from '../utils';
import { cleanupMetricTree, convertMetricTreeToMetricDetails } from '../utils-metrics';
import { MetricGroupDetails, QueryCohortMetricsParams, QueryRegularMetricsParams } from './types';

export interface MetricsService {
  queryRegularMetrics: (params: QueryRegularMetricsParams) => Promise<QueryMetricsQuery>;
  queryCohortMetrics: (params: QueryCohortMetricsParams) => Promise<QueryCohortMetricsQuery>;
  getMetricTree: () => MetricGroupDetails[];
}

export class BackendMetricsService implements MetricsService {
  constructor(
    readonly graphQlRequestService: GraphQlRequestService,
    readonly domainDependencies: DomainDependenciesQuery,
    readonly domain: Domains,
    readonly executorRole: Role,
    readonly simulateRole: Role | null
  ) {}

  public queryRegularMetrics = async (params: QueryRegularMetricsParams): Promise<QueryMetricsQuery> => {
    const { timeSelection, metrics, userFilters, grouping, disableNestLoop } = params;
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.queryMetrics({
        domain: this.domain,
        metrics: metrics.map((m) => m.value),
        disableNestLoop: disableNestLoop ?? null,
        grouping: grouping ?? null,
        timeSelection,
        userFilters: userFilters ?? null,
        selectedExecutorRole: this.executorRole.id,
        simulateRole: this.simulateRole?.id ?? null,
      })
    );
  };

  public queryCohortMetrics = async (params: QueryCohortMetricsParams): Promise<QueryCohortMetricsQuery> => {
    const { timeSelection, metrics, userFilters, disableNestLoop } = params;
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.queryCohortMetrics({
        domain: this.domain,
        metrics: metrics.map((m) => m.value),
        disableNestLoop: disableNestLoop ?? null,
        timeSelection,
        userFilters: userFilters ?? null,
        selectedExecutorRole: this.executorRole.id,
        simulateRole: this.simulateRole?.id ?? null,
      })
    );
  };

  public getMetricTree = (): MetricGroupDetails[] => {
    const metricTree = this.domainDependencies?.domainInfo?.metricTree;
    if (!metricTree) {
      throw new Error('metric tree cannot be null');
    }

    return convertMetricTreeToMetricDetails(cleanupMetricTree(metricTree));
  };
}
