import { ApiUser } from './api-interfaces';
import { GraphQlRequestService } from './graphql-request-service';

export interface UserApiService {
  getUser(): Promise<ApiUser>;
  createFirebaseUser(userEmail: string): Promise<void>;
  sendWelcomeWithSsoMail(userEmail: string): Promise<void>;
  sendWelcomeWithPasswordMail(userEmail: string): Promise<void>;
  sendPasswordResetMail(userEmail: string): Promise<void>;
}

export class DefaultUserApiService implements UserApiService {
  graphQlRequestService: GraphQlRequestService;

  constructor(graphqlRequestService: GraphQlRequestService) {
    this.graphQlRequestService = graphqlRequestService;
  }

  public async getUser(): Promise<ApiUser> {
    const currentUserUserInfo = await this.graphQlRequestService.graphQlSdk.currentUserUserInfo();
    return { email: currentUserUserInfo.currentUserUserInfo?.email };
  }

  public async createFirebaseUser(userEmail: string): Promise<void> {
    await this.graphQlRequestService.graphQlSdk.addFirebaseUserForExistingAppUser({ email: userEmail });
  }

  public async sendWelcomeWithSsoMail(userEmail: string): Promise<void> {
    await this.graphQlRequestService.graphQlSdk.sendWelcomeWithSsoMail({ receiver: userEmail });
  }

  public async sendWelcomeWithPasswordMail(userEmail: string): Promise<void> {
    await this.graphQlRequestService.graphQlSdk.sendWelcomeWithPasswordMail({ receiver: userEmail });
  }

  public async sendPasswordResetMail(userEmail: string): Promise<void> {
    await this.graphQlRequestService.graphQlSdk.sendPasswordResetMail({ receiver: userEmail });
  }
}
