import { ApiMasterDataAdvancedQuery, ApiMasterDataQuery, ApiMasterDataQueryResponse } from '../api/api-interfaces';
import { BenchmarkConfig, cachedMasterDataApiService } from '../api/master-data-service';
import { VersionId } from '../v2/api/types';
import { advancedQueryToCustomSqlQuery, oldQueryToAdvancedQuery } from './utils';

export const GetResponseForMasterQueryService = async (
  query: ApiMasterDataQuery,
  latestVersionId?: VersionId,
  benchmarkConfig?: BenchmarkConfig
): Promise<ApiMasterDataQueryResponse> => {
  const advancedQuery = oldQueryToAdvancedQuery(query, latestVersionId);
  const sqlQuery = await advancedQueryToCustomSqlQuery(advancedQuery);

  return cachedMasterDataApiService.executeCustomSqlQuery(
    advancedQuery.dimensions ?? [],
    advancedQuery.measures ?? [],
    sqlQuery,
    advancedQuery.disableNestLoop
  );
};

export const GetResponseForAdvancedQueryService = async (
  query: ApiMasterDataAdvancedQuery,
  _benchmarkConfig?: BenchmarkConfig
) => {
  const sqlQuery = await advancedQueryToCustomSqlQuery(query);
  return cachedMasterDataApiService.executeCustomSqlQuery(
    query.dimensions ?? [],
    query.measures ?? [],
    sqlQuery,
    query.disableNestLoop
  );
};
