import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'typeface-roboto';
import { Workbox } from 'workbox-window';
import App from './app/app';
import { environmentService } from './app/common/environment/environment-service';
import { INTERCOM_CUSTOM_LAUNCHER_ID } from './app/common/intercom/intercom-store';
import { sentryReplay } from './app/common/sentry/SentryReplay';
import { availableLanguagesToIso6291Map } from './app/common/utilFunctions/pure-utils';
import './assets/css/font.css';
import './assets/css/global.css';
import i18n from './i18n';

const languageInStorage = localStorage.getItem('i18nextLng');

let intercomSettings: Intercom_.IntercomSettings = {
  app_id: 'qcxorkyv',
  custom_launcher_selector: `#${INTERCOM_CUSTOM_LAUNCHER_ID}`,
};

if (languageInStorage && Object.values(availableLanguagesToIso6291Map).includes(languageInStorage)) {
  intercomSettings = { ...intercomSettings, language_override: availableLanguagesToIso6291Map[languageInStorage] };
}
window.intercomSettings = intercomSettings;

if (!environmentService.isLocalhost) {
  const wb = new Workbox(`/service-worker.js`);

  const isServiceWorkerSupported: () => boolean = () => 'serviceWorker' in navigator;

  const registerServiceWorker = (): void => {
    if (isServiceWorkerSupported()) {
      wb.register()
        .then(() => {
          console.log('Service Worker registration completed');
        })
        .catch((err) => {
          console.error('Service Worker registration failed:', err);
        });
    }
  };

  registerServiceWorker();
}

if (!environmentService.isLocalhost) {
  const environment = environmentService.getEnvironment();
  Sentry.init({
    dsn: 'https://be25460730964e43930b2228a9037ef2@o433529.ingest.sentry.io/5388844',
    environment: environment.name,
    integrations: [Sentry.browserTracingIntegration(), sentryReplay],
    ignoreErrors: ['ChunkLoadError'],
    tracesSampleRate: 1.0,
    // We want to only use sentry replays when manually triggered, so all is set to 0
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    tracePropagationTargets: environment.hosts,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <I18nextProvider i18n={i18n}>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </I18nextProvider>
);
