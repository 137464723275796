import { action, computed, observable } from 'mobx';

export class LoadingStateStore {
  @observable
  private unresolvedQueryCount = 0;

  @computed
  public get isLoading() {
    return this.unresolvedQueryCount > 0;
  }

  @action
  public loadStarted() {
    this.unresolvedQueryCount++;
  }

  @action
  public loadFinished() {
    if (this.unresolvedQueryCount !== 0) {
      this.unresolvedQueryCount--;
    }
  }

  @action
  public clearLoadingState = () => {
    this.unresolvedQueryCount = 0;
  };
}
