import { ApiMasterDataAdvancedQuery, ApiMasterDataBatchQueryResponse } from '../api/api-interfaces';
import { BenchmarkConfig } from '../api/employee-data-service/employee-data-service';
import { cachedMasterDataApiService } from '../api/master-data-service';
import { advancedQueryToCustomSqlQuery } from './utils';

export const GetResponsesForAdvancedQueriesService = async (
  queries: ApiMasterDataAdvancedQuery[],
  benchmarkConfig?: BenchmarkConfig
): Promise<ApiMasterDataBatchQueryResponse> => {
  return Promise.all(
    queries.map(async (q) => {
      const sqlQuery = await advancedQueryToCustomSqlQuery(q);
      const result = await cachedMasterDataApiService.executeCustomSqlQuery(
        q.dimensions ?? [],
        q.measures ?? [],
        sqlQuery,
        q.disableNestLoop
      );
      return { query: sqlQuery, response: result };
    })
  ).then((responses) => {
    return { responseListItems: responses };
  });
};
