import { AppBar, Grid, Tab, Tabs, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { compareDimensionToDim } from '../../../../common-types';
import Loading from '../../../common/components/utils/Loading';
import {
  ApiMasterDataAdvancedQuery,
  ApiMasterDataAdvancedQueryDimension,
  ApiMasterDataQueryResponseDataPoint,
  ApiMasterDataQueryResponseDataPointDimensionData,
  toApiMasterDataAdvancedQueryWithTypeInfo,
} from '../../api/api-interfaces';
import { DataFields, DataTypes, EmployeeDataFields, Operations } from '../../constants/constants';
import { ALL_TEMPORALITY_FILTER, getVersionFilter } from '../../filter/common-filters';
import { rootStore } from '../../store/root-store';
import { formatApiMasterDataProperty, formatDataPoint } from '../../utilFunctions/formatters';
import { uuid } from '../../utilFunctions/utils';
import { ApiMasterDataQueryFilterItem } from '../../v2/types';
import QueryExecutor from '../query/QueryExecutor';
import { Modal } from '../utils/Modal';

interface EmployeeProfileProps {
  open: boolean;
  onClose: () => void;
  employeeId: string;
}

const EmployeeDataLine = styled(Grid)`
  height: 35vh;
  padding: 16px;
` as typeof Grid;

const StyledTab = styled(Tab)`
  text-transform: none;
` as typeof Tab;

interface EmployeeProfileSection {
  displayName: string;
  description: string;
  query: ApiMasterDataAdvancedQuery;
}

const standardEmployeeProfileConfig: EmployeeProfileSection[] = [
  {
    displayName: 'common:employeeProfile.personalDetails',
    description: 'Personal Details',
    query: {
      dataType: DataTypes.EMPLOYEE,
      dimensions: [
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FULL_NAME },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.EMPLOYEE_ID },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.JOINING_AGE_GROUP },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCATION_LEVEL_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCATION_LEVEL_2 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCATION_LEVEL_3 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ORGANIZATION_LEVEL_2 },
      ],
    },
  },
  {
    displayName: 'common:employeeProfile.jobInformationDetails',
    description: 'Job Information Details',
    query: {
      dataType: DataTypes.EMPLOYEE,
      dimensions: [
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.JOB_TITLE },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.JOB_GRADE_LEVEL_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.JOB_GRADE_LEVEL_2 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.JOB_GRADE_LEVEL_3 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.START_DATE },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.TERM_DATE },
      ],
    },
  },
  {
    displayName: 'common:employeeProfile.compensationDetails',
    description: 'Compensation Details',
    query: {
      dataType: DataTypes.EMPLOYEE,
      dimensions: [
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.BASE_SALARY },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.BASE_SALARY_CURRENCY },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.BASE_SALARY_FREQUENCY },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.BASE_SALARY_PER_YEAR },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ANNUAL_CASH_BONUS_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ANNUAL_CASH_BONUS_1_CURRENCY },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ICR },
      ],
    },
  },
  {
    displayName: 'common:employeeProfile.performanceDetails',
    description: 'Performance Details',
    query: {
      dataType: DataTypes.EMPLOYEE,
      dimensions: [{ dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.PERFORMANCE_RATING }],
    },
  },
];

export const EmployeeProfile = observer((props: EmployeeProfileProps) => {
  const getEmployeeIdFilter: (employeeId: string) => ApiMasterDataQueryFilterItem[] = (employeeId: string) => {
    const latestVersion = rootStore.domainDependencyStore.getLatestVersion(DataTypes.EMPLOYEE)!;
    return [
      {
        operation: Operations.EQUAL,
        property: EmployeeDataFields.EMPLOYEE_ID,
        values: [employeeId],
        dataType: DataTypes.EMPLOYEE,
      },
      ALL_TEMPORALITY_FILTER,
      getVersionFilter(latestVersion),
    ];
  };

  useEffect(() => {
    rootStore.employeeDataStore.loadNonNullFields();
  }, []);

  const [accordianPosition, setAccordianPosition] = useState<number>(0);
  const employeeIdFilter = getEmployeeIdFilter(props.employeeId);

  const renderTabContent = (dataPoints: ApiMasterDataQueryResponseDataPoint[]) =>
    dataPoints
      ?.map(formatDataPoint)
      ?.map((dp) => dp.dimensions)
      ?.map((dim) => renderTabData(dim));

  const renderTabData = (dimensions: ApiMasterDataQueryResponseDataPointDimensionData[]) => {
    return (
      <EmployeeDataLine key={uuid()} container xs={12}>
        {dimensions.map((dim) => {
          const { dataType, property, value } = dim;
          if (value === undefined) {
            return null;
          }
          const field = { dataType: dataType as DataTypes, dataField: property as DataFields };
          return (
            <Grid item xs={3} key={property}>
              <Typography variant="subtitle2">
                {formatApiMasterDataProperty(
                  {
                    property: field,
                  },
                  rootStore.aliasStore.getAliasTextForField(field)
                )}
              </Typography>
              <Typography variant="h5">{value}</Typography>
            </Grid>
          );
        })}
      </EmployeeDataLine>
    );
  };

  interface Replacement {
    oldDimension: ApiMasterDataAdvancedQueryDimension;
    newDimension: ApiMasterDataAdvancedQueryDimension;
  }

  const replacements: Replacement[] = [
    {
      oldDimension: { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FULL_NAME },
      newDimension: { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCAL_FULL_NAME },
    },
  ];

  const replaceDimensions: (
    dimensions: ApiMasterDataAdvancedQueryDimension[]
  ) => ApiMasterDataAdvancedQueryDimension[] = (dimensions: ApiMasterDataAdvancedQueryDimension[]) =>
    dimensions.map((d) => {
      const replacement = replacements.find(
        (r) => r.oldDimension.dataType === d.dataType && r.oldDimension.property === d.property
      );
      return replacement?.newDimension ?? d;
    });

  const { t } = useTranslation();
  const { open, onClose, employeeId } = props;

  const { nonNullFields } = rootStore.employeeDataStore;
  const isJapaneseEnterpriseClient = rootStore.companyStore.isJapaneseEnterpriseClient();
  const finalStandardEmployeeProfileConfig: EmployeeProfileSection[] = isJapaneseEnterpriseClient
    ? standardEmployeeProfileConfig.map((s) => ({
        ...s,
        query: { ...s.query, dimensions: replaceDimensions(s.query.dimensions ?? []) },
      }))
    : standardEmployeeProfileConfig;
  const query: ApiMasterDataAdvancedQuery = {
    dataType: DataTypes.EMPLOYEE,
    dimensions: finalStandardEmployeeProfileConfig
      .flatMap((section) => section.query.dimensions ?? [])
      .filter((d) => d.property !== 'VERSION_ID' && (nonNullFields?.some((f) => compareDimensionToDim(d, f)) ?? true)),
    filterItems: employeeIdFilter,
  };
  return (
    <Modal open={open} onClose={onClose} title={t('common:employeeProfile.title')}>
      {employeeId === null ? (
        <>
          <Loading color="primary" />
        </>
      ) : (
        <QueryExecutor
          queryConfig={{
            queriesWithType: [toApiMasterDataAdvancedQueryWithTypeInfo(query)],
          }}
        >
          {({ data }) => {
            if (data) {
              const relevantDimensions: ApiMasterDataAdvancedQueryDimension[] =
                finalStandardEmployeeProfileConfig[accordianPosition].query.dimensions ?? [];
              const dataPoints = data.dataPoints.map((d) => ({
                ...d,
                dimensions: d.dimensions.filter((dim) =>
                  relevantDimensions.some(
                    (relDim) => relDim.dataType === dim.dataType && relDim.property === dim.property
                  )
                ),
              }));
              return (
                <>
                  <AppBar position="static">
                    <Tabs
                      value={accordianPosition}
                      onChange={(_1, value: number) => setAccordianPosition(value)}
                      variant="fullWidth"
                    >
                      {finalStandardEmployeeProfileConfig.map((r) => (
                        <StyledTab key={r.displayName} label={t(r.displayName)} />
                      ))}
                    </Tabs>
                  </AppBar>
                  {renderTabContent(dataPoints)}
                </>
              );
            }
          }}
        </QueryExecutor>
      )}
    </Modal>
  );
});
