import { action, observable } from 'mobx';
import { ZipCelXCell, ZipCelXConfig } from 'zipcelx';
import { trackDashboardDataViewExport } from './helpers/trackers/dashboardTracker';
import { isValidNumber } from './utilFunctions/validators';

export interface ExportData {
  data: string[][];
  headers: string[];
}

export class ExportStore {
  @observable
  public exportDataUpdatesCounter: number = 0;

  @observable
  public exportDocumentType: 'csv' | 'xlsx' = 'csv';

  @action
  public setExportDocumentType = (newType: 'csv' | 'xlsx') => {
    this.exportDocumentType = newType;
  };

  @action
  public triggerExportDataUpdate = (documentType: 'csv' | 'xlsx') => {
    this.setExportDocumentType(documentType);
    this.exportDataUpdatesCounter++;
  };

  public exportReport = (exportFileName: string, reportExpordData: ExportData) => {
    trackDashboardDataViewExport(this.exportDocumentType);
    if (this.exportDocumentType === 'csv') {
      return this.exportReportAsCsv(exportFileName, reportExpordData);
    }
    return this.exportReportAsExcel(exportFileName, reportExpordData);
  };

  private exportReportAsExcel = async (exportFileName: string, reportExportData: ExportData) => {
    const zipcelx = (
      await import(
        /* webpackChunkName: "zipcelx" */
        /* webpackMode: "lazy" */
        'zipcelx'
      )
    ).default;

    const { headers, data } = reportExportData;
    const excelData: ZipCelXCell[][] = [
      headers.map((h) => ({ value: h, type: 'string' })),
      ...data.map((row) =>
        row.map((val) => {
          const cell: ZipCelXCell = { value: val, type: isValidNumber(val) ? 'number' : 'string' };
          return cell;
        })
      ),
    ];
    const zipcelxConfig: ZipCelXConfig = {
      filename: exportFileName,
      sheet: {
        data: excelData,
      },
    };
    zipcelx(zipcelxConfig);
  };

  private exportReportAsCsv = async (exportFileName: string, reportExportData: ExportData) => {
    const Papa = (
      await import(
        /* webpackChunkName: "papaparse" */
        /* webpackMode: "lazy" */
        'papaparse'
      )
    ).default;

    reportExportData.data.unshift(reportExportData.headers);
    const csvContent = Papa.unparse(reportExportData.data);
    const encodedUri = encodeURIComponent(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodedUri);
    link.setAttribute('download', `${exportFileName}.csv`);
    document.body.appendChild(link);
    link.click();
  };
}
