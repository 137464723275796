/* TODO: Make code for this file shorter. It is like a config file with a lot of repeated logic.
   We can make it more modular and easier to manage, for example, by generating title and description
   based on db name and chartId
   Need to remember to still allow config like set up for charts which might not be from any dashboard and therefore
   won't fit this structure
*/

export class ChartDescriptionStore {
  public chartIds = {
    headcountByMonth: 'headcount-by-month',
    headcountByYear: 'headcount-by-year',
    headcountByEmploymentType: 'headcount-by-employment-type',
    headcountByOrganization: 'headcount-by-organization',
    headcountByLocation: 'headcount-by-locationn',
    headcountByFunction: 'headcount-by-function',
    headcountByPosition: 'headcount-by-position',
    headcountByTenure: 'headcount-by-tenure',
    headcountByGender: 'headcount-by-gender',
    headcountByJobGrade: 'headcount-by-jobgrade',
    headcountByNationalities: 'headcount-by-nationalities',
    headcountByAge: 'headcount-by-age',
    headcountFactbox: 'headcount-factbox',
    keyMetricsFactbox: 'key-metrics-factbox',
    attritionOverTime: 'attrition-over-time',
    attritionByEmploymentType: 'attrition-by-employment-type',
    attritionByOrganization: 'attrition-by-organization',
    attritionByLocation: 'attrition-by-location',
    attritionByFunction: 'attrition-by-function',
    attritionByPosition: 'attrition-by-position',
    attritionByTenure: 'attrition-by-tenure',
    attritionByGender: 'attrition-by-gender',
    attritionByJobGrade: 'attrition-by-jobgrade',
    attritionByLocalOrNonLocal: 'attrition-by-local-or-nonlocal',
    attritionByAge: 'attrition-by-age',
    attritionCheckBox: 'attrition-check-box',
    genderGradeOverTime: 'gender-grade-over-time',
    genderSplitOverTime: 'gender-split-over-time',
    genderSplitByOrganization: 'gender-split-by-organization',
    genderSplitByLocation: 'gender-split-by-location',
    genderSplitByFunction: 'gender-split-by-function',
    genderSplitByTenure: 'gender-split-by-tenure',
    genderSplitByJobGrade: 'gender-split-by-jobgrade',
    genderSplitByLocalOrNonLocal: 'gender-split-by-local-or-nonlocal',
    genderSplitByAge: 'gender-split-by-age',
    attritionByGenderOverTime: 'attrition-by-gender-over-time',
    attritionByGenderAndFunction: 'attrition-by-gender-function',
    attritionByGenderAndOrganization: 'attrition-by-gender-organization',
    attritionByGenderAndLocation: 'attrition-by-gender-location',
    attritionByGenderAndTenure: 'attrition-by-gender-tenure',
    attritionByGenderAndLocalOrNonLocal: 'attrition-by-gender-local-or-nonlocal',
    attritionByGenderAndAge: 'attrition-by-gender-age',
    joinersAndLeaversOverTime: 'joiners-and-leavers-over-time',
    joinersAndLeaversByEmploymentType: 'joiners-and-leavers-by-employment-type',
    joinersAndLeaversByOrganization: 'joiners-and-leavers-by-organization',
    joinersAndLeaversByJobTitle: 'joiners-and-leavers-by-job-title',
    joinersAndLeaversByLocation: 'joiners-and-leavers-by-locationn',
    joinersAndLeaversByTenure: 'joiners-and-leavers-by-tenure',
    joinersAndLeaversByGender: 'joiners-and-leavers-by-gender',
    joinersAndLeaversByJobGrade: 'joiners-and-leavers-by-jobgrade',
    joinersAndLeaversByLocalOrNonLocal: 'joiners-and-leavers-by-local-or-nonlocal',
    joinersAndLeaversByAge: 'joiners-and-leavers-by-age',
    diversityGenderPaygap: 'diversity-gender-paygap', // this is a factbox
    payGapByGrade: 'pay-gap-by-grade',
    payGapOverTime: 'pay-gap-by-over-time',
    payGapByLocation: 'pay-gap-by-locationn',
    payGapByOrganization: 'pay-gap-by-organization',
    payGapByFunction: 'pay-gap-by-function',
    payGapByTenure: 'pay-gap-by-tenure',
    payGapByLocalOrNonLocal: 'pay-gap-by-local-or-nonlocal',
    payGapByAge: 'pay-gap-by-age',
    payGapByNationality: 'pay-gap-by-nationality',
    acrSplitByGender: 'acr-split-gender-percentage',
    acrByGradeAndGender: 'acr-by-jobgrade-gender',
    acrOverTime: 'acr-over-time',
    acrByLocationAndGender: 'acr-by-location-and-gender',
    acrByOrganizationAndGender: 'acr-by-organization-and-gender',
    acrByFunctionAndGender: 'acr-by-function-and-gender',
    acrByAgeAndGender: 'acr-by-age-and-gender',
    acrByNationalityAndGender: 'acr-by-nationality-and-gender',
    acrByLocalOrNonLocalAndGender: 'acr-by-local-or-nonlocal-and-gender',
    acrByTenureAndGender: 'acr-by-tenure-and-gender',
    medianBaseByMonth: 'mdb-by-month',
    medianBaseByYear: 'mdb-by-year',
    medianBaseByEmploymentType: 'mdb-by-employment-type',
    medianBaseByOrganization: 'mdb-by-organization',
    medianBaseByLocation: 'mdb-by-locationn',
    medianBaseByTenure: 'mdb-by-tenure',
    medianBaseByGender: 'mdb-by-gender',
    medianBaseByJobGrade: 'mdb-by-jobgrade',
    medianBaseByLocalOrNonLocal: 'mdb-by-local-or-nonlocal',
    medianBaseByAge: 'mdb-by-age',
    acrByLocation: 'acr-by-location',
    acrByOrganization: 'acr-by-organization',
    acrByGender: 'acr-by-gender',
    acrByGradeAndLocation: 'acr-by-location-jobgrade',
    acrByGenderAndGrade: 'acr-by-gender-jobgrade',
    acrByGradeAndOrganization: 'acr-by-organization-jobgrade',
    recruitmentOverviewTimeToFillHireReject: 'recruitment-overview-time-to-fill-hire-reject',
    attendanceOverTime: 'attendance-over-time',
    overtimeByLocation: 'overtime-by-location',
    overtimeByOrganization: 'overtime-by-organization',
    overtimeByTenure: 'overtime-by-tenure',

    peopleSnapshot: {
      headcountByMonth: 'headcount-by-month',
      headcountByYear: 'headcount-by-year',
      headcountByEmploymentType: 'headcount-by-employment-type',
      headcountByOrganization: 'headcount-by-organization',
      headcountByLocation: 'headcount-by-locationn',
      headcountByFunction: 'headcount-by-function',
      headcountByPosition: 'headcount-by-position',
      headcountByTenure: 'headcount-by-tenure',
      headcountByGender: 'headcount-by-gender',
      headcountByJobGrade: 'headcount-by-jobgrade',
      headcountByNationalities: 'headcount-by-nationalities',
      headcountByAge: 'headcount-by-age',
      headcountFactbox: 'headcount-factbox',
      keyMetricsFactbox: 'key-metrics-factbox',
      spanOfControlFactbox: 'span-of-control-factbox',
    },

    attrition: {
      overview: {
        attritionOverTime: 'attrition-over-time',
        attritionByEmploymentType: 'attrition-by-employment-type',
        attritionByOrganization: 'attrition-by-organization',
        attritionByLocation: 'attrition-by-location',
        attritionByFunction: 'attrition-by-function',
        attritionByPosition: 'attrition-by-position',
        attritionByTenure: 'attrition-by-tenure',
        attritionByGender: 'attrition-by-gender',
        attritionByJobGrade: 'attrition-by-jobgrade',
        attritionByLocalOrNonLocal: 'attrition-by-local-or-nonlocal',
        attritionByNationality: 'attrition-by-nationality',
        attritionByAge: 'attrition-by-age',
        attritionCheckBox: 'attrition-check-box',
      },
    },
    diversity: {
      demographics: {
        genderGradeOverTime: 'gender-grade-over-time',
        genderSplitOverTime: 'gender-split-over-time',
        genderSplitByOrganization: 'gender-split-by-organization',
        genderSplitByLocation: 'gender-split-by-location',
        genderSplitByFunction: 'gender-split-by-function',
        genderSplitByTenure: 'gender-split-by-tenure',
        genderSplitByJobGrade: 'gender-split-by-jobgrade',
        genderSplitByLocalOrNonLocal: 'gender-split-by-local-or-nonlocal',
        genderSplitByNationality: 'gender-split-by-nationality',
        genderSplitByAge: 'gender-split-by-age',
      },
      joinersAndLeavers: {
        attritionByGenderOverTime: 'attrition-by-gender-over-time',
        attritionByGenderAndFunction: 'attrition-by-gender-function',
        attritionByGenderAndOrganization: 'attrition-by-gender-organization',
        attritionByGenderAndLocation: 'attrition-by-gender-location',
        attritionByGenderAndTenure: 'attrition-by-gender-tenure',
        attritionByGenderAndLocalOrNonLocal: 'attrition-by-gender-local-or-nonlocal',
        attritionByNationality: 'attrition-by-gender-nationality',
        attritionByGenderAndAge: 'attrition-by-gender-age',
      },
      payGap: {
        diversityGenderPaygap: 'diversity-gender-paygap', // this is a factbox
        payGapByGrade: 'pay-gap-by-grade',
        payGapOverTime: 'pay-gap-by-over-time',
        payGapByLocation: 'pay-gap-by-locationn',
        payGapByOrganization: 'pay-gap-by-organization',
        payGapByFunction: 'pay-gap-by-function',
        payGapByTenure: 'pay-gap-by-tenure',
        payGapByLocalOrNonLocal: 'pay-gap-by-local-or-nonlocal',
        payGapByNationality: 'pay-gap-by-nationality',
        payGapByAge: 'pay-gap-by-age',
      },
      compaRatio: {
        acrSplitByGender: 'acr-split-gender-percentage',
        acrByGradeAndGender: 'acr-by-jobgrade-gender',
        acrOverTime: 'acr-over-time',
        acrByLocationAndGender: 'acr-by-location-and-gender',
        acrByOrganizationAndGender: 'acr-by-organization-and-gender',
        acrByFunctionAndGender: 'acr-by-function-and-gender',
        acrByAgeAndGender: 'acr-by-age-and-gender',
        acrByLocalOrNonLocalAndGender: 'acr-by-local-or-nonlocal-and-gender',
        acrByNationalityAndGender: 'acr-by-nationality-and-gender',
        acrByTenureAndGender: 'acr-by-tenure-and-gender',
      },
    },
    joinersAndLeavers: {
      overview: {
        joinersAndLeaversOverTime: 'joiners-and-leavers-over-time',
        joinersAndLeaversByEmploymentType: 'joiners-and-leavers-by-employment-type',
        joinersAndLeaversByJobTitle: 'joiners-and-leavers-by-job-title',
        joinersAndLeaversByOrganization: 'joiners-and-leavers-by-organization',
        joinersAndLeaversByLocation: 'joiners-and-leavers-by-locationn',
        joinersAndLeaversByTenure: 'joiners-and-leavers-by-tenure',
        joinersAndLeaversByGender: 'joiners-and-leavers-by-gender',
        joinersAndLeaversByJobGrade: 'joiners-and-leavers-by-jobgrade',
        joinersAndLeaversByLocalOrNonLocal: 'joiners-and-leavers-by-local-or-nonlocal',
        joinersAndLeaversByNationality: 'joiners-and-leavers-by-nationality',
        joinersAndLeaversByAge: 'joiners-and-leavers-by-age',
      },
    },
    medianBase: {
      overview: {
        medianBaseByMonth: 'mdb-by-month',
        medianBaseByYear: 'mdb-by-year',
        medianBaseByEmploymentType: 'mdb-by-employment-type',
        medianBaseOverviewFactbox: 'mdb-factbox',
      },
      keyMetrics: {
        medianBaseByOrganization: 'mdb-by-organization',
        medianBaseByLocation: 'mdb-by-locationn',
        medianBaseByTenure: 'mdb-by-tenure',
        medianBaseByGender: 'mdb-by-gender',
        medianBaseByJobGrade: 'mdb-by-jobgrade',
        medianBaseByLocalOrNonLocal: 'mdb-by-local-or-nonlocal',
        medianBaseByNationality: 'mdb-by-nationality',
        medianBaseByAge: 'mdb-by-age',
        medianBaseKpiFactbox: 'mdb-kpi-factbox',
      },
      compaRatio: {
        acrByLocation: 'acr-by-location',
        acrByOrganization: 'acr-by-organization',
        acrByGender: 'acr-by-gender',
        acrByGradeAndLocation: 'acr-by-location-jobgrade',
        acrByGenderAndGrade: 'acr-by-gender-jobgrade',
        acrByGradeAndOrganization: 'acr-by-organization-jobgrade',
      },
    },
    recruitment: {
      overview: {
        recruitmentOverviewFactbox: 'recruitment-overview-factbox',
        openRequisitionsAndHiresOverTime: 'recruitment-open-requisitions-and-hires-over-time',
        avgTimeToFillHireReject: 'recruitment-avg-time-to-fill-hire-reject',
        applications: 'applications',
        offersAndHires: 'offers-and-hires',
        agingRequisitions: 'recruitment-aging-requisitions',
      },
      activities: {
        activitiesOverTime: 'recruitment-activities-over-time',
      },
    },
    recruitmentFunnel: {
      recruitmentFunnel: {
        leftFactbox: 'recruitment-funnel-left-factbox',
        rightFactbox: 'recruitment-funnel-right-factbox',
        funnel: 'recruitment-funnel-funnel',
        applicationByOffice: 'recruitment-funnel-application-by-office',
        applicationByOrganization: 'recruitment-funnel-application-by-organization',
      },
    },
    attritionPrediction: {
      predictionOvertime: 'attrition-prediction-overtime',
      attritionRiskAvgOverTime: 'attrition-risk-avg-over-time',
      attritionRiskAtRiskHc: 'attrition-risk-at-risk-hc',
    },
    evaluation: {
      overview: {
        evaluationScoreByLocation: 'evaluation-evaluationOverview-location',
        evaluationScoreByOrganization: 'evaluation-evaluationOverview-organization',
        evaluationScoreByTenure: 'evaluation-evaluationOverview-tenureGroup',
        evaluationScoreByGrade: 'evaluation-evaluationOverview-jobGrade',
        evaluationScoreByGender: 'evaluation-evaluationOverview-gender',
        evaluationScoreByLocalNonLocal: 'evaluation-evaluationOverview-localOrNonLocal',
        evaluationScoreByNationality: 'evaluation-evaluationOverview-nationalityHierarchical',
        evaluationScoreByFunction: 'evaluation-evaluationOverview-function',
        evaluationScoreByPosition: 'evaluation-evaluationOverview-position',
        evaluationScoreByAge: 'evaluation-evaluationOverview-ageGroup',
        evaluationDistribution: 'evaluation-evaluationOverview-distribution',
      },
      multicycle: {
        evaluationDistribution: 'evaluation-multicycle-distribution',
        evaluationDistributionTable: 'evaluation-multicycle-distribution-table',
        evaluationSelectors: 'evaluation-multicycle-selectors',
        evaluationHeadcountTable: 'evaluation-headcount-table',
      },
    },
    timeAndAttendance: {
      workHoursOverTime: 'work-hours-over-time',
      overtimeHoursOverTime: 'overtimeHoursOverTime',
    },
  };

  constructor() {
    /**
     * Logic to flag any duplicated keys
     */

    const allChartIds = new Set();
    const duplicatedChartIds = new Set();

    Object.values(this.chartIds).forEach((db) => {
      Object.values(db).forEach((page) => {
        Object.values(page).forEach((chartId) => {
          if (allChartIds.has(chartId)) {
            duplicatedChartIds.add(chartId);
          }
          allChartIds.add(chartId);
        });
      });
    });
    if (duplicatedChartIds.size > 0) {
      // tslint:disable-next-line:no-console
      console.warn('Duplicate chart ids found: ', duplicatedChartIds);
    }
  }
}
