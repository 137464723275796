import { action, computed, observable } from 'mobx';
import { ApiDashboard, FAVORITE_DASHBOARDS_KEY } from './api/api-interfaces';
import { DashboardApiService } from './api/dashboard-service';
import { CompanyStore } from './company/company-store';
import { Dashboards, DataTypes } from './constants/constants';
import { localStore } from './local-store';

export class DashboardStore {
  private companyStore: CompanyStore;
  private dashboardApiService: DashboardApiService;

  constructor(companyStore: CompanyStore, dashboardApiService: DashboardApiService) {
    this.companyStore = companyStore;
    this.dashboardApiService = dashboardApiService;
  }

  @computed
  private get dashboardToLatestVersionDataTypeMap(): Partial<Record<Dashboards, DataTypes>> {
    return {
      [Dashboards.EVALUATION]: DataTypes.EVALUATION,
      [Dashboards.PAYROLL]: DataTypes.PAYROLL,
      [Dashboards.WORK_HOURS_AND_OT]: DataTypes.TIMEANDATTENDANCEMONTHLY,
      [Dashboards.RECRUITMENT_OVERVIEW]: DataTypes.APPLICATION,
      [Dashboards.SURVEY]: DataTypes.QUESTIONANSWER,
    };
  }

  public getLatestVersionDataTypeForDashboard = (dashboard: Dashboards) =>
    this.dashboardToLatestVersionDataTypeMap[dashboard] ?? DataTypes.EMPLOYEE;

  public getDashboard(id: Dashboards): ApiDashboard | undefined {
    return this.dashboards.find((db) => db.id === id);
  }

  @observable
  public dashboards: ApiDashboard[] = [];

  @observable
  public dashboardsFetched = false;

  @observable
  public isFullScreen: boolean = false;

  @action
  public setFullScreen = (isFullScreen: boolean) => {
    this.isFullScreen = isFullScreen;
  };

  public toggleFullScreen = () => {
    this.isFullScreen = !this.isFullScreen;
  };

  public getDashboardFromDashboardPage = (pId: string) => {
    if (this.dashboardsFetched) {
      const dashboard = this.dashboards.find((db) => db.pages.some((p) => p.pageId === pId)) as ApiDashboard;
      return dashboard;
    } else {
      throw new Error('getDashboardFromDashboardPage called before dashboards are loaded');
    }
  };

  @action
  public async getAllDashboards() {
    const dashboards = await this.dashboardApiService.listDashboards();
    this.dashboards = dashboards.dashboards;
    this.dashboardsFetched = true;
    return dashboards;
  }

  public getFavoriteDashboards() {
    return JSON.parse(localStore.get(FAVORITE_DASHBOARDS_KEY) ?? JSON.stringify({}));
  }

  public addDashboardToFavoriteDashboards(dashboard: Dashboards) {
    const favoriteDashboards = this.getFavoriteDashboards();
    localStore.set(
      FAVORITE_DASHBOARDS_KEY,
      JSON.stringify({
        ...favoriteDashboards,
        [dashboard]: (favoriteDashboards[dashboard] ?? 0) + 1,
      })
    );
  }
}
