import { action, observable } from 'mobx';

export class EmployeeProfileStore {
  @observable
  private employeeProfileOpen: boolean = false;

  @observable
  private employeeProfileId: string | undefined;

  @action
  public handleClose = async () => {
    this.setEmployeeProfileOpen(false);
    this.setEmployeeProfileId('');
  };

  @action
  public handleClick = async (employeeId: string) => {
    this.setEmployeeProfileOpen(true);
    this.setEmployeeProfileId(employeeId);
  };

  public setEmployeeProfileId = async (employeeProfileId: string) => {
    this.employeeProfileId = employeeProfileId;
  };

  public setEmployeeProfileOpen = async (employeeProfileOpen: boolean) => {
    this.employeeProfileOpen = employeeProfileOpen;
  };

  public getEmployeeProfileId = () => this.employeeProfileId;
  public getEmployeeProfileOpen = () => this.employeeProfileOpen;
}
