import produce from 'immer';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { match } from 'ts-pattern';
import { IndexRange } from '../../../../common-types';
import {
  ApiDataQueryType,
  ApiDataQueryWithTypeInfo,
  ApiMasterDataQueryInfiniteLoadingResponse,
  ApiMasterDataQueryResponse,
} from '../../api/api-interfaces';
import { BenchmarkConfig } from '../../api/employee-data-service/employee-data-service';
import { DataTypes } from '../../constants/constants';
import { GetResponseForMovementQueryService } from '../../services/GetResponseForMovementQueryService';
import {
  GetResponseForAdvancedQueryService,
  GetResponseForMasterQueryService,
} from '../../services/GetResponseForQueryService';
import { rootStore } from '../../store/root-store';
import { useLatestVersionsContext } from '../../v2/context/contexts';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from './constants';

export interface RenderCallback {
  data: ApiMasterDataQueryResponse | ApiMasterDataQueryInfiniteLoadingResponse | null;
  refetch: (offsetParams?: IndexRange) => Promise<void>;
}

interface QueryExecutorProps {
  children: (state: RenderCallback) => React.ReactNode;
  onReady?: () => void;
  onLoading?: () => void;
  queryConfig: QueryConfig;
  benchmarkConfig?: BenchmarkConfig;
  withOffset?: boolean;
}

interface QueryExecutorState {
  data: ApiMasterDataQueryResponse | null;
}

interface QueryConfig {
  queriesWithType: ApiDataQueryWithTypeInfo[];
  resultsFormatter?: (responses: ApiMasterDataQueryResponse[]) => ApiMasterDataQueryResponse;
}

// Observer is needed here if the callback component uses any observable
const QueryExecutor = observer((props: QueryExecutorProps) => {
  const latestVersions = useLatestVersionsContext();
  const { queryConfig, benchmarkConfig, withOffset, onLoading, onReady } = props;

  const [dataState, setDataState] = useState<QueryExecutorState>({
    data: null,
  });

  const defaultResultsFormatter = (responses: ApiMasterDataQueryResponse[]) => {
    const dataPoints = responses.flatMap((r) => r.dataPoints?.filter((x) => x) ?? []);
    return { dataPoints };
  };

  const requestCounterRef = useRef(0);
  const incRequestCounter = () => {
    requestCounterRef.current++;
  };
  const fetch = async (offsetParams?: IndexRange) => {
    incRequestCounter();
    const currRequestNum = requestCounterRef.current;
    const freshDataFetch = !offsetParams && withOffset;
    const newQueryConfig = produce(queryConfig, (draft) => {
      draft.queriesWithType.forEach((typedQuery) => {
        if (withOffset) {
          typedQuery.query.offset = offsetParams?.startIndex ?? DEFAULT_OFFSET;
          typedQuery.query.limit = offsetParams ? offsetParams?.stopIndex - offsetParams?.startIndex : DEFAULT_LIMIT;
        }
      });
    });

    try {
      onLoading && onLoading();
      rootStore.loadingStateStore.loadStarted();

      const responses: ApiMasterDataQueryResponse[] = await Promise.all(
        newQueryConfig.queriesWithType.map(async (queryWithType) => {
          return match(queryWithType)
            .with({ type: ApiDataQueryType.ApiMasterDataQuery }, (qWithType) =>
              GetResponseForMasterQueryService(
                qWithType.query,
                latestVersions[qWithType.query.dataType as DataTypes],
                benchmarkConfig
              )
            )
            .with({ type: ApiDataQueryType.ApiMasterDataAdvancedQuery }, (qWithType) =>
              GetResponseForAdvancedQueryService(qWithType.query, benchmarkConfig)
            )
            .with({ type: ApiDataQueryType.ApiMasterDataMovementQuery }, (qWithType) =>
              GetResponseForMovementQueryService(qWithType.query, benchmarkConfig)
            )
            .exhaustive();
        })
      );

      const response: ApiMasterDataQueryResponse = newQueryConfig.resultsFormatter
        ? newQueryConfig.resultsFormatter(responses)
        : defaultResultsFormatter(responses);

      const isLatestRequest = currRequestNum === requestCounterRef.current;
      if (isLatestRequest) {
        let updatedData: ApiMasterDataQueryResponse | null;
        if (withOffset) {
          updatedData = {
            dataPoints: freshDataFetch
              ? response.dataPoints
              : uniqWith([...(dataState.data?.dataPoints ?? []), ...response.dataPoints], isEqual),
          };
        } else {
          updatedData = response.dataPoints ? response : { dataPoints: [] };
        }
        setDataState((prev) => ({ ...prev, data: updatedData }));
      }
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
    } finally {
      rootStore.loadingStateStore.loadFinished();
      onReady && onReady();
    }
  };

  useEffect(() => {
    void fetch();
  }, []);

  useEffect(() => {
    void fetch();
  }, [queryConfig, benchmarkConfig]);

  useEffect(() => {
    setDataState((prev) => ({ ...prev, data: null }));
    void fetch();
  }, [withOffset]);

  const { children } = props;

  return (
    <>
      {children({
        data: dataState.data,
        refetch: fetch,
      })}
    </>
  );
});

export default QueryExecutor;
