import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import * as auth from 'firebase/auth';
import { SAMLAuthProvider, signInWithPopup } from 'firebase/auth';
import i18n from '../../../i18n';
import { environmentService } from '../environment/environment-service';
import { SamlProvider } from './auth-store';

export enum FirebaseAuthErrorCodes {
  WRONG_PASSWORD = 'auth/wrong-password',
  POPUP_CLOSED_BY_USER = 'auth/popup-closed-by-user',
  USER_NOT_FOUND_FOR_EMAIL = 'auth/user-not-found',
}

export const firebaseErrorCodeToTranslatedDescription: Record<FirebaseAuthErrorCodes, string> = {
  [FirebaseAuthErrorCodes.WRONG_PASSWORD]: i18n.t('common:authErrorCodesDescription.wrongPassword'),
  [FirebaseAuthErrorCodes.POPUP_CLOSED_BY_USER]: i18n.t('common:authErrorCodesDescription.popupClosedByUser'),
  [FirebaseAuthErrorCodes.USER_NOT_FOUND_FOR_EMAIL]: i18n.t('common:authErrorCodesDescription.userNotFoundForEmail'),
};
export class AuthService {
  private auth: auth.Auth;

  constructor() {
    const firebaseApp = initializeApp(environmentService.getFirebaseConfig());
    this.auth = auth.getAuth(firebaseApp);
    this.auth.setPersistence(this.getSessionPersistence());
  }

  public onAuthStateChanged(f: () => void) {
    auth.onAuthStateChanged(this.auth, f);
  }

  private handleFirebaseError = (action: Promise<any>) => {
    return action.catch((e) => {
      const errorMessage = firebaseErrorCodeToTranslatedDescription[e.code as FirebaseAuthErrorCodes];
      if (!errorMessage) {
        console.error(`Unhandled error code - ${e.code} found`);
      }
      const authError = new Error(errorMessage ?? e.code);
      throw authError;
    });
  };

  public async loginWithPassword(email: string, password: string) {
    return this.handleFirebaseError(
      this.auth.setPersistence(this.getSessionPersistence()).then(() => {
        return auth.signInWithEmailAndPassword(this.auth, email, password);
      })
    );
  }

  public async logInUserWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    return this.handleFirebaseError(
      this.auth.setPersistence(this.getSessionPersistence()).then(() => {
        return auth.signInWithPopup(this.auth, provider);
      })
    );
  }

  public async logInUserWithMicrosoft() {
    const provider = new auth.OAuthProvider('microsoft.com');
    return this.handleFirebaseError(
      auth.setPersistence(this.auth, this.getSessionPersistence()).then(() => {
        return auth.signInWithPopup(this.auth, provider);
      })
    );
  }

  public async logInUserWithSAML(
    samlLoginTenantIds: Record<string, string>,
    samlLoginProviderIds: Record<string, SamlProvider>
  ) {
    this.auth.tenantId = samlLoginTenantIds[window.location.host] ?? null;
    const provider = new SAMLAuthProvider(samlLoginProviderIds[window.location.host]);
    return auth.setPersistence(this.auth, this.getSessionPersistence()).then(() => {
      return signInWithPopup(this.auth, provider)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          throw error;
        });
    });
  }

  public async logOutUser() {
    return this.handleFirebaseError(auth.signOut(this.auth).catch(() => console.error("Couldn't log user out")));
  }

  public currentUser() {
    return this.auth.currentUser;
  }

  public isLoggedIn() {
    return this.currentUser() ? true : false;
  }

  public async getAccessToken(forceRefresh?: boolean) {
    return this.currentUser()?.getIdToken(Boolean(forceRefresh));
  }

  public async createUserWithEmailAndPassword(email: string, password: string) {
    return this.handleFirebaseError(auth.createUserWithEmailAndPassword(this.auth, email, password));
  }

  public async sendVerificationEmail() {
    const hostUrl = environmentService.getHostUrl();
    const actionObj: auth.ActionCodeSettings = {
      url: hostUrl + '/create-account-flow/create-company',
    };
    const user = this.currentUser();
    return user ? this.handleFirebaseError(auth.sendEmailVerification(user, actionObj)) : null;
  }

  public async updatePassword(email: string, currentPassword: string, newPassword: string) {
    const credential = auth.EmailAuthProvider.credential(email, currentPassword);
    const user = this.currentUser();
    return user
      ? this.handleFirebaseError(
          auth.reauthenticateWithCredential(user, credential).then(() => auth.updatePassword(user, newPassword))
        )
      : null;
  }

  public async applyActionCode(actionCode: string) {
    return this.handleFirebaseError(auth.applyActionCode(this.auth, actionCode));
  }

  private getSessionPersistence: () => auth.Persistence = () => {
    if (environmentService.isTest) {
      return auth.inMemoryPersistence;
    }
    if (environmentService.isLocalhost) {
      return auth.browserLocalPersistence;
    } else {
      return auth.browserSessionPersistence;
    }
  };

  public async verifyPasswordResetCode(code: string) {
    return this.handleFirebaseError(auth.verifyPasswordResetCode(this.auth, code));
  }

  public async confirmPasswordReset(code: string, newPassword: string) {
    return this.handleFirebaseError(auth.confirmPasswordReset(this.auth, code, newPassword));
  }

  public async linkWithCredential(user: auth.User, credential: auth.AuthCredential) {
    return this.handleFirebaseError(auth.linkWithCredential(user, credential));
  }
}
