import { action, observable } from 'mobx';
import { GraphQlRequestErrorCode, QueryErrorEntity } from './api/utils';

export class ErrorStore {
  @observable
  public dashboardWithErrorName: string = '';

  @observable
  public errorReference: string = '';

  @observable
  public queryErrors: QueryErrorEntity[] = [];

  public errorDescriptions: Record<GraphQlRequestErrorCode | 'default', string> = {
    [GraphQlRequestErrorCode.query]: 'common:errors.query',
    default: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_AuthorizationDenied]: 'common:errors.authorization',
    [GraphQlRequestErrorCode.Err_RoleNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_UserNotFound]: 'common:errors.userNotFound',
    [GraphQlRequestErrorCode.Err_EmployeeNotFound]: 'common:errors.employeeNotFound',
    [GraphQlRequestErrorCode.Err_OrganizationPathNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_NoLatestVersionFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_NoFirstVersionFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_ReportNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_DashboardNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_AliasNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_PermissionIdNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_DomainSettingsNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_DomainSettingsVersionNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_DomainPreferencesNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_DomainPreferencesVersionNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_RolePreferencesNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_RolePreferencesVersionNotFound]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_UserAlreadyExists]: 'common:errors.userAlreadyExists',
    [GraphQlRequestErrorCode.Err_MetaDataError]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_SameNameRoleAlreadyExists]: 'common:errors.roleAlreadyExists',
    [GraphQlRequestErrorCode.Err_QueryExecutionError]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_MultipleEmployeesForId]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_AmbiguousPopFilter]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_NoReadDataPermissions]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_AmbiguousReadDataPermissions]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_DeleteFileFailed]: 'common:errors.deleteFileFailed',
    [GraphQlRequestErrorCode.Err_ListFilesFailed]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_GetBucketFailed]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_CreateBucketFailed]: 'common:errors.createItemError',
    [GraphQlRequestErrorCode.Err_CreateFolderFailed]: 'common:errors.createItemError',
    [GraphQlRequestErrorCode.Err_CreateFoldersFailed]: 'common:errors.createItemError',
    [GraphQlRequestErrorCode.Err_FilterInvalid]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_SendingEmailError]: 'common:errors.emailSendFailed',
    [GraphQlRequestErrorCode.Err_ExternalSystemUserCreationError]: 'common:errors.couldNotCreateExternalUser',
    [GraphQlRequestErrorCode.Err_ResetPasswordError]: 'common:errors.resetPasswordFailed',
    [GraphQlRequestErrorCode.Err_ValidationFailed]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_Unspecified]: 'common:errors.default',
    [GraphQlRequestErrorCode.Err_DomainAccessDeniedWrongAuthMethod]: 'common:errors.wrongAuthMethod',
    [GraphQlRequestErrorCode.Err_DomainAccessDeniedNotEnabled]: 'common:errors.notEnabled',
  };

  @action
  public setDashboardWithError = (dashboard: string) => (this.dashboardWithErrorName = dashboard);
  @action
  public setErrorReference = (errorReference: string) => (this.errorReference = errorReference);
}
