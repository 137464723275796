import * as Sentry from '@sentry/react';
import { UserCredential } from 'firebase/auth';
import { rootStore } from '../../common/store/root-store';
import { DOMAIN_KEY } from '../api/api-interfaces';
import { environmentService } from '../environment/environment-service';
import { ErrorGroups, ErrorTypes } from './types';

export const trackAPIErrorSimple = (error: Error, errorType?: ErrorTypes) => {
  return trackAPIError(error, {}, {}, errorType);
};

export const trackErrorSimple = (error: Error, errorType: ErrorTypes) => {
  return trackError(error.message, {}, {}, errorType);
};

export const trackAPIError = (
  error: Error,
  contexts: Record<string, any> = {},
  tags: Record<string, any> = {},
  errorType?: ErrorTypes
) => {
  return trackError(error.message, contexts, { ...tags, 'panalyt.errorGroup': ErrorGroups.API }, errorType);
};

export const trackError = (
  errorMsg: string,
  contexts: Record<string, any> = {},
  tags: Record<string, any> = {},
  errorType?: ErrorTypes
) => {
  if (Sentry) {
    const { authStore, userStore, permissionsStore } = rootStore || {};
    const { currentUser: authCurrentUser } = authStore || {};
    const { currentUser } = userStore || {};
    const executorRole = permissionsStore.executorRoleReady() ? permissionsStore.getExecutorRole() : null;
    const domain = localStorage.getItem(DOMAIN_KEY);
    const subDomain = environmentService.getSubDomain();
    Sentry.withScope((scope) => {
      scope.setUser({
        ...scope.getUser(),
        email: currentUser?.email ?? authCurrentUser?.email ?? 'UNKNOWN',
        username: authCurrentUser?.displayName ?? undefined,
      });
      scope.setTag('panalyt.errorType', errorType);
      scope.setTag('panalyt.errorGroup', tags['panalyt.errorGroup'] ?? ErrorGroups.GENERAL);
      scope.setTag('panalyt.domain', domain ?? 'UNKNOWN');
      scope.setTag('panalyt.subDomain', subDomain);
      if (executorRole) {
        scope.setTag('panalyt.executorRole.id', executorRole.id);
        scope.setTag('panalyt.executorRole.name', executorRole.name);
        scope.setTag('panalyt.executorRole.domain', executorRole.domain);
      }
      scope.setTags(tags);

      Sentry.captureException(new Error(errorMsg), {
        contexts,
      });
    });
  }
};

export const trackAuthenticationError = (
  error: Error,
  authenticationMethod: string,
  userCredential: UserCredential | null = null,
  extraCustomData: Record<string, any> = {},
  extraTags: Record<string, any> = {}
) => {
  const { userStore } = rootStore || {};
  const { currentUser } = userStore || {};
  const subDomain = environmentService.getSubDomain();
  if (Sentry) {
    Sentry.withScope((scope) => {
      scope.setUser({
        ...scope.getUser(),
        email: currentUser?.email ?? userCredential?.user?.email ?? (extraCustomData.email as string) ?? 'UNKNOWN',
      });
      scope.setTag('panalyt.errorType', ErrorTypes.AUTHENTICATION);
      scope.setTag('panalyt.subDomain', subDomain);
      scope.setTag('authenticationActionType', authenticationMethod);
      scope.setTags(extraTags);

      let extraFromUserCredentials = {};
      if (userCredential) {
        extraFromUserCredentials = {
          operationType: userCredential.operationType,
          providerId: userCredential.providerId,
          email: userCredential.user.email,
          emailVerified: userCredential.user.emailVerified,
          userProviderId: userCredential.user.providerId,
          providerData: userCredential.user.providerData,
          tenantId: userCredential.user.tenantId,
        };
      }
      scope.setExtra('details', {
        ...extraFromUserCredentials,
        ...extraCustomData,
      });
      Sentry.captureException(error);
      Sentry.setExtras({ customData: undefined }); // Reset extras so that they are not used within the next request
    });
  }
};
