export enum BenchmarkCategory {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  TIME = 'TIME',
}

export enum InternalBenchmarkTypes {
  MyPop = 'My Pop.',
  CompanyTotal = 'Company Total',
  CompanySameFilters = 'Company Same Filters',
}

export enum ExternalBenchmarkTypes {
  PDU = 'PDU',
  PRDU = 'PRDU',
}

export enum TimeBenchmarkTypes {
  YEAR = 'YEAR',
  FINQUARTER = 'FINQUARTER',
  PRIOR_PERIOD = 'PRIOR_PERIOD',
  SPECIFIC_PERIOD = 'SPECIFIC_PERIOD',
}

export type BenchmarkTypes = InternalBenchmarkTypes | ExternalBenchmarkTypes | TimeBenchmarkTypes;

export interface Benchmark {
  type: BenchmarkCategory;
  value: BenchmarkTypes;
  isDisabled?: () => boolean;
}
