import { GraphQlRequestService } from '../../../api/graphql-request-service';
import { Domains, EXECUTOR_ROLE_KEY, ReservedRoleNames, SIMULATED_ROLE_KEY } from '../../constants';
import { Role, RoleId } from '../types';

export interface RoleService {
  getExecutorRolesForRole: (roleId: RoleId, simulatedRole: Role | null) => Promise<Role[]>;
  getExecutorRole: (allExecutorRoles: Role[], simulatedRole: Role | null) => Role;
  getEffectiveRoleId: (simulatedRole: Role | null) => RoleId;
}

export class BackendRoleService implements RoleService {
  constructor(
    readonly graphQlRequestService: GraphQlRequestService,
    readonly userRoleId: RoleId,
    readonly domain: Domains
  ) {}

  public getExecutorRolesForRole = async (roleId: RoleId, simulatedRole: Role | null): Promise<Role[]> => {
    const result = await this.graphQlRequestService.graphQlSdk.listExecutorRolesForRole({
      roleId,
      domainFilter: this.domain,
      simulateRole: simulatedRole?.id ?? null,
    });
    return result.listExecutorRolesForRole?.map((r) => ({ id: r.id, name: r.name, domain: r.domain as Domains })) ?? [];
  };

  public getEffectiveRoleId = (simulatedRole: Role | null): RoleId => {
    return simulatedRole?.id ?? this.userRoleId;
  };

  public getExecutorRole = (allExecutorRoles: Role[], simulateRole: Role | null): Role => {
    const superAdminRole = allExecutorRoles.find((r) => r.name === ReservedRoleNames.SUPERADMIN);
    const defaultExecutorRole = (superAdminRole ?? allExecutorRoles.first() ?? simulateRole) as Role;
    return defaultExecutorRole;
  };
}

export class LocalStorageRoleService implements RoleService {
  constructor(readonly underlyingService: RoleService) {}

  public getExecutorRolesForRole = (roleId: RoleId, simulatedRole: Role | null) =>
    this.underlyingService.getExecutorRolesForRole(roleId, simulatedRole);

  public getEffectiveRoleId = (simulatedRole: Role | null) => this.underlyingService.getEffectiveRoleId(simulatedRole);

  public getSimulatedRole = (): Role | null => {
    const roleStringified = localStorage.getItem(SIMULATED_ROLE_KEY);
    const storedExecutorRole = JSON.parse(roleStringified ?? '{}') as Role;
    return storedExecutorRole;
  };

  public getExecutorRole = (allExecutorRoles: Role[], simulateRole: Role | null): Role => {
    const roleStringified = localStorage.getItem(EXECUTOR_ROLE_KEY);
    const storedExecutorRole = JSON.parse(roleStringified ?? '{}') as Role;
    const isValidExecutorRole = allExecutorRoles.find((r) => r.id === storedExecutorRole.id);
    return isValidExecutorRole
      ? storedExecutorRole
      : this.underlyingService.getExecutorRole(allExecutorRoles, simulateRole);
  };
}
