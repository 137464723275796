import moment from 'moment';
import i18n from '../../../../../i18n';
import { Granularity, Months, TIMEZONE_FORMAT } from '../../date-manager-constants';
import { DateFormatUtilityMethodsForLocale } from '../../date-manager-types';
import {
  getEndOfFiscalYear,
  getEndOfProvidedQuarter,
  getFiscalQuarter,
  getStartOfFiscalYear,
} from '../../date-manager-utils';

export const getFinancialYearAbbreviation = () => i18n.t('common:commonValues.financialYearAbbreviation');

export const ENGLISH_SHORT_DATE_FORMAT = "MMM 'YY";
export const ENGLISH_QUARTER_DATE_FORMAT = "[Q]Q 'YY";
export const ENGLISH_DAY_DATE_FORMAT = "MMM DD, 'YY";
export const ENGLISH_YEAR_FORMAT = 'YYYY';

export const ENGLISH_DATE_TIME_TIMEZONE_FORMAT = 'YYYY-MM-DD HH:mm z';

export const englishDateFormatUtilityMethods: DateFormatUtilityMethodsForLocale = {
  [Granularity.MONTH]: {
    formatter: (date) => date.format(ENGLISH_SHORT_DATE_FORMAT),
    parser: (formattedDate) => moment(formattedDate, ENGLISH_SHORT_DATE_FORMAT, true).endOf(Granularity.MONTH),
  },
  [Granularity.FINQUARTER]: {
    formatter: (date, firstMonthOfYear) => {
      // TODO: Make firstMonthOfYear typescript required here.
      // For now doing a runtime check so this doesn't slip through the cracks
      if (!firstMonthOfYear) {
        throw new Error(`Date formatting error - firsMonthOfYear missing in quarter formatting`);
      }
      const startOfFiscalYear = getStartOfFiscalYear(date, firstMonthOfYear).year();
      return `Q${getFiscalQuarter(date, firstMonthOfYear)}, ${getFinancialYearAbbreviation()}${startOfFiscalYear}`;
    },
    parser: (date, firstMonthOfYear) => {
      // TODO: Make firstMonthOfYear typescript required here.
      // For now doing a runtime check so this doesn't slip through the cracks
      if (!firstMonthOfYear) {
        throw new Error(`Date formatting error - firstMonthOfYear missing in quarter formatting`);
      }
      const quarterNum = Number(date[1]);
      const year = date.slice(date.indexOf(getFinancialYearAbbreviation()) + getFinancialYearAbbreviation().length);
      const endOfQuarterDate = getEndOfProvidedQuarter(
        moment(year, ENGLISH_YEAR_FORMAT, true).endOf(Granularity.YEAR),
        firstMonthOfYear,
        quarterNum
      );
      // Doing endOf because last date of calendar year XXXX will
      // always belong in FYXXXX
      return endOfQuarterDate;
    },
  },
  [Granularity.YEAR]: {
    formatter: (date) => date.format(ENGLISH_YEAR_FORMAT),
    parser: (formattedDate) => moment(formattedDate, ENGLISH_YEAR_FORMAT, true).endOf(Granularity.YEAR),
  },
  [Granularity.FINYEAR]: {
    formatter: (date, firstMonthOfYear) => {
      // TODO: Make firstMonthOfYear typescript required here.
      // For now doing a runtime check so this doesn't slip through the cracks
      if (!firstMonthOfYear) {
        throw new Error(`Date formatting error - firsMonthOfYear missing in quarter formatting`);
      }
      return getFinancialYearAbbreviation() + getStartOfFiscalYear(date, firstMonthOfYear).year();
    },
    parser: (formattedDate, firstMonthOfYear) => {
      if (!firstMonthOfYear) {
        throw new Error(`Date formatting error - firstMonthOfYear missing in quarter formatting`);
      }
      const year = formattedDate.substring(getFinancialYearAbbreviation().length);
      const endOfCalendarYear = moment(year, ENGLISH_YEAR_FORMAT, true).endOf(Granularity.YEAR);
      const endOfFiscalYear = getEndOfFiscalYear(endOfCalendarYear, firstMonthOfYear as Months);
      return endOfFiscalYear;
      // This logic is based on the fact that the last date of calendar year XXXX will
      // always belong in FYXXXX
    },
  },
  [TIMEZONE_FORMAT]: {
    formatter: (date) => {
      return date.tz('Asia/Tokyo').format(ENGLISH_DATE_TIME_TIMEZONE_FORMAT);
    },
    parser: (formattedDate) => {
      return moment(formattedDate, ENGLISH_DATE_TIME_TIMEZONE_FORMAT, true);
    },
  },
};
