import { DataTypes, EmployeeDataFields, EvaluationDataFields } from '../types';

export const defaultFiltersOrder = [
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.POSITION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_ID },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_OR_IC },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.DEFINED_MANAGER },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.START_DATE },
  { dataType: DataTypes.EVALUATION, dataField: EvaluationDataFields.EVALUATION_CYCLE_TYPE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.PERFORMANCE_REGULAR_CYCLE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.NATIONALITY_HIERARCHICAL },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.SOCIAL_TYPE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MARITAL_STATUS },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.REGRET_ATTRITION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_2 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_3 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_4 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_5 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_6 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_7 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_8 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_9 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_10 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_11 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_12 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_13 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_14 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_15 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_16 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_17 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_18 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_19 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FILTER_20 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_2 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_3 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_4 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_5 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_6 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_7 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_8 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_9 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CUSTOM_FIELD_10 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_OR_NON_LOCAL },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.RECRUITMENT_CATEGORY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_TITLE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYEE_ID },
];
