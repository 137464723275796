import { DataTypes } from '../../constants/constants';
import { TimeSliderConfig } from '../../filter/filter-store';
import { trackAPIError } from '../../sentry/sentry';
import { rootStore } from '../../store/root-store';
import { ApiEmployeeSearchResponse, ApiEmployeeSearchResponseItem } from '../api-interfaces';
import { parseCustomSqlValue } from '../graphql-utils';

export interface BenchmarkConfig {
  prduId?: string | null;
  pdu?: boolean;
  limitedToPermittedPopulation?: boolean;
}

export type TimeSliderConfigStartAndEnd = Pick<TimeSliderConfig, 'startDate' | 'endDate'>;

class EmployeeDataApiService {
  public async searchForEmployees(
    domain: string,
    searchInput: string,
    presentOnly: boolean
  ): Promise<ApiEmployeeSearchResponse> {
    try {
      const latestVersion = rootStore.domainDependencyStore.getLatestVersion(DataTypes.EMPLOYEE);
      if (!latestVersion) {
        throw new Error(`No latest version for EMPLOYEE in ${domain}`);
      }

      const employmentTemporality = presentOnly ? "('PRESENT')" : "('PAST', 'PRESENT')";
      const querySql = `
      with similarity_result as (
        select
            greatest(
                case when employee.employeeId = '${searchInput}' then 1 else 0 end,
                case when employee.companyEmail = '${searchInput}' then 1 else 0 end,
                coalesce(similarity(employee.fullName, '${searchInput}'), 0),
                coalesce(similarity(employee.localFullName, '${searchInput}'), 0),
                coalesce(similarity(employee.localFullNamePronunciation, '${searchInput}'), 0),
                coalesce(similarity(employee.preferredName, '${searchInput}'), 0),
                coalesce(similarity(employee.localPreferredName, '${searchInput}'), 0),
                coalesce(similarity(employee.localPreferredNamePronunciation, '${searchInput}'), 0)
            ) as similarity,
          employee.employeeId,
          employee.companyEmail,
          employee.firstName,
          employee.lastName,
          employee.fullName,
          employee.localFullName,
          employee.localFirstName,
          employee.localLastName,
          employee.preferredName,
          employee.localPreferredName,
          employee.jobTitle
        from employee
        where
          employee.versionId = '${latestVersion}'
          and employee.employmentTemporality in ${employmentTemporality}
          and employee.namespace = '${domain}'
        order by
          similarity desc
    ) select
      employeeId,
      companyEmail,
      firstName,
      lastName,
      fullName,
      localFullName,
      localFirstName,
      localLastName,
      preferredName,
      localPreferredName,
      jobTitle,
      similarity
    from similarity_result
    where similarity > 0.1
    limit 15;
  `;

      const responseItems = (
        await rootStore.graphQlRequestService.graphQlSdk.executeWhitelistedSqlQuery({
          domain,
          querySql,
          selectedExecutorRole: rootStore.permissionsStore.getExecutorRole().id,
          simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
        })
      ).executeWhitelistedSqlQuery?.data.map((rows) => {
        const valueRow = rows.map((field) => {
          if (field) {
            const value = parseCustomSqlValue(field);
            if (!value) {
              throw new Error(`Unexpected employee search query response shape for ${rows} and ${field}`);
            } else {
              return value;
            }
          } else {
            return null;
          }
        });

        const nameMapped: ApiEmployeeSearchResponseItem = {
          employeeId: valueRow[0] as string,
          companyEmail: valueRow[1] as string,
          firstName: valueRow[2] as string,
          lastName: valueRow[3] as string,
          fullName: valueRow[4] as string,

          localFullName: valueRow[5] as string,
          localFirstName: valueRow[6] as string,
          localLastName: valueRow[7] as string,
          preferredName: valueRow[8] as string,
          localPreferredName: valueRow[9] as string,
          jobTitle: valueRow[10] as string,
        };

        return nameMapped;
      });

      if (responseItems) {
        return {
          employees: responseItems,
        };
      } else {
        throw new Error('employee search response from backend was undefined');
      }
    } catch (E) {
      console.error(`Cannot run employee search in domain ${domain}. Returning nothing. Reason: ${E}`, E);
      trackAPIError(E);
      return {
        employees: [],
      };
    }
  }
}

export const employeeDataApiService = new EmployeeDataApiService();
