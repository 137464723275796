import memoize from 'fast-memoize';
import isEqual from 'lodash.isequal';
import { action, observable } from 'mobx';
import { DataFieldWithDataType } from '../../../common-types';
import {
  NewApiAliasForDataField,
  NewApiAliasForMetricCategoryId,
  NewApiAliasForMetricGroupId,
} from '../api/api-interfaces';
import { CachedAliasService } from '../api/cached-alias-service';
import { Domains } from '../constants/constants';
import { MetricCategoryId, MetricGroupId } from '../graphql/generated/graphql-sdk';
import { LanguageStore } from '../localization/language-store';

export class AliasStore {
  @observable
  public aliasesForDataField: NewApiAliasForDataField[] = [];

  @observable
  public aliasesForMetricGroupId: NewApiAliasForMetricGroupId[] = [];

  @observable
  public aliasesForMetricCategoryId: NewApiAliasForMetricCategoryId[] = [];

  @observable
  public aliasesLoaded = false;

  public getAliasTextForField = memoize(
    (dataFieldWithDataType: DataFieldWithDataType, aliases: NewApiAliasForDataField[] = this.aliasesForDataField) => {
      const alias = aliases.find((a) => isEqual(a.dataFieldWithDataType, dataFieldWithDataType));
      return alias ? this.getAliasForFieldText(alias) : null;
    }
  );

  public getAliasTextForMetricGroupId = memoize(
    (metricGroupId: MetricGroupId, aliases: NewApiAliasForMetricGroupId[] = this.aliasesForMetricGroupId) => {
      const alias = aliases.find((a) => isEqual(a.metricGroupId, metricGroupId));
      return alias ? this.getAliasForMetricText(alias) : null;
    }
  );

  public getAliasTextForMetricCategoryId = memoize(
    (
      metricCategoryId: MetricCategoryId,
      aliases: NewApiAliasForMetricCategoryId[] = this.aliasesForMetricCategoryId
    ) => {
      const alias = aliases.find((a) => isEqual(a.metricCategoryId, metricCategoryId));
      return alias ? this.getAliasForMetricCategoryText(alias) : null;
    }
  );

  public getAliasForMetricCategoryText = memoize((alias: NewApiAliasForMetricCategoryId) => {
    return alias.translations.find((t) => t.language === this.languageStore.selectedLanguage.id)?.text ?? null;
  });

  public getAliasForFieldText = memoize((alias: NewApiAliasForDataField) => {
    return alias.translations.find((t) => t.language === this.languageStore.selectedLanguage.id)?.text ?? null;
  });

  public getAliasForMetricText = memoize((alias: NewApiAliasForMetricGroupId) => {
    return alias.translations.find((t) => t.language === this.languageStore.selectedLanguage.id)?.text ?? null;
  });

  private cachedAliasService: CachedAliasService;
  private languageStore: LanguageStore;

  constructor(cachedAliasService: CachedAliasService, languageStore: LanguageStore) {
    this.cachedAliasService = cachedAliasService;
    this.languageStore = languageStore;
  }

  @action
  public async loadAliases(domain: Domains) {
    this.cachedAliasService.clearCache();
    this.aliasesForDataField = await this.cachedAliasService.getAliasesForDataFields(domain);
    this.aliasesForMetricGroupId = await this.cachedAliasService.getAliasesForMetricGroupId(domain);
    this.aliasesForMetricCategoryId = await this.cachedAliasService.getAliasesForMetricCategoryId(domain);
    this.aliasesLoaded = true;
  }
}
