import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { PaperProps } from '@mui/material/Paper';
import { PopperProps } from '@mui/material/Popper';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

interface PopUpMenuProps {
  children: any;
  open: boolean;
  onClose: (event: any) => void;
  anchorEl: Element | null;
  placement?: PopperProps['placement'];
  closeOnClickAway?: boolean;
  backgroundColor?: string;
  maxHeight?: string;
  paperStyles?: any;
  [propName: string]: any;
}

const StyledPaper = styled(Paper as React.FC<React.PropsWithChildren<PaperProps>>)<{
  $maxHeight?: string;
  $backgroundColor?: string;
}>`
  background: ${(props) => (props.$backgroundColor ? props.$backgroundColor : '#00a599')};
  & li,
  span {
    color: ${(props) =>
      props.$backgroundColor
        ? 'none'
        : '#fff'}; // if no bg specified and bg defaults to teal, this is white, otherwise, this shouldn't change color
  }
  max-height: ${(props) => props.$maxHeight || '30vh'};
  overflow: auto;
`;

const StyledPopper = styled(Popper)`
  z-index: 1300;
` as typeof Popper;

const PopUpMenu = observer((props: PopUpMenuProps) => {
  const {
    anchorEl,
    open,
    onClose,
    children,
    placement = 'right-start',
    closeOnClickAway = true,
    backgroundColor,
    maxHeight,
    paperStyles,
    ...rest
  } = props;

  return (
    <StyledPopper
      open={open}
      anchorEl={anchorEl}
      transition
      placement={placement}
      // https://atfzl.com/don-t-attach-tooltips-to-document-body
      container={document.querySelector('#tooltips-container')}
      {...rest}
    >
      {({ TransitionProps, placement: popperPlacement }) => (
        <ClickAwayListener onClickAway={closeOnClickAway ? onClose : () => {}}>
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: popperPlacement === 'bottom' ? 'center top' : 'center bottom',
              borderRadius: 0,
            }}
          >
            <StyledPaper $backgroundColor={backgroundColor} $maxHeight={maxHeight} style={{ ...paperStyles }}>
              {children}
            </StyledPaper>
          </Grow>
        </ClickAwayListener>
      )}
    </StyledPopper>
  );
});

export default PopUpMenu;
