import { DataFieldWithDataType } from '../../../../common-types';
import { DataTypes, EmployeeDataFields } from '../../constants/constants';

export const panalytFieldNameSortOrder: DataFieldWithDataType[] = [
  // RECRUITMENT ORDER,
  // EMPLOYEE ORDER
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MEMBER_ID },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYEE_ID },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FULL_NAME },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.PREFERRED_NAME },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FIRST_NAME },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LAST_NAME },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_FIRST_NAME },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_LAST_NAME },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_FULL_NAME },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_FIRST_NAME_PRONUNCIATION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_LAST_NAME_PRONUNCIATION },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.COMPANY_EMAIL },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_ID },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_EMAIL },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.START_DATE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TERM_DATE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TERM_DATE_NORMALIZED },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EFFECTIVE_LEAVER_DATE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EFFECTIVE_LEAVER_DATE_NORMALIZED },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_TITLE },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_2 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_3 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_4 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_5 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_6 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_7 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_8 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_9 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_2 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_3 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_4 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_5 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_6 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_7 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_8 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_9 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION_LEVEL_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION_LEVEL_2 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION_LEVEL_3 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION_LEVEL_4 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION_LEVEL_5 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION_LEVEL_6 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION_LEVEL_7 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION_LEVEL_8 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FUNCTION_LEVEL_9 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_2 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_3 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_4 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_5 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_6 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_7 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_8 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_9 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_2 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_3 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_4 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_5 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_6 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_7 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_8 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_9 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.DATE_OF_BIRTH },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.COMPANY_COUNTRY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_OR_NON_LOCAL },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.NATIONALITY_HIERARCHICAL_LEVEL_1 },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.BASE_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.BASE_SALARY_CURRENCY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_SALARY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_SALARY_CURRENCY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TOTAL_COMPENSATION },
];
