import { useSuspenseQuery } from '@tanstack/react-query';
import { TimeRangesService } from './service';

const queryKey = ['get-time-ranges'];
export const useTimeRanges = (timeRangesService: TimeRangesService) => {
  return useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      return timeRangesService.getTimeRanges();
    },
  });
};
