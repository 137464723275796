import { GraphQlRequestService } from '../../../api/graphql-request-service';
import { Role, RoleId } from '../types';
import { AssignedPermission, AssignedPermissions, Permission } from './types';

export interface PermissionService {
  getExecutorRolePermissions: (executorRole: Role) => Promise<AssignedPermissions<Permission>>;
  getAllPermissionsForRole: (roleId: RoleId) => Promise<AssignedPermission<Permission>[]>;
}

export class BackendPermissionService implements PermissionService {
  constructor(readonly graphQlRequestService: GraphQlRequestService) {}

  public getExecutorRolePermissions = async (executorRole: Role) => {
    const result = await this.graphQlRequestService.graphQlSdk.directPermissions({
      roleId: executorRole.id,
    });
    const directPermissions: Array<AssignedPermission<Permission>> = result.directPermissions ?? [];
    return new AssignedPermissions(directPermissions);
  };

  public getAllPermissionsForRole = async (roleId: RoleId) => {
    const result = await this.graphQlRequestService.graphQlSdk.allPermissions({
      roleId,
    });
    const directPermissions: AssignedPermission<Permission>[] = result.allPermissions ?? [];
    return directPermissions;
  };
}
