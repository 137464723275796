import { Moment } from 'moment';
import { match } from 'ts-pattern';
import { CONFIDENTIAL_VALUE } from '../constants/constants';
import { dateManagerService } from '../date-manager/date-manager-service';
import { ResponseValue } from '../graphql/generated/graphql-sdk';
import { CustomSqlQueryValueBackendType } from './graphql-types';

export const parseCustomSqlValue = (
  value: CustomSqlQueryValueBackendType | null
): boolean | number | string | Moment | null => {
  if (!value) {
    return null;
  }
  const { formatDateApi, parseApiDate } = dateManagerService;
  return match(value)
    .with(
      { __typename: 'BigDecimalValue' },
      (valueWithType) => valueWithType.bigDecimal as number // TODO: Handle BigDecimal without loss of precision
    )
    .with({ __typename: 'BooleanValue' }, (valueWithType) => valueWithType.boolean)
    .with({ __typename: 'Confidential' }, () => CONFIDENTIAL_VALUE)
    .with({ __typename: 'DateValue' }, (valueWithType) => formatDateApi(parseApiDate(valueWithType.date)))
    .with({ __typename: 'DoubleValue' }, (valueWithType) => valueWithType.double)
    .with({ __typename: 'IntValue' }, (valueWithType) => valueWithType.int)
    .with({ __typename: 'StringValue' }, (valueWithType) => valueWithType.string)
    .with({ __typename: 'TimestampValue' }, (valueWithType) => valueWithType.timestamp as string)
    .with({ __typename: 'JsonValue' }, (valueWithType) => valueWithType.json)
    .otherwise(() => {
      throw new Error(`Unknown value type: ${value}`);
    });
};

export const parseResponseValue = (value: ResponseValue | null): boolean | number | string | Moment | null => {
  if (!value) {
    return null;
  }
  const { formatDateApi, parseApiDate } = dateManagerService;
  return match(value)
    .with(
      { __typename: 'BigDecimalValue' },
      (valueWithType) => valueWithType.value as number // TODO: Handle BigDecimal without loss of precision
    )
    .with({ __typename: 'BooleanValue' }, (valueWithType) => valueWithType.value)
    .with({ __typename: 'Confidential' }, () => CONFIDENTIAL_VALUE)
    .with({ __typename: 'DateValue' }, (valueWithType) => formatDateApi(parseApiDate(valueWithType.value)))
    .with({ __typename: 'DoubleValue' }, (valueWithType) => valueWithType.value)
    .with({ __typename: 'IntValue' }, (valueWithType) => valueWithType.value)
    .with({ __typename: 'StringValue' }, (valueWithType) => valueWithType.value)
    .with({ __typename: 'TimestampValue' }, (valueWithType) => valueWithType.value as string)
    .with({ __typename: 'JsonValue' }, (valueWithType) => valueWithType.value as string)
    .otherwise(() => {
      throw new Error(`Unknown value type: ${value}`);
    });
};
