import moment from 'moment';
import { Granularity, TIMEZONE_FORMAT } from '../../date-manager-constants';
import { DateFormatUtilityMethodsForLocale } from '../../date-manager-types';
import { englishDateFormatUtilityMethods, ENGLISH_DATE_TIME_TIMEZONE_FORMAT } from './english-date-locale-settings';

export const JAPANESE_SHORT_DATE_FORMAT = "'YY年M月";
export const JAPANESE_YEAR_FORMAT = 'YYYY年';
const YEAR_FORMAT = 'YYYY';

export const japaneseDateFormatUtilityMethods: DateFormatUtilityMethodsForLocale = {
  [Granularity.MONTH]: {
    formatter: (date) => date.format(JAPANESE_SHORT_DATE_FORMAT),
    parser: (formattedDate) => moment(formattedDate, JAPANESE_SHORT_DATE_FORMAT, true).endOf(Granularity.MONTH),
  },
  [Granularity.FINQUARTER]: englishDateFormatUtilityMethods[Granularity.FINQUARTER],
  [Granularity.YEAR]: {
    formatter: (date) => date.format(JAPANESE_YEAR_FORMAT),
    parser: (formattedDate) => moment(formattedDate, JAPANESE_YEAR_FORMAT, true).endOf(Granularity.YEAR),
  },
  [Granularity.FINYEAR]: englishDateFormatUtilityMethods[Granularity.FINYEAR],
  [TIMEZONE_FORMAT]: {
    formatter: (date) => {
      return date.tz('Asia/Tokyo').format(ENGLISH_DATE_TIME_TIMEZONE_FORMAT);
    },
    parser: (formattedDate) => {
      return moment(formattedDate, ENGLISH_DATE_TIME_TIMEZONE_FORMAT, true);
    },
  },
};
