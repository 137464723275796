import { $UndefinedValueIndicator$, ApiMasterDataQueryResponseDataPoint } from '../api/api-interfaces';
import { CONFIDENTIAL_VALUE } from '../constants/constants';

export const validateEmail = (email: string) => {
  const mailformat = /^[^@]+@[^@]+$/;

  if (email.match(mailformat)) {
    return true;
  }
  return false;
};

export const dataPointHasMeasures = (dp: ApiMasterDataQueryResponseDataPoint) => dp && dp.measures;

export const containsConfidentialData = (dataMap: any, labels: string[], dataKey: string) =>
  labels.some((label) => label === CONFIDENTIAL_VALUE || dataMap?.[label]?.[dataKey] === CONFIDENTIAL_VALUE);

export const isNullValue = (val: any) => {
  return val === undefined || val === null || val.trim() === '' || val.includes($UndefinedValueIndicator$);
};

export const isValidNumber = (val: any) => {
  if (val === 0 || val === '0') {
    return true;
  } else {
    return Boolean(Number(val));
  }
};
