import { useSuspenseQuery } from '@tanstack/react-query';
import { ApiMasterDataType } from '../../graphql/generated/graphql-sdk';
import { AlexDashboardChartConfig } from '../settings/alex_dashboard_config';
import { DataFieldWithDataType } from '../types';
import { AuthorizationService } from './authorization/service';
import { BackendService } from './service';
import { LatestDomainPreferences } from './types';

const queryKey = ['domain-dependencies'];
export const useDomainDependencies = (backendService: BackendService) => {
  return useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const result = await backendService.fetchDomainDependencies();
      return result;
    },
  });
};

export const useLatestVersion = (backendService: BackendService, dataType: ApiMasterDataType) => {
  const { data: domainDependencies } = useDomainDependencies(backendService);
  const latestVersion: string = domainDependencies?.domainInfo?.allLatestVersions?.find((v) => v.dataType === dataType)
    ?.version as string;
  return latestVersion;
};

export const useLatestPreferences = (backendService: BackendService) => {
  const { data: domainDependencies } = useDomainDependencies(backendService);
  return domainDependencies?.domainInfo?.latestPreferences as unknown as LatestDomainPreferences;
};

export const useAllowedFilters = (backendService: BackendService) => {
  const latestPreferences = useLatestPreferences(backendService);
  return latestPreferences?.settings?.allowedFilters as DataFieldWithDataType[] | null;
};

export const usePermittedFilters = (backendService: BackendService, authorizationService: AuthorizationService) => {
  const allowedFilters = useAllowedFilters(backendService);
  return allowedFilters?.filter(
    (f) => authorizationService.isFilterAllowed(f) && authorizationService.isFieldVisible(f)
  );
};

export const useCustomDashboardConfig = () => {
  return [AlexDashboardChartConfig];
  // return this.domainDependencies?.domainInfo?.customDashboardConfigs;
};
