import { RegularMetricId } from '../../graphql/generated/graphql-sdk';
import { DashboardConfig } from '../common/components/dashboards/types';
import { ApplicationDataFields, DataTypes, EmployeeDataFields, TimeSelectionType } from '../types';
import { addYear, date, format, monthEnd, now } from '../utils-date';

// TODO: review this as we won't be able to store logic in json settings
// we should have two options: relative (e.g. last 3 months) or absolute timeframes (e.g. 2023 to 2024)
const xYearsAgo = (x: number) => addYear(now(), -x);
const lastYear = addYear(now(), -1);
const lastYearEndOfMonth = format(monthEnd(lastYear));
const nowEndOfMonth = format(monthEnd(now()));

export const AlexDashboardChartConfig: DashboardConfig = {
  id: 'themis',
  title: 'Themis Project',
  enabled: true,
  link: '/dashboard/custom/themis',
  pages: [
    {
      pageId: '1',
      pageNumber: 1,
      link: '/dashboard/custom/themis/1',
      enabled: true,
      tabName: 'DEMAND',
      pageOptions: {
        disableDataView: false,
      },
      components: [
        {
          chartId: 'attrition-ttm',
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0076_3AttritionTtm }],
              timeSelection: { type: TimeSelectionType.FinancialYearYearly, input: { start: 2022, end: 2023 } },
            },
          ],
        },
        {
          chartId: '',
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0019_2AgeAvg },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0010_1MngCalcCount },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0020_1FhcCount },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0023_1TenureMedian },
              ],
              timeSelection: {
                type: TimeSelectionType.FinancialYearQuarterly,
                input: { start: { quarterOfYear: 2, year: 2022 }, end: { quarterOfYear: 3, year: 2023 } },
              },
            },
          ],
        },
        {
          chartId: 'joiners',
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
              timeSelection: { type: TimeSelectionType.CalendarYearYearly, input: { start: 2020, end: 2023 } },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'individuals-count',
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: '2022-08-31', end: '2022-08-31' },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'new-joiners-managers-by-gender',
          title: 'New Joiners Managers by Gender ',
          chartTypeConfig: { chartType: 'area' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0014_1MngDefCount }],
              timeSelection: {
                type: TimeSelectionType.FinancialYearQuarterly,
                input: { start: { quarterOfYear: 2, year: 2022 }, end: { quarterOfYear: 3, year: 2023 } },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
              filters: `EMPLOYEE.TENURE_GROUP = ('6ヶ月未満')`,
            },
          ],
        },
        {
          chartId: 'leavers-by-gender',
          title: 'Leavers by Gender',
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime', stack: false, relative: true },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: '2022-06-01', end: '2023-09-30' },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                  filters: ['Male', 'Female'],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '2',
      pageNumber: 2,
      link: '/dashboard/custom/themis/2',
      enabled: true,
      tabName: 'SUPPLY',
      pageOptions: {
        disableDataView: false,
      },
      components: [
        {
          chartId: 'female-managers',
          title: 'Female Managers',
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0016_1FMngDefCount },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0016_2FMngDefPercHc },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: format(lastYear), end: format(now()) },
              },
            },
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0015_1NewMngDefCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: format(xYearsAgo(2)), end: format(monthEnd(lastYear)) },
              },
            },
          ],
        },
        {
          chartId: 'new-managers-by-gender',
          title: 'New Managers by Gender',
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0015_1NewMngDefCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearYearly,
                input: { start: date(now()).year() - 5, end: date(now()).year() },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
            },
          ],
        },
        {
          chartId: 'female-managers-by-department',
          title: 'Female Managers by Department',
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0016_1FMngDefCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'management-promotion-pct-hc',
          title: 'Management Promotion % of HC',
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0196ManagementPromotionPercOfHeadcount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearYearly,
                input: { start: date(now()).year() - 5, end: date(now()).year() },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
            },
          ],
        },
        {
          chartId: 'applications-by-gender',
          title: 'Applications by Gender',
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0252_2ApplicationsCount }],
              timeSelection: {
                type: TimeSelectionType.FinancialYearYearly,
                input: { start: date(now()).year() - 2, end: date(now()).year() },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.CUSTOM_FILTER_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'applications-by-source',
          title: 'Applications by Source',
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime', stack: false, relative: true },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0252_2ApplicationsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearYearly,
                input: { start: 2019, end: 2021 },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.SOURCE_LEVEL_1 },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '3',
      pageNumber: 3,
      link: '/dashboard/custom/themis/3',
      enabled: true,
      tabName: 'OFFER',
      pageOptions: {
        disableDataView: false,
      },
      gridCols: 4,
      gridRows: 1,
      components: [
        {
          chartId: 'parental-leave-utilization-by-gender',
          title: 'Parental leave utilization by Gender',
          gridColSpan: 1,
          chartTypeConfig: { chartType: 'pie', variant: 'donut' },
          query: {
            metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0171ParentalLeaveTakenUtilizationPerc }],
            timeSelection: {
              type: TimeSelectionType.CalendarYearSingleValueByMonths,
              input: { start: '2021-01-01', end: '2021-12-31' },
            },
            segmentations: [
              {
                type: 'non-hierarchical',
                dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
              },
            ],
          },
        },
        {
          chartId: 'individuals-by-department-by-age-group',
          title: 'Individuals by Department and by Age Group',
          gridColSpan: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod', stack: true },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '4',
      pageNumber: 4,
      link: '/dashboard/custom/themis/4',
      enabled: true,
      tabName: 'Themis 4',
      pageOptions: {
        disableDataView: false,
      },
      gridCols: 4,
      components: [
        {
          chartId: 'individuals-count',
          title: 'Individuals Count',
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count',
          title: 'Joiners & Leavers Count',
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [
                  { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                  { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
                ],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
        {
          chartId: 'individuals-count-2',
          title: 'Individuals Count',
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-2',
          title: 'Joiners & Leavers Count',
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [
                  { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                  { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
                ],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
        {
          chartId: 'individuals-by-department-by-age-group',
          title: 'Individuals by Department and by Age Group',
          gridColSpan: 4,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod', stack: true },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-over-time',
          title: 'Joiners & Leavers Over Time',
          gridColSpan: 4,
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
            },
          ],
        },
      ],
    },
    {
      pageId: '5',
      pageNumber: 5,
      link: '/dashboard/custom/themis/5',
      enabled: true,
      tabName: 'Joiners & Leavers',
      pageOptions: {
        disableDataView: false,
      },
      gridCols: 4,
      components: [
        {
          chartId: 'joiners-and-leavers-count-over-time',
          title: 'Joiners & Leavers Over Time',
          gridColSpan: 4,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-org',
          title: 'Organization',
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-location',
          title: 'Location',
          gridColSpan: 2,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-tenure',
          title: 'Tenure',
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-gender',
          title: 'Gender',
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-job-grade',
          title: 'Job Grade',
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-employment-type',
          title: 'Employment Type',
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'joiners-and-leavers-count-by-age-group',
          title: 'Age Group',
          gridColSpan: 1,
          gridRowSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [
                { type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners },
                { type: 'RegularMetricId', value: RegularMetricId.Pm0076_4AttritionLeavers },
              ],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      pageId: '6',
      pageNumber: 6,
      link: '/dashboard/custom/themis/6',
      enabled: true,
      tabName: 'People Snapshot: Headcount',
      pageOptions: {
        disableDataView: false,
      },
      gridCols: 3,
      components: [
        {
          chartId: 'people-snapshot-headcount-over-time',
          title: 'Headcount Over Time',
          gridColSpan: 3,
          chartTypeConfig: { chartType: 'line' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: lastYearEndOfMonth, end: nowEndOfMonth },
              },
            },
          ],
        },
        {
          chartId: 'people-snapshot-headcount-by-employment-type',
          title: 'Employment Type',
          gridColSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-headcount-factbox',
          gridColSpan: 1,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [
                  { type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount },
                  { type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount },
                ],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
        {
          chartId: 'people-snapshot-headcount-by-year',
          title: 'Headcount by Year',
          gridColSpan: 1,
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearYearly,
                input: { start: date(now()).year() - 14, end: date(now()).year() },
              },
            },
          ],
        },
      ],
    },
    {
      pageId: '7',
      pageNumber: 7,
      link: '/dashboard/custom/themis/7',
      enabled: true,
      tabName: 'People Snapshot: Demographics(Headcount)',
      pageOptions: {
        disableDataView: false,
      },
      gridCols: 6,
      gridRows: 4,
      components: [
        {
          chartId: 'people-snapshot-demographic-by-organization',
          title: 'Organization',
          gridColStart: 1,
          gridColEnd: 3,
          gridRowStart: 1,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-location',
          title: 'Location',
          gridColStart: 3,
          gridColEnd: 6,
          gridRowStart: 1,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-tenure',
          title: 'Tenure',
          gridColStart: 1,
          gridColEnd: 2,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-gender',
          title: 'Gender',
          gridColStart: 2,
          gridColEnd: 3,
          gridRowStart: 3,
          gridRowEnd: 5,
          colors: ['#7245b2', '#25b1e4'],
          chartTypeConfig: { chartType: 'pie' },
          query: {
            metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
            timeSelection: {
              type: TimeSelectionType.CalendarYearSingleValueByMonths,
              input: { start: nowEndOfMonth, end: nowEndOfMonth },
            },
            segmentations: [
              {
                type: 'non-hierarchical',
                dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
              },
            ],
          },
        },
        {
          chartId: 'people-snapshot-demographic-by-job-grade',
          title: 'Job Grade',
          gridColStart: 3,
          gridColEnd: 4,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-employment-type',
          title: 'Employment Type',
          gridColStart: 4,
          gridColEnd: 5,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-age-group',
          title: 'Age Group',
          gridColStart: 5,
          gridColEnd: 6,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-hc',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 1,
          gridRowEnd: 2,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-tenure',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 2,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0023_2TenureAvg }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-gender-ratio',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 3,
          gridRowEnd: 4,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
                segmentations: [
                  {
                    type: 'non-hierarchical',
                    dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                  },
                ],
              },
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-age',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 4,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0019_2AgeAvg }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
      ],
    },
    {
      pageId: '8',
      pageNumber: 8,
      link: '/dashboard/custom/themis/8',
      enabled: true,
      tabName: 'People Snapshot with Time slider',
      pageOptions: {
        disableDataView: false,
        timeSliderOptions: { enabled: true },
      },
      gridCols: 6,
      gridRows: 4,
      components: [
        {
          chartId: 'people-snapshot-demographic-by-organization',
          title: 'Organization',
          gridColStart: 1,
          gridColEnd: 3,
          gridRowStart: 1,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-location',
          title: 'Location',
          gridColStart: 3,
          gridColEnd: 6,
          gridRowStart: 1,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-tenure',
          title: 'Tenure',
          gridColStart: 1,
          gridColEnd: 2,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-gender',
          title: 'Gender',
          gridColStart: 2,
          gridColEnd: 3,
          gridRowStart: 3,
          gridRowEnd: 5,
          colors: ['#7245b2', '#25b1e4'],
          chartTypeConfig: { chartType: 'pie' },
          query: {
            metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
            timeSelection: {
              type: TimeSelectionType.CalendarYearSingleValueByMonths,
              input: { start: nowEndOfMonth, end: nowEndOfMonth },
            },
            segmentations: [
              {
                type: 'non-hierarchical',
                dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
              },
            ],
          },
        },
        {
          chartId: 'people-snapshot-demographic-by-job-grade',
          title: 'Job Grade',
          gridColStart: 3,
          gridColEnd: 4,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_GRADE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-employment-type',
          title: 'Employment Type',
          gridColStart: 4,
          gridColEnd: 5,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TYPE_LEVEL_1 },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-by-age-group',
          title: 'Age Group',
          gridColStart: 5,
          gridColEnd: 6,
          gridRowStart: 3,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'bar', timeframe: 'timePeriod' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearSingleValueByMonths,
                input: { start: nowEndOfMonth, end: nowEndOfMonth },
              },
              segmentations: [
                {
                  type: 'non-hierarchical',
                  dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
                },
              ],
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-hc',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 1,
          gridRowEnd: 2,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-tenure',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 2,
          gridRowEnd: 3,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0023_2TenureAvg }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-gender-ratio',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 3,
          gridRowEnd: 4,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
                segmentations: [
                  {
                    type: 'non-hierarchical',
                    dataField: { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
                  },
                ],
              },
            },
          ],
        },
        {
          chartId: 'people-snapshot-demographic-factbox-age',
          gridColStart: 6,
          gridColEnd: 7,
          gridRowStart: 4,
          gridRowEnd: 5,
          chartTypeConfig: { chartType: 'kpi' },
          sections: [
            {
              query: {
                metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0019_2AgeAvg }],
                timeSelection: {
                  type: TimeSelectionType.CalendarYearSingleValueByMonths,
                  input: { start: nowEndOfMonth, end: nowEndOfMonth },
                },
              },
            },
          ],
        },
      ],
    },
    {
      pageId: '9',
      pageNumber: 9,
      link: '/dashboard/custom/themis/9',
      enabled: true,
      tabName: 'G3 Page',
      pageOptions: {
        disableDataView: false,
        timeSliderOptions: { enabled: false },
      },
      gridCols: 6,
      components: [
        {
          chartId: 'people-snapshot-demographic-by-organization',
          title: 'Individual Count',
          gridColSpan: 2,
          gridRowSpan: 2,
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0001_1IndividualsCount }],
              timeSelection: {
                type: TimeSelectionType.FinancialYearYearly,
                input: { start: 2023, end: 2024 },
              },
            },
          ],
        },
        {
          chartId: 'joiners-this-month',
          title: 'Joiners & Leavers',
          gridColSpan: 2,
          gridRowSpan: 2,
          chartTypeConfig: { chartType: 'bar', timeframe: 'overTime' },
          queries: [
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0006_1JoinersJoiners }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: '2024-06-30', end: '2024-06-30' },
              },
            },
            {
              metrics: [{ type: 'RegularMetricId', value: RegularMetricId.Pm0007_1LeaversTermDateLeavers }],
              timeSelection: {
                type: TimeSelectionType.CalendarYearMonthly,
                input: { start: '2024-06-30', end: '2024-06-30' },
              },
              filters: `EMPLOYEE.CUSTOM_FIELD_1_LEVEL_1 = ('自己都合')`,
            },
          ],
        },
      ],
    },
  ],
};
