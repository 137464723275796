import { toSql, parseFirst, Statement } from 'pgsql-ast-parser/lib';
import { GraphQlRequestService } from '../../../api/graphql-request-service';
import { Months, TimeSliderState } from '../../common/components/timeslider/types';
import { Domains, termDateNormalizedField } from '../../constants';
import { DataFieldWithDataType, DataTypes } from '../../types';
import { startDateFromTimeRangeType } from '../timeranges/utils';
import { Role } from '../types';
import { CustomSqlQueryValueBackendType, EMPLOYMENT_TEMPORALITY } from './types';
import { dataTypeToExpr, employmentTemporalitySql, fieldToSql, timeSliderStateToExpr } from './utils';

export interface DataService {
  queryDataFieldValues: (
    dimensions: DataFieldWithDataType[],
    timeSliderState?: TimeSliderState,
    firstMonthOfYear?: Months,
    range?: boolean
  ) => Promise<CustomSqlQueryValueBackendType[][] | undefined>;
}

export class BackendDataService implements DataService {
  constructor(
    readonly domain: Domains,
    readonly graphQlRequestService: GraphQlRequestService,
    readonly executorRole: Role,
    readonly simulatedRole: Role | null
  ) {}

  public queryDataFieldValues = async (
    dimensions: DataFieldWithDataType[],
    timeSliderState?: TimeSliderState,
    firstMonthOfYear?: Months,
    range?: boolean
  ): Promise<CustomSqlQueryValueBackendType[][] | undefined> => {
    const dataType = dimensions[0].dataType;
    const selection = dimensions.map((dimension) => fieldToSql(dimension)).join(',');
    const timeSelection = timeSliderState ? toSql.expr(timeSliderStateToExpr(timeSliderState, range, dataType)) : null;
    const employmentTemporalitySelection =
      dataType === DataTypes.EMPLOYEE
        ? employmentTemporalitySql([EMPLOYMENT_TEMPORALITY.PRESENT, EMPLOYMENT_TEMPORALITY.PAST])
        : null;
    const termDateSelection =
      dataType === DataTypes.EMPLOYEE && timeSliderState && firstMonthOfYear
        ? `${fieldToSql(termDateNormalizedField)} >= '${startDateFromTimeRangeType(
            timeSliderState.start,
            timeSliderState.selectedGranularity,
            firstMonthOfYear
          )}'`
        : null;
    const where = [timeSelection, employmentTemporalitySelection, termDateSelection].filter((s) => s !== null);
    const statementAsString = `
      SELECT ${selection}
        FROM ${toSql.expr(dataTypeToExpr(dataType))}
     ${where.length > 0 ? `WHERE ${where.join(' AND ')}` : ''}
    GROUP BY ${selection}
    `;

    const statement: Statement = parseFirst(statementAsString);
    const query = toSql.statement(statement);

    const result = await this.graphQlRequestService.graphQlSdk.executeCustomSqlQuery({
      domain: this.domain,
      querySql: query,
      selectedExecutorRole: this.executorRole.id,
      simulateRole: this.simulatedRole?.id ?? null,
      disableNestLoop: true,
    });
    const data = result.executeCustomSqlQuery?.data;
    return data;
  };
}
