import { Domains } from '../constants';

export const evaluationFilterSortingOrders: Partial<Record<Domains, string[]>> = {
  [Domains.JT]: [
    '2020年上期 専門性',
    '2020年上期 業務',
    '2020年上期 行動',
    '2020年上期 総合評価',
    '2020年下期 専門性',
    '2020年下期 業務',
    '2020年下期 行動',
    '2020年下期 総合評価',
    '2020年年度（昇給） 専門性',
    '2020年年度（昇給） 業務',
    '2020年年度（昇給） 行動',
    '2020年年度（昇給） 総合評価',
    '2021年上期 専門性',
    '2021年上期 業務',
    '2021年上期 行動',
    '2021年上期 総合評価',
    '2021年下期 専門性',
    '2021年下期 業務',
    '2021年下期 行動',
    '2021年下期 総合評価',
    '2021年年度（昇給） 専門性',
    '2021年年度（昇給） 業務',
    '2021年年度（昇給） 行動',
    '2021年年度（昇給） 総合評価',
  ],
};
