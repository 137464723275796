import { action, computed, observable, runInAction } from 'mobx';
import { DataFieldWithDataType } from '../../../common-types';
import { ApiEmployeeSearchResponseItem, ApiMasterDataAdvancedQuery, HierarchicalFields } from '../api/api-interfaces';
import { employeeDataApiService } from '../api/employee-data-service/employee-data-service';
import { CompanyStore } from '../company/company-store';
import { DataTypes, Operations } from '../constants/constants';
import { COUNT_MEASURE, getVersionFilter, VERSION_ID_DIMENSION } from '../filter/common-filters';
import { GetUserVisibleNonConfidentialNonNullFieldsForDataTypeService } from '../services/GetNonNullFieldsForDataTypeService';
import { GetResponseForAdvancedQueryService } from '../services/GetResponseForQueryService';
import { getBaseField, getValueForMeasure } from '../utilFunctions/utils';
import { VersionId } from '../v2/api/types';
import { EmployeeDataFields } from './../constants/constants';

type StateType = 'PENDING' | 'ERROR' | 'DONE';

export class EmployeeDataStore {
  private companyStore: CompanyStore;

  public constructor(companyStore: CompanyStore) {
    this.companyStore = companyStore;
  }

  @observable
  public employeeHits: ApiEmployeeSearchResponseItem[] = [];

  @observable
  public oldestStartDate: string | null = null;

  @observable
  public oldestStartDateNotFound: boolean = false;

  @observable
  public state: StateType | null = null;

  @observable
  public nonNullFields: DataFieldWithDataType[] = [];

  @action
  public loadOldestStartDate = async (latestVersionId?: VersionId) => {
    const query: ApiMasterDataAdvancedQuery = {
      dataType: DataTypes.EMPLOYEE,
      dimensions: [VERSION_ID_DIMENSION],
      measures: [
        {
          dataType: DataTypes.EMPLOYEE,
          operation: Operations.MIN,
          property: 'START_DATE',
        },
        COUNT_MEASURE,
      ],
      filterItems: [
        {
          operation: Operations.EQUAL,
          property: 'EMPLOYMENT_TEMPORALITY',
          values: ['PRESENT', 'PAST'],
          dataType: DataTypes.EMPLOYEE,
        },
        ...(latestVersionId ? [getVersionFilter(latestVersionId)] : []),
      ],
    };
    // Need to use startdate normalized where it s not equal to the friendly minimum value
    // as startdate can be null
    // assuming it won t be null in this case

    try {
      const result = await GetResponseForAdvancedQueryService(query);
      const oldestStartDate = getValueForMeasure(
        result?.dataPoints?.[0]?.measures ?? [],
        {
          dataType: DataTypes.EMPLOYEE,
          dataField: EmployeeDataFields.START_DATE,
        },
        Operations.MIN
      );
      if (oldestStartDate) {
        runInAction(() => (this.oldestStartDate = oldestStartDate));
      } else {
        runInAction(() => (this.oldestStartDateNotFound = true));
        // tslint:disable-next-line: no-console
        console.error(
          'err in loadOldestStartDate - usually occurs when there is no employee data for the given domain and user'
        );
      }
    } catch (e) {
      runInAction(() => (this.oldestStartDateNotFound = true));
      // tslint:disable-next-line: no-console
      console.error(
        'err in loadOldestStartDate - usually occurs when there is no employee data for the given domain and user'
      );
    }
  };

  @action
  public loadNonNullFields = async () => {
    if (this.nonNullFields && this.nonNullFields.length > 0) {
      return;
    }
    const fields: DataFieldWithDataType[] = await GetUserVisibleNonConfidentialNonNullFieldsForDataTypeService(
      DataTypes.EMPLOYEE
    );
    runInAction(() => (this.nonNullFields = fields));
  };

  @computed
  public get nonNullHierarchicalFields(): DataFieldWithDataType[] {
    const hierarchicalFieldsSet = HierarchicalFields;
    return this.nonNullFields.filter((f) => {
      const nf = getBaseField(f);
      return hierarchicalFieldsSet.deepCompareContains(nf);
    });
  }

  @action
  public async searchForEmployees(text: string) {
    if (text) {
      this.state = 'PENDING';
      const result = await employeeDataApiService.searchForEmployees(this.companyStore.domain, text, false);

      runInAction(() => {
        this.state = 'DONE';
        this.employeeHits = result.employees;
      });
    } else {
      runInAction(() => {
        this.state = null;
        this.employeeHits = [];
      });
    }
  }

  public isNonNullFields = async (field: EmployeeDataFields) => {
    await this.loadNonNullFields();
    return this.nonNullFields.deepCompareContains({ dataType: DataTypes.EMPLOYEE, dataField: field });
  };
}
