import { Domains, Languages } from '../../common/constants/constants';
import { rootStore } from '../../common/store/root-store';

// This is a temporary function
export const getAttritionTranslationKey = (baseKey: string) => {
  const { domain } = rootStore.companyStore;
  const { selectedLanguage } = rootStore.languageStore;
  const splitBaseKey = baseKey.split('.');
  const newSplitKey =
    domain === Domains.NIKKI && selectedLanguage.id === Languages.EN
      ? [...splitBaseKey.front(), 'nikki', splitBaseKey.last() as string]
      : splitBaseKey;
  return newSplitKey.join('.');
};
