import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import React from 'react';
import styled from 'styled-components';

const StyledDialog = styled(Dialog as React.FunctionComponent<React.PropsWithChildren<DialogProps>>)<{
  width: string;
  $height?: string;
}>`
  & .paper {
    width: ${(props) => props.width};
    height: ${(props) => props.$height};
    max-width: none !important;
    min-width: 400px;
    @media only screen and (max-device-width: 480px) {
      margin: 0;
      width: 90vw;
      min-width: 0;
    } /* height: 100%; */
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  background: ${(props) => props.theme.color.primary};
  padding: 8px !important;
` as typeof DialogTitle;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px !important;
  justify-content: center;
  height: 100%;
` as typeof DialogContent;

interface ModalProps {
  open: boolean;
  width?: string;
  title?: any;
  children: React.ReactNode;
  onClose?: () => void;
  height?: string;
}
export const Modal = (props: ModalProps) => {
  return (
    <StyledDialog
      width={props.width ?? '70vw'}
      open={props.open}
      aria-labelledby="simple-dialog-title"
      classes={{ paper: 'paper' }}
      onClose={props.onClose}
      $height={props.height}
    >
      {props.title && (
        <StyledDialogTitle id="simple-dialog-title">
          <Typography
            variant="h6"
            sx={{
              color: 'secondary.main',
            }}
          >
            {props.title}
          </Typography>
        </StyledDialogTitle>
      )}
      <StyledDialogContent>{props.children}</StyledDialogContent>
    </StyledDialog>
  );
};
