import { Domains } from '../constants/constants';
import { environmentService } from '../environment/environment-service';
import { trackAPIError } from '../sentry/sentry';

export class RequestService {
  public staleRequestsMap = new Map<RequestInit, AbortController>();

  private async client(url: string, domain: Domains, token: string, options?: RequestInit): Promise<any> {
    const requestOptions = await this.decorateOptions(token, options);
    const requestData = {
      requestData: {
        domain,
        url,
        requestOptions,
      },
    };
    const controller = new AbortController();
    this.staleRequestsMap.set(requestOptions, controller);
    const response = await fetch(url, { ...requestOptions, signal: controller.signal });
    let responseJson = '';
    if (response.status !== 204 && response.status !== 202) {
      responseJson = await response.json();
    }
    if (!response.ok && response.status !== 404) {
      const error = new Error(`API ERROR at ${url}`);
      const contexts = {
        ...requestData,
        responseBody: responseJson,
      };
      trackAPIError(error, contexts, { statusCode: response.status });
      this.staleRequestsMap.delete(requestOptions);
    }
    this.staleRequestsMap.delete(requestOptions);
    return responseJson;
  }

  public async get(url: string, domain: Domains, token: string, options: RequestInit = {}): Promise<any> {
    return this.client(url, domain, token, options);
  }

  public async post<T>(url: string, domain: Domains, token: string, data: T, options: RequestInit = {}): Promise<any> {
    const requestOptions = {
      ...options,
      method: 'POST',
      body: JSON.stringify(data),
    };
    return this.client(url, domain, token, requestOptions);
  }

  public async postFile<T>(
    url: string,
    domain: Domains,
    token: string,
    data: any,
    options: RequestInit = {}
  ): Promise<any> {
    const requestOptions: RequestInit = {
      ...options,
      method: 'POST',
      body: data,
    };
    return this.client(url, domain, token, requestOptions);
  }

  public async put<T>(url: string, domain: Domains, token: string, data: T, options?: RequestInit): Promise<any> {
    const requestOptions = {
      ...options,
      method: 'PUT',
      body: JSON.stringify(data),
    };
    return this.client(url, domain, token, requestOptions);
  }

  public async delete<T>(url: string, domain: Domains, token: string, data?: T, options?: RequestInit): Promise<any> {
    const requestOptions = (data && {
      ...options,
      method: 'DELETE',
      body: JSON.stringify(data),
    }) || { ...options, method: 'DELETE' };
    return this.client(url, domain, token, requestOptions);
  }

  private addAuthHeader(initialHeaders: HeadersInit | undefined, token: string): any {
    const headers = {
      ...initialHeaders,
      Authorization: `Bearer ${token}`,
    };
    return headers;
  }

  private async decorateOptions(token: string, options?: RequestInit) {
    return {
      ...options,
      headers: {
        ...this.addAuthHeader(options ? options.headers : {}, token as string),
        'X-PANALYT-CSRF-PROTECTION': environmentService.getCSRFProtectionToken(),
      },
    };
  }
}
