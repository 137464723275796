import { useSuspenseQuery } from '@tanstack/react-query';
import { AliasService } from './service';

const queryKey = ['get-aliases'];
export const useAliases = (aliasService: AliasService) => {
  return useSuspenseQuery({
    queryKey,
    queryFn: () => {
      return aliasService.getAliases();
    },
  });
};
