import * as Sentry from '@sentry/react';

// IMPORTANT: this config will make it so that sensitive data / PII might be stored.
// Only use this config for usage with Panalyt staff
// If enabling it for all users/clients, the config must be changed (conditionally) to mask sensitive data.
export const sentryReplay = Sentry.replayIntegration({
  networkDetailAllowUrls: [new RegExp('.*')],
  networkCaptureBodies: true,
  networkRequestHeaders: [],
  networkResponseHeaders: ['X-Trace-Url'],
  unmask: ['*'],
  unblock: ['*'],
});
