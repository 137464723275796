import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../app';
import { errorHandler } from '../api/utils';
import { UserRolePreferences, UserRolePreferencesSettings } from '../api/zod-schemas';
import { rootStore } from '../store/root-store';
import { CommonMutationOptions } from './permissions-query-hooks/permissions-query-hooks';

export const useGetUserRolePreferences = () => {
  const { data, isPending, error } = useQuery({
    queryKey: ['rolePreferences'],

    queryFn: async () => {
      return (await rootStore.userRolePreferencesService.getLatestUserRolePreferences()) as UserRolePreferences;
    },
  });
  if (error) {
    void errorHandler(error, { request: 'getLatestUserPreferences' });
  }
  return { data, isPending, error };
};

export const useSaveUserRolePreferences = (options?: CommonMutationOptions) => {
  return useMutation({
    mutationFn: ({ preferences }: { preferences: UserRolePreferencesSettings }) => {
      return rootStore.userRolePreferencesService.saveUserRolePreferences(preferences);
    },

    onMutate: () => {
      options?.beforeCallback?.();
    },

    onSuccess: (_) => {
      void queryClient.refetchQueries({ queryKey: ['rolePreferences'], exact: true });
      options?.successCallback?.();
    },

    onError: (e) => errorHandler(e, { request: 'saveUserPreferences' }),
  });
};
