import { observer } from 'mobx-react';
import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { DashboardRouteProps } from '../../routes';
import { rootStore } from '../../store/root-store';
// import { GlobalContextProvider } from '../../v2/context/GlobalContextProvider';
import LoadingOverlay from '../utils/LoadingOverlay';

export const PrivateRoute = observer((props: DashboardRouteProps) => {
  const { component: PathComponent, ...rest } = props;
  const { isLoggedIn, loading, emailNeedsVerification } = rootStore.authStore;
  return (
    <Route
      {...rest}
      // tslint:disable-next-line:jsx-no-lambda
      render={(p) => {
        if (loading) {
          return <LoadingOverlay />;
        }
        return isLoggedIn && !emailNeedsVerification && PathComponent ? (
          <Suspense fallback={<LoadingOverlay />}>
            {/*<GlobalContextProvider>*/}
            <PathComponent {...p} />
            {/*</GlobalContextProvider>*/}
          </Suspense>
        ) : isLoggedIn && emailNeedsVerification ? (
          <Redirect
            to={{
              pathname: '/verify-email',
              state: { from: (p as any).location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: (p as any).location },
            }}
          />
        );
      }}
    />
  );
});
