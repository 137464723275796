import * as Sentry from '@sentry/react';
import { environmentService } from '../environment/environment-service';
import { ErrorTypes } from './types';

export const trackError = (
  errorMsg: string,
  contexts: Record<string, any> = {},
  tags: Record<string, any> = {},
  errorType?: ErrorTypes
) => {
  if (Sentry) {
    const domain = localStorage.getItem('DOMAIN');
    const subDomain = environmentService.getSubDomain();

    Sentry.withScope((scope) => {
      scope.setTag('panalyt.domain', domain ?? 'UNKNOWN');
      scope.setTag('panalyt.subDomain', subDomain);
      scope.setTag('panalyt.errorType', errorType ?? 'general');
      scope.setTags(tags);

      Sentry.captureException(new Error(errorMsg), {
        contexts,
      });
    });
  }
};
