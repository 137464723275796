import { baseAuthedTracker } from './baseTracker';

const ROUTE_CHANGE_EVENT = '[Pages Visited] Page Changed';

/**
 * Track whenever user change location
 */
export const trackRouteChange = (location: any) => {
  const { pathname } = location;
  const isAdmin = pathname.indexOf('/admin/') > -1 || pathname.indexOf('/panalyt-superadmin/') > -1;
  const isDashboard = pathname.indexOf('/dashboard/') > -1;
  const type = isAdmin ? 'admin' : isDashboard ? 'dashboard' : 'normal';

  baseAuthedTracker(ROUTE_CHANGE_EVENT, {
    type,
  });
};
