import { ApiMasterDataMovementQuery, ApiMasterDataQueryResponse } from '../api/api-interfaces';
import { BenchmarkConfig } from '../api/employee-data-service/employee-data-service';
import { appCache } from '../cache/cache';
import { rootStore } from '../store/root-store';

export const GetResponseForMovementQueryService = async (
  query: ApiMasterDataMovementQuery,
  benchmarkConfig?: BenchmarkConfig
) => {
  const cacheKey = JSON.stringify({ query, benchmarkConfig });
  const result: ApiMasterDataQueryResponse = await appCache.getFromCacheOrRequest(cacheKey, () => {
    return rootStore.masterDataApiService.executeMovementQuery(rootStore.companyStore.domain, query);
  });
  return result;
};
