import { useEffect, useState } from 'react';

export const useTabs = (numTabs: number, defaultTabIndex?: number): [number, (index: number) => void] => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(defaultTabIndex ?? 0);
  const handleTabChange = (index: number) => setActiveTabIndex(index);
  const handleArrowKeys = (event: any) => {
    let newIndex: number | null = null;
    if (event.key === 'ArrowUp') {
      newIndex = activeTabIndex !== 0 ? activeTabIndex - 1 : numTabs - 1;
    } else if (event.key === 'ArrowDown') {
      newIndex = activeTabIndex !== numTabs - 1 ? activeTabIndex + 1 : 0;
    }
    newIndex !== null && setActiveTabIndex(newIndex);
  };
  useEffect(() => {
    document.addEventListener('keyup', handleArrowKeys);
    return () => document.removeEventListener('keyup', handleArrowKeys);
  }, [activeTabIndex]);
  return [activeTabIndex, handleTabChange];
};
