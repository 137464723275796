import { MetricFormatTypeId, MetricGroupId, MetricId } from '../graphql/generated/graphql-sdk';
import { FormatTypes } from '../utilFunctions/formatters';

// PbsItems.FEMALE_MANAGERS_DEFINED, MetricGroupId.Pmg0016FMngDef MetricId.Pm0016_3FMngDefPercMng
// PbsItems.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE, MetricGroupId.Pmg0281EmpExExperienceMsad, MetricId.Pm0281_2EmpExExperienceMsadPercHc
// PbsItems.DIGITAL_EXPERTS, MetricGroupId.Pmg0280EmpCompDigitalTrainingMsad MetricId.Pm0280_2EmpCompDigitalTrainingMsadPercHc
// PbsItems.MSAD_SURVEY_ENGAGEMENT_SCORE, MetricGroupId.Pmg0279DigitalExpertsMsad MetricId.Pm0279_2DigitalExpertsMsadPercHc
// PbsItems.HIGHLY_STRESSED_EMPLOYEE, MetricGroupId.Pmg0283EngScoreMsad MetricId.Pm0284_2HighStrsEmpMsadPercHc
// PbsItems.EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING MetricGroupId.Pmg0284HighStrsEmpMsad MetricId.Pm0283_1EngScoreMsadPercHc

export enum FrontendMetricGroupId {
  REVENUE_HC_MONTHLY = 'REVENUE_HC_MONTHLY',
  GROSS_PROFIT_HC_MONTHLY = 'GROSS_PROFIT_HC_MONTHLY',
  OPERATING_PROFIT_AND_LOSS_HC_MONTHLY = 'OPERATING_PROFIT_AND_LOSS_HC_MONTHLY',
  NET_INCOME_HC_MONTHLY = 'NET_INCOME_HC_MONTHLY',
  SELLING_GENERAL_AND_ADMINISTRATION_HC_MONTHLY = 'SELLING_GENERAL_AND_ADMINISTRATION_HC_MONTHLY',
  TRAINING_COST_MONTHLY = 'TRAINING_COST_MONTHLY',
  INDIVIDUALS = 'INDIVIDUALS',
  JOINERS = 'JOINERS',
  LEAVERS_TERM_DATE = 'LEAVERS_TERM_DATE',
  SPAN_OF_CONTROL = 'SPAN_OF_CONTROL',
  ATTRITION = 'ATTRITION',
  THREE_MONTH_TENURE_ATTRITION = 'THREE_MONTH_TENURE_ATTRITION',
  ONE_YEAR_TENURE_ATTRITION = 'ONE_YEAR_TENURE_ATTRITION',
  FEMALE_HC = 'FEMALE_HC',
  MANAGERS_CALCULATED = 'MANAGERS_CALCULATED',
  MANAGERS_DEFINED = 'MANAGERS_DEFINED',
  FEMALE_MANAGERS_CALCULATED = 'FEMALE_MANAGERS_CALCULATED',
  FEMALE_MANAGERS_DEFINED = 'FEMALE_MANAGERS_DEFINED',
  TENURE = 'TENURE',
  DEPTH_OF_CONTROL = 'DEPTH_OF_CONTROL',
  AGE = 'AGE',
  MEDIAN_BASE = 'MEDIAN_BASE',
  GENDER_PAY_GAP_MEDIAN_BASE = 'GENDER_PAY_GAP_MEDIAN_BASE',
  POP_PAY_GAP = 'POP_PAY_GAP',
  CEO_PAY_GAP_BASE_AVG = 'CEO_PAY_GAP_BASE_AVG',
  TOTAL_CASH_COMPENSATION = 'TOTAL_CASH_COMPENSATION',
  DAYS_IN_OFFICE = 'DAYS_IN_OFFICE',
  MONTHLY_OT = 'MONTHLY_OT',
  AVG_EMP_45_HRS_OT_PER_MONTH = 'AVG_EMP_45_HRS_OT_PER_MONTH',
  AVG_WORK_HRS_PER_PERSON = 'AVG_WORK_HRS_PER_PERSON',
  NEW_MANAGERS_CALCULATED = 'NEW_MANAGERS_CALCULATED',
  RETENTION = 'RETENTION',
  INTERNAL_COMPA_RATIO = 'INTERNAL_COMPA_RATIO',
  REGULAR_EMPLOYEE = 'REGULAR_EMPLOYEE',
  NON_REGULAR_EMPLOYEE = 'NON_REGULAR_EMPLOYEE',
  TOTAL_WORKFORCE_COST = 'TOTAL_WORKFORCE_COST',
  OVERTIME_PAY = 'OVERTIME_PAY',
  TRAVEL_AND_COMMUTING = 'TRAVEL_AND_COMMUTING',
  EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_MSAD = 'EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_MSAD',
  DIGITAL_EXPERTS_MSAD = 'DIGITAL_EXPERTS_MSAD',
  MSAD_SURVEY_ENGAGEMENT_SCORE_MSAD = 'MSAD_SURVEY_ENGAGEMENT_SCORE_MSAD',
  HIGHLY_STRESSED_EMPLOYEE_MSAD = 'HIGHLY_STRESSED_EMPLOYEE_MSAD',
  EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING_MSAD = 'EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING_MSAD',
  // Recruitment
  APPLICATIONS_AT_APPLIED_STAGE = 'APPLICATIONS_AT_APPLIED_STAGE',
  APPLICATIONS_AT_APPLIED_STAGE_PASSED = 'APPLICATIONS_AT_APPLIED_STAGE_PASSED',
  APPLICATIONS_AT_APPLIED_STAGE_REJECTED = 'APPLICATIONS_AT_APPLIED_STAGE_REJECTED',
  APPLICATIONS_AT_APPLIED_STAGE_WITHDRAWN = 'APPLICATIONS_AT_APPLIED_STAGE_WITHDRAWN',
  APPLICATIONS_AT_SCREENING_STAGE = 'APPLICATIONS_AT_SCREENING_STAGE',
  APPLICATIONS_AT_SCREENING_STAGE_PASSED = 'APPLICATIONS_AT_SCREENING_STAGE_PASSED',
  APPLICATIONS_AT_SCREENING_STAGE_REJECTED = 'APPLICATIONS_AT_SCREENING_STAGE_REJECTED',
  APPLICATIONS_AT_SCREENING_STAGE_WITHDRAWN = 'APPLICATIONS_AT_SCREENING_STAGE_WITHDRAWN',
  APPLICATIONS_AT_1ST_INTERVIEW_STAGE = 'APPLICATIONS_AT_1ST_INTERVIEW_STAGE',
  APPLICATIONS_AT_1ST_INTERVIEW_STAGE_PASSED = 'APPLICATIONS_AT_1ST_INTERVIEW_STAGE_PASSED',
  APPLICATIONS_AT_1ST_INTERVIEW_STAGE_REJECTED = 'APPLICATIONS_AT_1ST_INTERVIEW_STAGE_REJECTED',
  APPLICATIONS_AT_1ST_INTERVIEW_STAGE_WITHDRAWN = 'APPLICATIONS_AT_1ST_INTERVIEW_STAGE_WITHDRAWN',
  APPLICATIONS_AT_2ND_INTERVIEW_STAGE = 'APPLICATIONS_AT_2ND_INTERVIEW_STAGE',
  APPLICATIONS_AT_2ND_INTERVIEW_STAGE_PASSED = 'APPLICATIONS_AT_2ND_INTERVIEW_STAGE_PASSED',
  APPLICATIONS_AT_2ND_INTERVIEW_STAGE_REJECTED = 'APPLICATIONS_AT_2ND_INTERVIEW_STAGE_REJECTED',
  APPLICATIONS_AT_2ND_INTERVIEW_STAGE_WITHDRAWN = 'APPLICATIONS_AT_2ND_INTERVIEW_STAGE_WITHDRAWN',
  APPLICATIONS_AT_3RD_INTERVIEW_STAGE = 'APPLICATIONS_AT_3RD_INTERVIEW_STAGE',
  APPLICATIONS_AT_3RD_INTERVIEW_STAGE_PASSED = 'APPLICATIONS_AT_3RD_INTERVIEW_STAGE_PASSED',
  APPLICATIONS_AT_3RD_INTERVIEW_STAGE_REJECTED = 'APPLICATIONS_AT_3RD_INTERVIEW_STAGE_REJECTED',
  APPLICATIONS_AT_3RD_INTERVIEW_STAGE_WITHDRAWN = 'APPLICATIONS_AT_3RD_INTERVIEW_STAGE_WITHDRAWN',
  APPLICATIONS_AT_OFFERED_STAGE = 'APPLICATIONS_AT_OFFERED_STAGE',
  APPLICATIONS_AT_OFFERED_STAGE_PASSED = 'APPLICATIONS_AT_OFFERED_STAGE_PASSED',
  APPLICATIONS_AT_OFFERED_STAGE_REJECTED = 'APPLICATIONS_AT_OFFERED_STAGE_REJECTED',
  APPLICATIONS_AT_OFFERED_STAGE_WITHDRAWN = 'APPLICATIONS_AT_OFFERED_STAGE_WITHDRAWN',
  APPLICATIONS_AT_JOINED_STAGE = 'APPLICATIONS_AT_JOINED_STAGE',
}

export enum FrontendMetricId {
  FEMALE_MANAGERS_DEFINED_PERC_OF_MANAGERS = 'FEMALE_MANAGERS_DEFINED_PERC_OF_MANAGERS',
  EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_MSAD_PERC_OF_HC = 'EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_MSAD_PERC_OF_HC',
  DIGITAL_EXPERTS_MSAD_PERC_OF_HC = 'DIGITAL_EXPERTS_MSAD_PERC_OF_HC',
  MSAD_SURVEY_ENGAGEMENT_SCORE_MSAD_PERC_OF_HC = 'MSAD_SURVEY_ENGAGEMENT_SCORE_MSAD_PERC_OF_HC',
  HIGHLY_STRESSED_EMPLOYEE_MSAD_PERC_OF_HC = 'HIGHLY_STRESSED_EMPLOYEE_MSAD_PERC_OF_HC',
  EMPLOYEE_COMPLETED_DIGITAL_TRAINING_MSAD_PERC_OF_HC = 'EMPLOYEE_COMPLETED_DIGITAL_TRAINING_MSAD_PERC_OF_HC',
  INDIVIDUALS_COUNT = 'INDIVIDUALS_COUNT',
  JOINERS_JOINERS = 'JOINERS_JOINERS',
  JOINERS_PERC_HC = 'JOINERS_PERC_HC',
  LEAVERS_TERM_DATE_LEAVERS = 'LEAVERS_TERM_DATE_LEAVERS',
  CUSTOM_INDIVIDUALS_1_BRIDGESTONE_COUNT = 'CUSTOM_INDIVIDUALS_1_BRIDGESTONE_COUNT',
  CUSTOM_JOINERS_1_BRIDGESTONE_JOINERS = 'CUSTOM_JOINERS_1_BRIDGESTONE_JOINERS',
  CUSTOM_JOINERS_2_BRIDGESTONE_JOINERS = 'CUSTOM_JOINERS_2_BRIDGESTONE_JOINERS',
  CUSTOM_ATTRITION_1_BRIDGESTONE_CUSTOM_ATTRITION = 'CUSTOM_ATTRITION_1_BRIDGESTONE_CUSTOM_ATTRITION',
  CUSTOM_LEAVERS_2_BRIDGESTONE_LEAVERS = 'CUSTOM_LEAVERS_2_BRIDGESTONE_LEAVERS',
  CUSTOM_ATTRITION_NIKKI_TTM = 'CUSTOM_ATTRITION_NIKKI_TTM',
}

export const frontendMetricGroupIdToBackendMetricGroupIdMap: Record<FrontendMetricGroupId, MetricGroupId> = {
  [FrontendMetricGroupId.REVENUE_HC_MONTHLY]: MetricGroupId.Pmg0037RevenueHcMonthly,
  [FrontendMetricGroupId.GROSS_PROFIT_HC_MONTHLY]: MetricGroupId.Pmg0044GrossProfitHcMonthly,
  [FrontendMetricGroupId.OPERATING_PROFIT_AND_LOSS_HC_MONTHLY]: MetricGroupId.Pmg0050OperatingProfitAndLossHcMonthly,
  [FrontendMetricGroupId.NET_INCOME_HC_MONTHLY]: MetricGroupId.Pmg0199NetIncomeHcMonthly,
  [FrontendMetricGroupId.SELLING_GENERAL_AND_ADMINISTRATION_HC_MONTHLY]: MetricGroupId.Pmg0047SgAndAhcMonthly,
  [FrontendMetricGroupId.TRAINING_COST_MONTHLY]: MetricGroupId.Pmg0051TrainingCostMonthly,
  [FrontendMetricGroupId.INDIVIDUALS]: MetricGroupId.Pmg0001Individuals,
  [FrontendMetricGroupId.JOINERS]: MetricGroupId.Pmg0006Joiners,
  [FrontendMetricGroupId.LEAVERS_TERM_DATE]: MetricGroupId.Pmg0007LeaversTermDate,
  [FrontendMetricGroupId.SPAN_OF_CONTROL]: MetricGroupId.Pmg0024SpanControl,
  [FrontendMetricGroupId.ATTRITION]: MetricGroupId.Pmg0076Attrition,
  [FrontendMetricGroupId.THREE_MONTH_TENURE_ATTRITION]: MetricGroupId.Pmg0078_3MonthAttrition,
  [FrontendMetricGroupId.ONE_YEAR_TENURE_ATTRITION]: MetricGroupId.Pmg0079_1YearAttrition,
  [FrontendMetricGroupId.FEMALE_HC]: MetricGroupId.Pmg0020Fhc,
  [FrontendMetricGroupId.FEMALE_MANAGERS_CALCULATED]: MetricGroupId.Pmg0012FMngCalc,
  [FrontendMetricGroupId.FEMALE_MANAGERS_DEFINED]: MetricGroupId.Pmg0016FMngDef,
  [FrontendMetricGroupId.MANAGERS_CALCULATED]: MetricGroupId.Pmg0010MngCalc,
  [FrontendMetricGroupId.MANAGERS_DEFINED]: MetricGroupId.Pmg0014MngDef,
  [FrontendMetricGroupId.TENURE]: MetricGroupId.Pmg0023Tenure,
  [FrontendMetricGroupId.DEPTH_OF_CONTROL]: MetricGroupId.Pmg0025DepthControl,
  [FrontendMetricGroupId.AGE]: MetricGroupId.Pmg0019Age,
  [FrontendMetricGroupId.MEDIAN_BASE]: MetricGroupId.Pmg0210MedianBase,
  [FrontendMetricGroupId.GENDER_PAY_GAP_MEDIAN_BASE]: MetricGroupId.Pmg0054GenderPayGapMedBase,
  [FrontendMetricGroupId.POP_PAY_GAP]: MetricGroupId.Pmg0026PopPayGap,
  [FrontendMetricGroupId.CEO_PAY_GAP_BASE_AVG]: MetricGroupId.Pmg0028CeoPayGapBaseAvg,
  [FrontendMetricGroupId.TOTAL_CASH_COMPENSATION]: MetricGroupId.Pmg0176TotalCashCompensation,
  [FrontendMetricGroupId.DAYS_IN_OFFICE]: MetricGroupId.Pmg0170DaysinOffice,
  [FrontendMetricGroupId.MONTHLY_OT]: MetricGroupId.Pmg0215MonthlyOt,
  [FrontendMetricGroupId.AVG_EMP_45_HRS_OT_PER_MONTH]: MetricGroupId.Pmg0214AvgEmp45HrsOTperMth,
  [FrontendMetricGroupId.AVG_WORK_HRS_PER_PERSON]: MetricGroupId.Pmg0213AvgWorkHoursPerPerson,
  [FrontendMetricGroupId.NEW_MANAGERS_CALCULATED]: MetricGroupId.Pmg0011NewMngCalc,
  [FrontendMetricGroupId.RETENTION]: MetricGroupId.Pmg0077Retention,
  [FrontendMetricGroupId.INTERNAL_COMPA_RATIO]: MetricGroupId.Pmg0083Icr,
  [FrontendMetricGroupId.REGULAR_EMPLOYEE]: MetricGroupId.Pmg0236RegularEmployee,
  [FrontendMetricGroupId.NON_REGULAR_EMPLOYEE]: MetricGroupId.Pmg0207NonRegEmp,
  [FrontendMetricGroupId.TOTAL_WORKFORCE_COST]: MetricGroupId.Pmg0175TotalWorkforceCost,
  [FrontendMetricGroupId.OVERTIME_PAY]: MetricGroupId.Pmg0226OtPay,
  [FrontendMetricGroupId.TRAVEL_AND_COMMUTING]: MetricGroupId.Pmg0227TravelCommuting,
  [FrontendMetricGroupId.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_MSAD]: MetricGroupId.Pmg0281EmpExExperienceMsad,
  [FrontendMetricGroupId.DIGITAL_EXPERTS_MSAD]: MetricGroupId.Pmg0279DigitalExpertsMsad,
  [FrontendMetricGroupId.MSAD_SURVEY_ENGAGEMENT_SCORE_MSAD]: MetricGroupId.Pmg0283EngScoreMsad,
  [FrontendMetricGroupId.HIGHLY_STRESSED_EMPLOYEE_MSAD]: MetricGroupId.Pmg0284HighStrsEmpMsad,
  [FrontendMetricGroupId.EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING_MSAD]:
    MetricGroupId.Pmg0280EmpCompDigitalTrainingMsad,
  // Recruitment
  [FrontendMetricGroupId.APPLICATIONS_AT_APPLIED_STAGE]: MetricGroupId.Pmg0252Applications,
  [FrontendMetricGroupId.APPLICATIONS_AT_APPLIED_STAGE_PASSED]: MetricGroupId.Pmg0253PassThroughatApplied,
  [FrontendMetricGroupId.APPLICATIONS_AT_APPLIED_STAGE_REJECTED]: MetricGroupId.Pmg0254RejectedApplicationsatApplied,
  [FrontendMetricGroupId.APPLICATIONS_AT_APPLIED_STAGE_WITHDRAWN]: MetricGroupId.Pmg0255WithdrawnApplicationsatApplied,
  [FrontendMetricGroupId.APPLICATIONS_AT_SCREENING_STAGE]: MetricGroupId.Pmg0256ApplicationsatScreening,
  [FrontendMetricGroupId.APPLICATIONS_AT_SCREENING_STAGE_PASSED]: MetricGroupId.Pmg0257PassThroughatScreening,
  [FrontendMetricGroupId.APPLICATIONS_AT_SCREENING_STAGE_REJECTED]:
    MetricGroupId.Pmg0258RejectedApplicationsatScreening,
  [FrontendMetricGroupId.APPLICATIONS_AT_SCREENING_STAGE_WITHDRAWN]:
    MetricGroupId.Pmg0259WithdrawnApplicationsatScreening,
  [FrontendMetricGroupId.APPLICATIONS_AT_1ST_INTERVIEW_STAGE]: MetricGroupId.Pmg0260Applicationsat1stInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_PASSED]: MetricGroupId.Pmg0261PassThroughat1stInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_REJECTED]:
    MetricGroupId.Pmg0262RejectedApplicationsat1stInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_1ST_INTERVIEW_STAGE_WITHDRAWN]:
    MetricGroupId.Pmg0263WithdrawnApplicationsat1stInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_2ND_INTERVIEW_STAGE]: MetricGroupId.Pmg0264Applicationsat2ndInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_PASSED]: MetricGroupId.Pmg0265PassThroughat2ndInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_REJECTED]:
    MetricGroupId.Pmg0266RejectedApplicationsat2ndInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_2ND_INTERVIEW_STAGE_WITHDRAWN]:
    MetricGroupId.Pmg0267WithdrawnApplicationsat2ndInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_3RD_INTERVIEW_STAGE]: MetricGroupId.Pmg0268ApplicationsatFinalInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_PASSED]: MetricGroupId.Pmg0269PassThroughatFinalInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_REJECTED]:
    MetricGroupId.Pmg0270RejectedApplicationsatFinalInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_3RD_INTERVIEW_STAGE_WITHDRAWN]:
    MetricGroupId.Pmg0271WithdrawnApplicationsatFinalInterview,
  [FrontendMetricGroupId.APPLICATIONS_AT_OFFERED_STAGE]: MetricGroupId.Pmg0272ApplicationsatOffer,
  [FrontendMetricGroupId.APPLICATIONS_AT_OFFERED_STAGE_PASSED]: MetricGroupId.Pmg0273PassThroughatOffer,
  [FrontendMetricGroupId.APPLICATIONS_AT_OFFERED_STAGE_REJECTED]: MetricGroupId.Pmg0274RejectedApplicationsatOffer,
  [FrontendMetricGroupId.APPLICATIONS_AT_OFFERED_STAGE_WITHDRAWN]: MetricGroupId.Pmg0275WithdrawnApplicationsatOffer,
  [FrontendMetricGroupId.APPLICATIONS_AT_JOINED_STAGE]: MetricGroupId.Pmg0276Applications,
};

export const frontendMetricIdToBackendMetricIdMap: Record<FrontendMetricId, MetricId> = {
  [FrontendMetricId.FEMALE_MANAGERS_DEFINED_PERC_OF_MANAGERS]: MetricId.Pm0016_3FMngDefPercMng,
  [FrontendMetricId.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_MSAD_PERC_OF_HC]: MetricId.Pm0281_2EmpExExperienceMsadPercHc,
  [FrontendMetricId.DIGITAL_EXPERTS_MSAD_PERC_OF_HC]: MetricId.Pm0279_2DigitalExpertsMsadPercHc,
  [FrontendMetricId.MSAD_SURVEY_ENGAGEMENT_SCORE_MSAD_PERC_OF_HC]: MetricId.Pm0283_1EngScoreMsadPercHc,
  [FrontendMetricId.HIGHLY_STRESSED_EMPLOYEE_MSAD_PERC_OF_HC]: MetricId.Pm0284_2HighStrsEmpMsadPercHc,
  [FrontendMetricId.EMPLOYEE_COMPLETED_DIGITAL_TRAINING_MSAD_PERC_OF_HC]:
    MetricId.Pm0280_2EmpCompDigitalTrainingMsadPercHc,
  [FrontendMetricId.INDIVIDUALS_COUNT]: MetricId.Pm0001_1IndividualsCount,
  [FrontendMetricId.JOINERS_JOINERS]: MetricId.Pm0006_1JoinersJoiners,
  [FrontendMetricId.JOINERS_PERC_HC]: MetricId.Pm0006_2JoinersPercHc,
  [FrontendMetricId.LEAVERS_TERM_DATE_LEAVERS]: MetricId.Pm0007_1LeaversTermDateLeavers,
  [FrontendMetricId.CUSTOM_INDIVIDUALS_1_BRIDGESTONE_COUNT]: MetricId.Pm0390_1CstmIndividuals1BsCount,
  [FrontendMetricId.CUSTOM_JOINERS_1_BRIDGESTONE_JOINERS]: MetricId.Pm0383_1CstmJoiners1BsJoiners,
  [FrontendMetricId.CUSTOM_JOINERS_2_BRIDGESTONE_JOINERS]: MetricId.Pm0384_1CstmJoiners2BsJoiners,
  [FrontendMetricId.CUSTOM_ATTRITION_1_BRIDGESTONE_CUSTOM_ATTRITION]: MetricId.Pm0389_1CstmAttrition1BsCstmAttrition,
  [FrontendMetricId.CUSTOM_LEAVERS_2_BRIDGESTONE_LEAVERS]: MetricId.Pm0387_1CstmLeavers2BsLeavers,
  [FrontendMetricId.CUSTOM_ATTRITION_NIKKI_TTM]: MetricId.Pm0318_3CustomAttritionNikkiTtm,
};

export const getFrontendFormatTypeFromBackendFormatType = (metricFormatTypeId: MetricFormatTypeId) => {
  switch (metricFormatTypeId) {
    case MetricFormatTypeId.Pmft002Percentage:
      return FormatTypes.PERCENTAGE;
    case MetricFormatTypeId.Pmft005Count:
      return FormatTypes.COUNT;
    case MetricFormatTypeId.Pmft006Number:
      return FormatTypes.NUMBER;
    case MetricFormatTypeId.Pmft007SensitiveNumber:
      return FormatTypes.SENSITIVE_NUMBER;
    case MetricFormatTypeId.Pmft008Multiple:
      return FormatTypes.COUNT;
    case MetricFormatTypeId.Pmft009Leaves:
      return FormatTypes.LEAVES_TAKEN;
    case MetricFormatTypeId.Pmft010Hours:
      return FormatTypes.HOURS;
    case MetricFormatTypeId.Pmft011Fte:
      return FormatTypes.FTE;
    case MetricFormatTypeId.Pmft012FloatingNumber1:
      return FormatTypes.FLOATING_NUMBER_1;
    case MetricFormatTypeId.Pmft013FloatingNumber2:
      return FormatTypes.FLOATING_NUMBER_2;
    case MetricFormatTypeId.Pmft014FloatingPercentage1:
      return FormatTypes.FLOATING_PERCENTAGE_1;
    case MetricFormatTypeId.Pmft015FloatingPercentage2:
      return FormatTypes.FLOATING_PERCENTAGE_2;
    default:
      throw new Error(`Mapping to frontend format type not found for ${metricFormatTypeId}`);
    // We might not need mappings for all frontend format types so this might be sufficient
  }
};

export const getMetricIdFromFrontendMetricId = (frontendMetricId: FrontendMetricId) => {
  return frontendMetricIdToBackendMetricIdMap[frontendMetricId];
};

export const getMetricGroupIdFromFrontendMetricGroupId = (frontendMetricGroupId: FrontendMetricGroupId) => {
  return frontendMetricGroupIdToBackendMetricGroupIdMap[frontendMetricGroupId];
};
