import { Languages } from '../../constants';
import { LanguageObject } from './types';

export const availableLanguagesToIso6291Map: Record<string, string> = {
  en: 'en',
  ja: 'ja',
  jp: 'ja',
  es: 'es',
};

export const languages: LanguageObject[] = [
  { id: Languages.EN, shortName: 'EN', longName: 'English', translationKey: 'common:commonValues.english' },
  { id: Languages.JA, shortName: 'JA', longName: 'Japanese', translationKey: 'common:commonValues.japanese' },
  {
    id: Languages.ES,
    shortName: 'ES',
    longName: 'Spanish (Latin America)',
    translationKey: 'common:commonValues.spanish',
  },
];
