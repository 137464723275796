import { Avatar, IconButton, ListItemAvatar, ListItemText, MenuItem, MenuList, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Languages } from '../../constants/constants';
import { rootStore } from '../../store/root-store';
import { styled } from '../styled';
import Loading from '../utils/Loading';
import PopUpMenu from '../utils/PopUpMenu';

const StyledIconButton = styled(IconButton)`
  padding: 5px !important;
` as typeof IconButton;

const StyledAvatar = styled(Avatar)`
  height: 20px;
  width: 20px;
  background: transparent;
  border-radius: 0;
  font-size: 12px;
` as typeof Avatar;

const LanguageSwitcher = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);

  const onLanguageSelect = (id: Languages) => () => {
    rootStore.languageStore.setLanguageInLocalStorage(id);
    rootStore.intercomStore.updateSettings({ language_override: id });
    location.reload();
    setOpen(false);
  };

  const { languages, selectedLanguage } = rootStore.languageStore;
  return (
    <>
      <StyledIconButton
        aria-owns={anchorEl ? 'material-appbar' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setOpen(true);
        }}
        color="inherit"
        className={'intercom_appbar_locale'}
      >
        <StyledAvatar>
          <Typography variant="body2">{selectedLanguage.shortName} </Typography>
        </StyledAvatar>
      </StyledIconButton>
      <PopUpMenu anchorEl={anchorEl} open={open} onClose={() => setOpen(false)} placement="bottom-end">
        {languages.length > 1 ? (
          <MenuList>
            {languages.map(({ id, shortName, longName }) => (
              <MenuItem onClick={onLanguageSelect(id)} key={id} selected={selectedLanguage.id === id}>
                <ListItemAvatar>
                  <StyledAvatar>{shortName}</StyledAvatar>
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'body2',
                    noWrap: true,
                  }}
                >
                  {longName}
                </ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        ) : (
          <Loading />
        )}
      </PopUpMenu>
    </>
  );
});

export default LanguageSwitcher;
