import i18n from '../../../i18n';
import { Languages } from '../constants/constants';
import { availableLanguagesToIso6291Map } from '../utilFunctions/pure-utils';

interface LanguageObject {
  id: Languages;
  shortName: string;
  longName: string;
  translationKey: string;
}

export class LanguageStore {
  public languages: LanguageObject[] = [
    { id: Languages.EN, shortName: 'EN', longName: 'English', translationKey: 'common:commonValues.english' },
    { id: Languages.JA, shortName: 'JA', longName: 'Japanese', translationKey: 'common:commonValues.japanese' },
    {
      id: Languages.ES,
      shortName: 'ES',
      longName: 'Spanish (Latin America)',
      translationKey: 'common:commonValues.spanish',
    },
  ];

  public selectedLanguage: LanguageObject;

  public constructor() {
    const currentLang = this.getLanguageFromStorage();
    this.selectedLanguage = this.findSelectedLanguage(currentLang ?? this.languages[0].id);
  }

  public getLanguageFromStorage() {
    const currentLang = localStorage.getItem('i18nextLng');
    return currentLang ? availableLanguagesToIso6291Map[currentLang] : null;
  }

  public findSelectedLanguage(id: string) {
    return this.languages.find((lng) => lng.id === id) || this.languages[0];
  }

  public setLanguageInLocalStorage = (id: string) => {
    const selectedLanguage = this.findSelectedLanguage(id);
    i18n.changeLanguage(selectedLanguage.id);
  };
}
