import { Granularity, Presets } from './types';

export const Last3MonthsPreset = { id: Presets.LAST3MONTHS, label: 'last3Months' } as const;
export const Last6MonthsPreset = { id: Presets.LAST6MONTHS, label: 'last6Months' } as const;
export const Last12MonthsPreset = { id: Presets.LAST12MONTHS, label: 'last12Months' } as const;
export const YTDPreset = { id: Presets.YTD, label: 'ytd' } as const;
export const LastYearPreset = { id: Presets.LASTYEAR, label: 'lastYear' } as const;
export const FYTDPreset = { id: Presets.FYTD, label: 'fytd' } as const;
export const LastFinancialYearPreset = { id: Presets.LASTFINANCIALYEAR, label: 'lastFinancialYear' } as const;
export const AllTimePreset = { id: Presets.ALLTIME, label: 'allTime' } as const;

export const CalendarYearMonthlyTimesliderGranularity = {
  id: 'byMonth',
  label: 'byMonth',
  value: Granularity.MONTH,
} as const;

export const CalendarYearYearlyTimesliderGranularity = {
  id: 'byYear',
  label: 'byYear',
  value: Granularity.YEAR,
} as const;

export const FinancialYearYearlyTimesliderGranularity = {
  id: 'byFinYear',
  label: 'byFinYear',
  value: Granularity.FINYEAR,
} as const;

export const FinancialYearQuarterlyTimesliderGranularity = {
  id: 'byFinQtr',
  label: 'byFinQtr',
  value: Granularity.FINQUARTER,
} as const;
