import { useSuspenseQuery } from '@tanstack/react-query';
import { Role, RoleId } from '../types';
import { RoleService } from './service';

const queryKey = ['get-executor-roles-for-role'];
export const useExecutorRolesForRole = (roleService: RoleService, roleId: RoleId, simulatedRole: Role | null) => {
  return useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      return roleService.getExecutorRolesForRole(roleId, simulatedRole);
    },
  });
};
