import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, InputBase, List, ListItemText, ThemeProvider } from '@mui/material';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { createRef, useRef, useState } from 'react';
import i18n from '../../../../i18n';
import { ApiEmployeeSearchResponseItem } from '../../api/api-interfaces';
import { rootStore } from '../../store/root-store';
import { defaultTheme } from '../../theme/default-theme';
import { lightInputTheme } from '../../theme/mui-theme';
import { formatUserSearchBasedOnDisplayNameAndLocale } from '../../utilFunctions/formatters';
import { styled } from '../styled';
import PopUpMenu from '../utils/PopUpMenu';

import ListItemButton from '@mui/material/ListItemButton';

const SearchWrapper = styled('div')`
  position: relative;
  height: 25px;
  border-radius: 4px;
  background-color: ${defaultTheme.color.light}26;
  &:hover {
    background-color: ${defaultTheme.color.light}40;
  }
  margin: 0 8px;
  width: auto;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchInput = styled(InputBase)`
  width: 100%;
  height: 25px !important;
  font-size: 0.8em;

  & .input {
    padding: 8px 8px 8px 32px;
    transition: all 0.2s;
    width: 0;
    height: 25px;

    &:focus {
      padding-left: 40px;
      width: 200px;
    }
  }
` as typeof InputBase;

const StyledSearchIcon = styled(SearchIcon)`
  && {
    width: 0.8em;
    height: 0.8em;
  }
` as typeof SearchIcon;

const StyledPersonIcon = styled(PersonIcon)`
  && {
    width: 0.8em;
    height: 0.8em;
  }
` as typeof PersonIcon;

export const Search = observer(() => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const searchWrapperRef = createRef<HTMLDivElement>();
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleClick = (employeeId: string) => {
    void rootStore.employeeProfileStore.handleClick(employeeId);
    setAnchorEl(null);
  };

  const handleInputClick = action(() => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setTimeout(() => {
        setAnchorEl(searchWrapperRef.current);
      }, 100);
    }
  });

  const getNameFromEmployeeHit = (emp: ApiEmployeeSearchResponseItem) => {
    const { selectedLanguage } = rootStore.languageStore;
    return formatUserSearchBasedOnDisplayNameAndLocale(emp, selectedLanguage.id);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timeout) {
      clearTimeout(timeout.current ?? undefined);
    }

    const { value } = event.target;

    timeout.current = setTimeout(() => {
      void rootStore.employeeDataStore.searchForEmployees(value);
    }, 500);
  };

  const { employeeHits, state } = rootStore.employeeDataStore;
  const isSearching = state === 'PENDING';
  return (
    <>
      <ThemeProvider theme={lightInputTheme}>
        <SearchWrapper ref={searchWrapperRef}>
          <IconWrapper>
            {isSearching ? (
              <CircularProgress size={16} />
            ) : (
              <>
                <StyledSearchIcon sx={{ color: 'primary.contrastText' }} />
                <StyledPersonIcon sx={{ color: 'primary.contrastText' }} />
              </>
            )}
          </IconWrapper>
          <SearchInput
            placeholder={i18n.t('common:shell.searchEmployee')}
            classes={{ input: 'input' }}
            onClick={handleInputClick}
            onChange={handleChange}
            className={'intercom_appbar_search'}
          />
        </SearchWrapper>
      </ThemeProvider>
      <PopUpMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl && employeeHits?.length)}
        onClose={() => setAnchorEl(null)}
        placement={'bottom-end'}
      >
        <List>
          {employeeHits &&
            employeeHits.map((emp) => {
              // If local name exists, showing local name first
              return (
                <ListItemButton key={emp.employeeId} onClick={() => handleClick(emp.employeeId)}>
                  <ListItemText {...getNameFromEmployeeHit(emp)} />
                </ListItemButton>
              );
            })}
        </List>
      </PopUpMenu>
    </>
  );
});

export default Search;
