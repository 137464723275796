import partition from 'lodash.partition';
import * as ast from 'pgsql-ast-parser/lib/syntax/ast';
import { combineFilters, isHierarchical } from '../v2/api/utils';
import { filterToDataFieldWithDataType } from '../v2/utils';
import {
  ApiMasterDataQueryFilterItem,
  DataTypes,
  JoinTypes,
  Operations,
  SingleValueTypes,
  ValueTypes,
} from '../v2/types';

export class QueryToSqlHelper {
  public static joinTypeMapper: (joinType: JoinTypes) => ast.JoinType = (joinType: JoinTypes) => {
    switch (joinType) {
      case JoinTypes.INNER:
        return 'INNER JOIN';
      case JoinTypes.LEFT:
        return 'LEFT JOIN';
      case JoinTypes.RIGHT:
        return 'RIGHT JOIN';
      default:
        throw new Error(`Unknown JoinType ${joinType}`);
    }
  };

  public static operationMapper: (operation: Operations) => ast.BinaryOperator = (operation: Operations) => {
    switch (operation) {
      case 'GREATER_THAN':
        return '>';
      case 'LESS_THAN':
        return '<';
      case 'GREATER_THAN_OR_EQUAL_TO':
        return '>=';
      case 'LESS_THAN_OR_EQUAL_TO':
        return '<=';
      case 'EQUAL':
        return '=';
      case 'NOT_EQUAL':
        return 'IS DISTINCT FROM';
      case 'LIKE':
        return 'LIKE';
      case 'IN':
        return 'IN';
      case 'NOT_IN':
        return 'NOT IN';
      default:
        throw Error('Operation not supported');
    }
  };

  public static join(
    dataType: DataTypes,
    joinCondition: ast.Expr | undefined,
    joinType: ast.JoinType = 'INNER JOIN'
  ): ast.FromTable {
    return {
      type: 'table',
      name: { name: dataType },
      join: joinCondition
        ? {
            type: joinType,
            on: joinCondition,
          }
        : null,
    };
  }

  public static fieldMapper: (dataType: DataTypes, field: string) => ast.ExprRef = (
    dataType: DataTypes,
    field: string
  ) => {
    return {
      type: 'ref',
      table: { name: dataType },
      name: field,
    };
  };

  public static valueMapper: <T>(
    value: T
  ) => ast.ExprInteger | ast.ExprNumeric | ast.ExprString | ast.ExprList | ast.ExprNull = <T>(value: T) => {
    switch (typeof value) {
      case 'undefined':
        return { type: 'null' };
      case 'object':
        if (Array.isArray(value) && value.length > 1) {
          return {
            type: 'array',
            expressions: value.map((v) => this.valueMapper(v)),
          };
        } else if (Array.isArray(value)) {
          return this.valueMapper(value.first());
        } else if (value === null) {
          return { type: 'null' };
        } else {
          throw Error(`Object type not supported ${typeof value}, ${value}`);
        }
      case 'string':
        return {
          type: 'string',
          value,
        };
      case 'number':
        if (Number.isInteger(value)) {
          return {
            type: 'integer',
            value,
          };
        } else {
          return {
            type: 'numeric',
            value,
          };
        }
      default:
        throw Error(`Type not supported for value ${value}`);
    }
  };

  public static measureMapper: (dataType: DataTypes, field: string, operation: Operations) => ast.ExprCall = (
    dataType: DataTypes,
    field: string,
    operation: Operations
  ) => {
    const op = operation === Operations.COUNT_DISTINCT ? 'COUNT' : operation;
    return {
      type: 'call',
      function: { name: op.toLowerCase() },
      args: [this.fieldMapper(dataType, field)],
      distinct: operation === Operations.COUNT_DISTINCT ? 'distinct' : undefined,
    };
  };

  public static coalesce: (expr: ast.Expr | undefined, defaultValue: boolean) => ast.ExprCall = (
    expr: ast.Expr | undefined,
    defaultValue: boolean
  ) => {
    return {
      type: 'call',
      function: { name: 'COALESCE' },
      args: [expr ?? { type: 'null' }, { type: 'boolean', value: defaultValue }],
    };
  };

  public static selectedColumnMapper: (expr: ast.Expr) => ast.SelectedColumn = (expr: ast.Expr) => {
    return {
      expr,
    };
  };

  public static isNull: (dataType: DataTypes, field: string, not?: boolean) => ast.ExprUnary | undefined = (
    dataType: DataTypes,
    field: string,
    not = false
  ) => {
    return this.unop(this.fieldMapper(dataType, field), not ? 'IS NOT NULL' : 'IS NULL');
  };

  public static not: (expr: ast.Expr | undefined) => ast.ExprUnary | undefined = (expr: ast.Expr | undefined) => {
    return this.unop(expr, 'NOT');
  };

  public static inCond: <T>(
    dataType: DataTypes,
    field: string,
    values: T[],
    not?: boolean
  ) => ast.ExprBinary | undefined = <T>(dataType: DataTypes, field: string, values: T[], not = false) => {
    return this.binop(
      this.fieldMapper(dataType, field),
      { type: 'list', expressions: values.map((v) => this.valueMapper(v)) },
      not ? 'NOT IN' : 'IN'
    );
  };

  public static equal: (
    left: ast.Expr | undefined,
    right: ast.Expr | undefined,
    not?: boolean
  ) => ast.ExprBinary | undefined = (left: ast.Expr | undefined, right: ast.Expr | undefined, not = false) => {
    return this.binop(left, right, not ? 'IS DISTINCT FROM' : '=');
  };

  public static or: (left: ast.Expr | undefined, right: ast.Expr | undefined) => ast.ExprBinary | undefined = (
    left: ast.Expr | undefined,
    right: ast.Expr | undefined
  ) => {
    return this.binop(left, right, 'OR');
  };

  public static ands: (exprs: (ast.Expr | undefined)[]) => ast.ExprBinary | undefined = (
    exprs: (ast.Expr | undefined)[]
  ) => {
    return exprs.reduce<ast.ExprBinary | undefined>((acc: ast.Expr | undefined, expr: ast.Expr | undefined) => {
      return this.and(acc ?? { type: 'boolean', value: true }, expr ?? { type: 'boolean', value: true });
    }, undefined);
  };

  public static and: (left: ast.Expr | undefined, right: ast.Expr | undefined) => ast.ExprBinary | undefined = (
    left: ast.Expr | undefined,
    right: ast.Expr | undefined
  ) => {
    return this.binop(left, right, 'AND');
  };

  public static unop: (operand: ast.Expr | undefined, op: ast.UnaryOperator) => ast.ExprUnary | undefined = (
    operand: ast.Expr | undefined,
    op: ast.UnaryOperator
  ) => {
    if (!operand) {
      return undefined;
    }
    return {
      type: 'unary',
      operand,
      op,
    };
  };

  public static binop: (
    left: ast.Expr | undefined,
    right: ast.Expr | undefined,
    op: ast.BinaryOperator
  ) => ast.ExprBinary | undefined = (
    left: ast.Expr | undefined,
    right: ast.Expr | undefined,
    op: ast.BinaryOperator
  ) => {
    if (!left || !right) {
      return undefined;
    }
    return {
      type: 'binary',
      left,
      right,
      op,
    };
  };

  public static exprList = (expressions: ast.Expr[]): ast.ExprList => ({
    type: 'list',
    expressions,
  });

  public static filterMapper: (filter: ApiMasterDataQueryFilterItem) => ast.Expr | undefined = (
    filter: ApiMasterDataQueryFilterItem
  ) => {
    switch (typeof filter.values) {
      case 'object':
        if (filter.operation === Operations.EQUAL) {
          if (filter.values && filter.values.length === 1 && filter.values[0] === null) {
            return this.isNull(filter.dataType, filter.property);
          } else if (filter.values && filter.values.length === 1) {
            return this.filterMapper({ ...filter, values: (filter.values.first() as string[]) ?? [] });
          } else if (filter.values && filter.values.length === 0) {
            console.warn(
              'Got a filter that will always return false and cause the query to be empty. This is probably a bug but we will still do as demanded.',
              filter
            );
            return {
              type: 'boolean',
              value: false,
            } as ast.Expr;
          } else {
            if (filter.values.includes(null)) {
              return this.or(
                this.inCond(
                  filter.dataType,
                  filter.property,
                  filter.values.filter((v) => v !== null)
                ),
                this.isNull(filter.dataType, filter.property)
              );
            } else {
              return this.inCond(filter.dataType, filter.property, filter.values);
            }
          }
        } else if (filter.operation === 'NOT_EQUAL') {
          if (filter.values.length === 1) {
            return this.equal(
              this.fieldMapper(filter.dataType, filter.property),
              this.valueMapper(filter.values.first()),
              true
            );
          } else {
            if (filter.values.includes(null)) {
              return this.and(
                this.inCond(
                  filter.dataType,
                  filter.property,
                  filter.values.filter((v) => v !== null),
                  true
                ),
                this.isNull(filter.dataType, filter.property, true)
              );
            } else {
              return this.inCond(filter.dataType, filter.property, filter.values, true);
            }
          }
        } else {
          return this.binop(
            this.fieldMapper(filter.dataType, filter.property),
            this.valueMapper(filter.values),
            this.operationMapper(filter.operation)
          );
        }
      default:
        return this.binop(
          this.fieldMapper(filter.dataType, filter.property),
          this.valueMapper(filter.values),
          this.operationMapper(filter.operation)
        );
    }
  };

  public static combine = <T>(
    array: T[],
    f: (v: T, i: number) => ast.Expr | undefined,
    op: (left: ast.Expr | undefined, right: ast.Expr | undefined) => ast.ExprBinary | undefined
  ): ast.Expr | undefined => {
    if (array.length === 1 && array[0]) return f(array[0], 0);
    return array
      .map((v: T, i: number) => f(v, i))
      .reduce<ast.Expr | undefined>((acc: ast.Expr | undefined, filter: ast.Expr | undefined) => {
        if (acc && filter) {
          return op(acc, filter);
        } else if (filter) {
          return filter;
        } else {
          return acc;
        }
      }, undefined);
  };

  public static combineFiltersAnd: <T>(
    array: T[],
    f: (v: T, i: number) => ast.Expr | undefined
  ) => ast.Expr | undefined = <T>(array: T[], f: (v: T, i: number) => ast.Expr | undefined) => {
    return this.combine(array, f, this.and);
  };

  public static combineFiltersOr: <T>(
    array: T[],
    f: (v: T, i: number) => ast.Expr | undefined
  ) => ast.Expr | undefined = <T>(array: T[], f: (v: T, i: number) => ast.Expr | undefined) => {
    return this.combine(array, f, this.or);
  };

  private static hierarchicalFilterMapper: (filter: ApiMasterDataQueryFilterItem) => ast.Expr | undefined = (
    filter: ApiMasterDataQueryFilterItem
  ) => {
    const result = this.combineFiltersOr(filter.values, (value: ValueTypes) =>
      Array.isArray(value)
        ? this.combineFiltersAnd(value, (v: SingleValueTypes, index: number) =>
            this.filterMapper({
              ...filter,
              operation: Operations.EQUAL,
              property: `${filter.property}_LEVEL_${index + 1}`,
              values: [v],
            })
          )
        : (() => {
            throw new Error('Unexpected single value for hierarchical filter');
          })()
    );
    return filter.operation === Operations.NOT_EQUAL ? this.coalesce(this.not(result), true) : result;
  };

  public static fromMapper: (dataType: DataTypes) => ast.FromTable = (dataType: DataTypes) => {
    return {
      type: 'table',
      name: { name: dataType },
    };
  };

  public static filtersMapper: (filters: ApiMasterDataQueryFilterItem[]) => ast.Expr | undefined = (
    filters: ApiMasterDataQueryFilterItem[]
  ) => {
    const [hierarchicalFilters, nonHierarchicalFilters]: [
      ApiMasterDataQueryFilterItem[],
      ApiMasterDataQueryFilterItem[]
    ] = partition(combineFilters(filters), (f: ApiMasterDataQueryFilterItem) =>
      isHierarchical(filterToDataFieldWithDataType(f))
    );

    const combineAndMap = (
      partitionedFilters: ApiMasterDataQueryFilterItem[],
      mapper: (f: ApiMasterDataQueryFilterItem) => ast.Expr | undefined
    ) => {
      return this.combineFiltersAnd(
        partitionedFilters.map((f) => mapper(f)),
        (value) => value
      );
    };

    const hierarchicalFiltersAst = combineAndMap(hierarchicalFilters, this.hierarchicalFilterMapper);
    const nonHierarchicalFiltersAst = combineAndMap(nonHierarchicalFilters, this.filterMapper);

    const result =
      hierarchicalFilters.length > 0 &&
      nonHierarchicalFilters.length > 0 &&
      hierarchicalFiltersAst &&
      nonHierarchicalFiltersAst
        ? this.and(hierarchicalFiltersAst, nonHierarchicalFiltersAst)
        : hierarchicalFilters.length > 0
        ? hierarchicalFiltersAst
        : nonHierarchicalFiltersAst;
    return result;
  };
}
