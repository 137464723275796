import { Benchmark } from '../../benchmark/types';
import { ForecastType } from '../../forecast/forecast-store';
import { ApiMasterDataQueryFilterItem } from '../../v2/types';
import { baseAuthedTracker } from './baseTracker';

const SIDEBAR_OPENED_EVENT = '[Dashboard Sidebar] Opened';
const SIDEBAR_CLOSED_EVENT = '[Dashboard Sidebar] Closed';

const SIDEBAR_BENCHMARK_TOGGLE_EVENT = '[Dashboard Sidebar] Benchmark Toggled';

const SIDEBAR_SETTING_TOGGLE_LABEL_EVENT = '[Dashboard Sidebar] Setting: Label Toggled';
const SIDEBAR_SETTING_TOGGLE_PERCENTAGE_EVENT = '[Dashboard Sidebar] Setting: Percentage Toggled';
const SIDEBAR_SETTING_TOGGLE_CURRENCY_EVENT = '[Dashboard Sidebar] Setting: Currency Toggled';

const SIDEBAR_SEGMENT_TOGGLE_EVENT = '[Dashboard Sidebar] Segment Toggled';

const SIDEBAR_FORECAST_TOGGLE_EVENT = '[Dashboard Sidebar] Forecast Toggled';

export const trackBenchmark = (benchmark: Benchmark) => {
  baseAuthedTracker(SIDEBAR_BENCHMARK_TOGGLE_EVENT, {
    benchmark,
  });
};

export const trackSettingLabelToggle = () => {
  baseAuthedTracker(SIDEBAR_SETTING_TOGGLE_LABEL_EVENT);
};

export const trackSettingPercentageToggle = () => {
  baseAuthedTracker(SIDEBAR_SETTING_TOGGLE_PERCENTAGE_EVENT);
};

export const trackSettingCurrencyToggle = () => {
  baseAuthedTracker(SIDEBAR_SETTING_TOGGLE_CURRENCY_EVENT);
};

export const trackSegment = (segment: ApiMasterDataQueryFilterItem) => {
  baseAuthedTracker(SIDEBAR_SEGMENT_TOGGLE_EVENT, {
    segment,
  });
};

export const trackForecast = (forecast: ForecastType) => {
  baseAuthedTracker(SIDEBAR_FORECAST_TOGGLE_EVENT, {
    forecast: forecast.type,
  });
};

export const trackOpenSidebar = () => {
  baseAuthedTracker(SIDEBAR_OPENED_EVENT);
};

export const trackHideSidebar = () => {
  baseAuthedTracker(SIDEBAR_CLOSED_EVENT);
};
