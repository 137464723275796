import { getKeyFromDataFieldWithDataType } from '../../../common-types';
import { Domains, Languages } from '../constants/constants';
import { Language } from '../graphql/generated/graphql-sdk';
import { rootStore } from '../store/root-store';
import {
  AliasTypes,
  NewApiAlias,
  NewApiAliasFor,
  NewApiAliasForDataField,
  NewApiAliasForMetricCategoryId,
  NewApiAliasForMetricGroupId,
  NewApiAliasTranslation,
  toNewApiAliasForDataFields,
  toNewApiAliasForMetricCategoryId,
  toNewApiAliasForMetricGroupId,
} from './api-interfaces';
import { GraphQlRequestService } from './graphql-request-service';
import { errorHandler, getGraphqlResult } from './utils';

export interface IAliasService {
  getAliasesForDataFields(domain: Domains): Promise<NewApiAliasForDataField[]>;
  getAliasesForMetricGroupId(domain: Domains): Promise<NewApiAliasForMetricGroupId[]>;
  getAliasesForMetricCategoryId(domain: Domains): Promise<NewApiAliasForMetricCategoryId[]>;
  getAliases(domain: Domains): Promise<NewApiAlias[]>;
  setAliasForDataField(
    domain: Domains,
    alias: NewApiAliasForDataField,
    getKey: () => string,
    type: AliasTypes
  ): Promise<void>;
  setAliasForMetricGroupId(
    domain: Domains,
    alias: NewApiAliasForMetricGroupId,
    getKey: () => string,
    type: AliasTypes
  ): Promise<void>;
  setAliasForMetricCategoryId(
    domain: Domains,
    alias: NewApiAliasForMetricCategoryId,
    getKey: () => string,
    type: AliasTypes
  ): Promise<void>;
  deleteAliasForDataField(domain: Domains, alias: NewApiAliasForDataField): Promise<void>;
  deleteAliasForMetricGroupId(domain: Domains, alias: NewApiAliasForMetricGroupId): Promise<void>;
  deleteAliasForMetricCategoryId(domain: Domains, alias: NewApiAliasForMetricCategoryId): Promise<void>;
}

export class AliasService implements IAliasService {
  private graphQlRequestService: GraphQlRequestService;

  constructor(graphqlRequestService: GraphQlRequestService) {
    this.graphQlRequestService = graphqlRequestService;
  }

  public async getAliasesForDataFields(domain: Domains): Promise<NewApiAliasForDataField[]> {
    const apiAliases = await this.getAliases(domain);
    return toNewApiAliasForDataFields(apiAliases);
  }

  public async getAliasesForMetricGroupId(domain: Domains): Promise<NewApiAliasForMetricGroupId[]> {
    const apiAliases = await this.getAliases(domain);
    return toNewApiAliasForMetricGroupId(apiAliases);
  }

  public async getAliasesForMetricCategoryId(domain: Domains): Promise<NewApiAliasForMetricCategoryId[]> {
    const apiAliases = await this.getAliases(domain);
    return toNewApiAliasForMetricCategoryId(apiAliases);
  }

  public async getAliases(domain: Domains): Promise<NewApiAlias[]> {
    try {
      const result = rootStore.domainDependencyStore.getAliases() || [];
      const modifiedResult = result.map((alias) => {
        return {
          ...alias,
          type: alias.type as AliasTypes,
          translations: alias.translations.map((t) => {
            let language: Languages;
            if (t.language === Language.En) {
              language = Languages.EN;
            } else if (t.language === Language.Jp) {
              language = Languages.JA;
            } else {
              throw new Error(`Unidentified Language found: ${t.language}`);
            }
            return {
              ...t,
              language,
            };
          }),
        };
      });
      return modifiedResult;
    } catch (e) {
      return errorHandler(e, { request: 'listAliases', domain });
    }
  }

  private convertTranslations = (translations: NewApiAliasTranslation[]) =>
    translations.map((translation) => {
      let apiLang: Language;
      const language = translation.language as string;
      if (language === Languages.EN) {
        apiLang = Language.En;
      } else if (language === Languages.JA) {
        apiLang = Language.Jp;
      } else {
        throw new Error(
          `Unexpected alias language ${language}. Cannot convert it into API language. Expected en or ja.`
        );
      }

      return {
        text: translation.text,
        language: apiLang,
      };
    });

  public async setAliasForDataField(domain: Domains, alias: NewApiAliasForDataField): Promise<void> {
    return this.setAlias(
      domain,
      alias,
      () => getKeyFromDataFieldWithDataType(alias.dataFieldWithDataType),
      AliasTypes.DATAFIELD
    );
  }

  public async setAliasForMetricGroupId(domain: Domains, alias: NewApiAliasForMetricGroupId): Promise<void> {
    return this.setAlias(domain, alias, () => alias.metricGroupId, AliasTypes.METRIC_GROUP_ID);
  }

  public async setAliasForMetricCategoryId(domain: Domains, alias: NewApiAliasForMetricCategoryId): Promise<void> {
    return this.setAlias(domain, alias, () => alias.metricCategoryId, AliasTypes.METRIC_CATEGORY_ID);
  }

  private async setAlias(
    domain: Domains,
    alias: NewApiAliasFor,
    getKey: () => string,
    type: AliasTypes
  ): Promise<void> {
    try {
      const convertedTranslations = this.convertTranslations(alias.translations);

      const result = getGraphqlResult(
        await this.graphQlRequestService.graphQlSdk.setAlias({
          domain,
          key: getKey(),
          type,
          translations: convertedTranslations,
          simulateRole: rootStore.permissionsStore.currentlySimulatingRole()?.id ?? null,
        })
      ).setAlias;

      return result;
    } catch (e) {
      return errorHandler(e, {
        request: 'setAlias',
        domain,
        field: getKey(),
      });
    }
  }

  public async deleteAliasForDataField(domain: Domains, alias: NewApiAliasForDataField): Promise<void> {
    return this.deleteAlias(domain, () => getKeyFromDataFieldWithDataType(alias.dataFieldWithDataType));
  }

  public async deleteAliasForMetricGroupId(domain: Domains, alias: NewApiAliasForMetricGroupId): Promise<void> {
    return this.deleteAlias(domain, () => alias.metricGroupId);
  }

  public async deleteAliasForMetricCategoryId(domain: Domains, alias: NewApiAliasForMetricCategoryId): Promise<void> {
    return this.deleteAlias(domain, () => alias.metricCategoryId);
  }

  private async deleteAlias(domain: Domains, getKey: () => string): Promise<void> {
    try {
      getGraphqlResult(
        await this.graphQlRequestService.graphQlSdk.deleteAlias({
          domain,
          aliasKey: getKey(),
          simulateRole: rootStore.permissionsStore.currentlySimulatingRole()?.id ?? null,
        })
      );

      return;
    } catch (e) {
      return errorHandler(e, {
        request: 'deleteAlias',
        domain,
        field: getKey(),
      });
    }
  }
}
