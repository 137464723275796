import { TFunction } from 'i18next';
import { ValueOf } from 'ts-pattern/dist/types/helpers';
import { FilterTypes } from './common/components/filter/filterbar/types';
import { translateLabel } from './common/utils';
import { ASCII_ARROW, DEFAULT_OPTION, HierarchicalFields, UNDEFINED_DISPLAY_KEY } from './constants';
import {
  ApiMasterDataQueryFilterItem,
  ApplicationDataFields,
  DataFields,
  DataFieldWithDataType,
  DataTypes,
  EmployeeDataFields,
  FormatTypes,
  HashCode,
  NonEmptyArray,
} from './types';

export const hierarchicalFieldFullLabel = (
  t: TFunction,
  values: (string | null)[],
  dimension: DataFieldWithDataType,
  defaultValue: string
) => {
  const label =
    values
      .map((v: string | null) => {
        const translatedLabel = translateLabel(v, dimension);
        return translatedLabel ? t(translatedLabel, { defaultValue: UNDEFINED_DISPLAY_KEY }) ?? defaultValue : v;
      })
      .join(ASCII_ARROW) ?? defaultValue;
  if (label.length === 0) {
    return defaultValue;
  } else {
    return label;
  }
};

export const hierarchicalFieldLabel = (
  t: TFunction,
  value: string | null,
  dimension: DataFieldWithDataType,
  defaultValue: string
) => {
  const translatedLabel = translateLabel(value, dimension);
  const label = translatedLabel ? t(translatedLabel, { defaultValue }) : value ?? defaultValue;
  return label;
};

export const nonHierarchicalFieldLabel = (
  t: TFunction,
  value: string | null,
  dimension: DataFieldWithDataType,
  defaultValue: string
) => {
  const translatedLabel = translateLabel(value, dimension);
  return translatedLabel ? t(translatedLabel, { defaultValue }) : value ?? defaultValue;
};

// Source: https://stackoverflow.com/a/7616484
export const hashCode = (s: string): HashCode => {
  let hash = 0,
    i,
    chr;
  if (s.length === 0) return { type: 'hashCode', hash };
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return { type: 'hashCode', hash: Math.abs(hash) };
};

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

// Iterate through enumeration keys without loosing the type of the enumeration
// Without this, the type of each element would be string
export function enumValues<O extends object, V extends ValueOf<O> = ValueOf<O>>(obj: O): V[] {
  return Object.values(obj) as V[];
}

export const toDataFieldWithDataType = (dataType: DataTypes, field: string) => ({
  dataType,
  dataField: field as DataFields,
});

// nonFrontendQueryableFields are those that won't be used in queries
// from the frontend and therefore don't need permissions to be set on them
export const internalFields: Set<DataFieldWithDataType> = new Set([
  ...enumValues(DataTypes).flatMap((d) => {
    return [
      toDataFieldWithDataType(d, 'ID'),
      toDataFieldWithDataType(d, 'ENTITY_ID'),
      toDataFieldWithDataType(d, 'IDHASH'),
      toDataFieldWithDataType(d, 'NAMESPACE'),
    ];
  }),
]);

export const nonFrontendQueryableFields: Set<DataFieldWithDataType> = new Set([
  ...Array.from(internalFields),
  ...HierarchicalFields,
]);

// UserVisible fields are those that are meant to be shown on dataview, column selector, etc
// excludes fields like EFFECTIVE_LEAVER_DATE_NORMALIZED, DATE_OF_BIRTH_YEAR_MONTH, etc
// which are used in queries internally
export const queryableButNonUserVisibleFields: Set<DataFieldWithDataType> = new Set([
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TEMPORALITY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.VERSION_ID },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.SUPERIOR_ID },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.START_DATE_NORMALIZED },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TERM_DATE_NORMALIZED },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EFFECTIVE_LEAVER_DATE_NORMALIZED },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EFFECTIVE_LEAVER_DATE_NORMALIZED_YEAR_MONTH },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.START_DATE_NORMALIZED_YEAR_MONTH },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TERM_DATE_NORMALIZED_YEAR_MONTH },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.DATE_OF_BIRTH_YEAR_MONTH },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CALENDAR_YEAR },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CALENDAR_QUARTER },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FINANCIAL_YEAR },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FINANCIAL_QUARTER },
  { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.VERSION_ID },
]);

export const nonUserVisibleFields = new Set([
  ...Array.from(queryableButNonUserVisibleFields),
  ...Array.from(nonFrontendQueryableFields),
]);

export const isValidNumber = (val: number | string) => {
  if (val === 0 || val === '0') {
    return true;
  } else {
    return Boolean(Number(val));
  }
};

interface ValueLimitToPrecisionMap {
  1: number;
  10: number;
  100: number;
  1000: number;
  [DEFAULT_OPTION]: number;
}

const formatTypeToPrecisionsMap: Record<FormatTypes, ValueLimitToPrecisionMap | null> = {
  [FormatTypes.PERCENTAGE]: {
    1: 2,
    10: 2,
    100: 1,
    1000: 0,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.COUNT]: {
    1: 0,
    10: 0,
    100: 0,
    1000: 0,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.NUMBER]: {
    1: 2,
    10: 1,
    100: 0,
    1000: 0,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.CURRENCY_INDIVIDUAL]: {
    1: 2,
    10: 2,
    100: 2,
    1000: 2,
    [DEFAULT_OPTION]: 2,
  },
  [FormatTypes.CURRENCY_AGGREGATE]: {
    1: 2,
    10: 2,
    100: 1,
    1000: 1,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.SENSITIVE_NUMBER]: {
    1: 2,
    10: 1,
    100: 1,
    1000: 0,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.HOURS]: {
    1: 2,
    10: 2,
    100: 1,
    1000: 1,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.LEAVES_TAKEN]: {
    1: 2,
    10: 2,
    100: 1,
    1000: 1,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.FTE]: {
    1: 2,
    10: 2,
    100: 2,
    1000: 2,
    [DEFAULT_OPTION]: 2,
  },
  [FormatTypes.EXACT_NUMBER]: null,
  [FormatTypes.FLOATING_NUMBER_1]: {
    1: 1,
    10: 1,
    100: 1,
    1000: 1,
    [DEFAULT_OPTION]: 1,
  },
  [FormatTypes.FLOATING_NUMBER_2]: {
    1: 2,
    10: 2,
    100: 2,
    1000: 2,
    [DEFAULT_OPTION]: 2,
  },
};

export const getFormattingPrecisionForNumber = (num: number, formatType: FormatTypes) => {
  const absNum = Math.abs(num);
  const precisionsMap = formatTypeToPrecisionsMap[formatType];
  if (precisionsMap === null) {
    return null;
  } else {
    if (absNum < 1) {
      return precisionsMap[1];
    } else if (absNum < 10) {
      return precisionsMap[10];
    } else if (absNum < 100) {
      return precisionsMap[100];
    } else if (absNum < 1000) {
      return precisionsMap[1000];
    } else {
      return precisionsMap[DEFAULT_OPTION];
    }
  }
};

export const roundOffNumber = (num: number | string, formatType: FormatTypes): number => {
  const n = Number(num);
  const precision = getFormattingPrecisionForNumber(n, formatType);
  if (precision !== null) {
    return Number(n.toFixed(precision));
  } else {
    return n;
  }
};

export const currencySymbol: Record<string, string> = {
  JPY: '円',
  USD: '$',
  EUR: '€',
  MXN: 'MXN',
  AUD: 'A$',
};

export const filterToDataFieldWithDataType = (filter: ApiMasterDataQueryFilterItem) => ({
  dataType: filter.dataType,
  dataField: filter.property as DataFields,
});

export const getKeyFromDataFieldWithDataType = (d: DataFieldWithDataType): string => {
  return `${d.dataType}+__+${d.dataField}`;
};

export const getDataFieldWithDataTypeFromKey = (d: string): DataFieldWithDataType => {
  const [dataType, dataField] = d.split('+__+');
  return { dataType: dataType as DataTypes, dataField: dataField as DataFields };
};

export const findWithRest =
  <T>(args: T[]) =>
  (cond: (t: T) => boolean): NonEmptyArray<T> => {
    return [args.find(cond), ...args.filter((t) => !cond(t))];
  };
