import { cloneDeep } from 'lodash';
import { useReducer, useState } from 'react';
import { match } from 'ts-pattern';
import { TimeRange } from '../../../api/timeranges/types';
import { Domains } from '../../../constants';
import { TimestampUTC } from '../../../types';
import {
  CalendarYearMonthlyTimesliderGranularity,
  CalendarYearYearlyTimesliderGranularity,
  FinancialYearQuarterlyTimesliderGranularity,
  FinancialYearYearlyTimesliderGranularity,
} from './constants';
import {
  Granularity,
  MenuHandle,
  TimeSliderAction,
  TimeSliderGranularity,
  TimeSliderGranularityAction,
  TimeSliderPresetAction,
  TimeSliderRangeAction,
  TimeSliderReducer,
  TimeSliderState,
  TimeSliderStateWithPersistence,
} from './types';
import { GranularityDefaultInfo, jsonParserReviver, presetToTimeRange } from './utils';

export const timeSliderGranularities: TimeSliderGranularity[] = [
  CalendarYearMonthlyTimesliderGranularity,
  CalendarYearYearlyTimesliderGranularity,
  FinancialYearYearlyTimesliderGranularity,
  FinancialYearQuarterlyTimesliderGranularity,
];

// TODO: should be based on client settings
export const initialState = (
  availableTimeRanges: Record<Granularity, TimeRange>,
  current: TimestampUTC
): TimeSliderState => {
  const selectedGranularity = CalendarYearMonthlyTimesliderGranularity;
  const range = availableTimeRanges[selectedGranularity.value];
  const granularityInfo = GranularityDefaultInfo(availableTimeRanges, current);
  const selectedPreset = granularityInfo[selectedGranularity.value].defaultPreset;
  const { start, end } = presetToTimeRange(selectedPreset, range, selectedGranularity);
  const initialState = {
    start,
    end,
    range,
    initialRange: { ...range },
    granularities: timeSliderGranularities,
    selectedGranularity,
    selectedPreset,
    availableTimeRanges,
    granularityInfo,
  };
  return {
    ...initialState,
    initialState: cloneDeep(initialState),
  };
};

export const useTimeSliderWithPersistence = (domain: Domains, init: TimeSliderState) => {
  const persistenceKey = `${domain}-timeslider-v1`;
  const stored: string | null = localStorage.getItem(persistenceKey);
  const storedItems: TimeSliderStateWithPersistence | null = stored
    ? (JSON.parse(stored, jsonParserReviver) as unknown as TimeSliderStateWithPersistence)
    : null;
  const state = storedItems ?? { ...init, persistenceKey };
  return useReducer(reducerWithPersistentWrapper(reducer), state);
};

export const useTimeSlider = (init: TimeSliderState) => {
  return useReducer(reducer, init);
};

export const reducerWithPersistentWrapper = (
  underlyingReducer: TimeSliderReducer<TimeSliderState, TimeSliderAction>
): TimeSliderReducer<TimeSliderStateWithPersistence, TimeSliderAction> => {
  return (state: TimeSliderStateWithPersistence, action: TimeSliderAction): TimeSliderStateWithPersistence => {
    const newstate = underlyingReducer(state, action);
    // TODO: think whether we should store a smaller version of the state so that we can update keys without breaking the app for existing users
    localStorage.setItem(state.persistenceKey, JSON.stringify(newstate));
    return { ...newstate, persistenceKey: state.persistenceKey };
  };
};

export const reducer = (state: TimeSliderState, action: TimeSliderAction): TimeSliderState => {
  return match(action)
    .with({ type: 'reset' }, () => {
      const newState = state.initialState;
      return { ...newState, initialState: newState };
    })
    .with({ type: 'update-range' }, (a: TimeSliderRangeAction) => {
      return {
        ...state,
        start: a.start,
        end: a.end,
      };
    })
    .with({ type: 'update-granularity' }, (a: TimeSliderGranularityAction) => {
      const selectedGranularity = a.granularity;
      const range = state.availableTimeRanges[selectedGranularity.value];
      const granularityInfo = GranularityDefaultInfo(state.availableTimeRanges, a.current)[selectedGranularity.value];
      const selectedPreset = granularityInfo.defaultPreset;
      const { start, end } = presetToTimeRange(selectedPreset, range, selectedGranularity);
      const newState = { ...state, selectedGranularity, selectedPreset, range, start, end };
      return newState;
    })
    .with({ type: 'update-preset' }, (a: TimeSliderPresetAction) => {
      const selectedGranularity = a.granularity;
      const range = state.availableTimeRanges[selectedGranularity.value];
      const selectedPreset = a.preset;
      const { start, end } = presetToTimeRange(selectedPreset, range, selectedGranularity);
      const newState = { ...state, selectedGranularity, selectedPreset, range, start, end };
      return newState;
    })
    .exhaustive();
};

export const useMenu = (): MenuHandle => {
  const [opened, setOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<ParentNode | null>(null);
  return {
    opened,
    open: () => setOpened(true),
    close: () => setOpened(false),
    anchorEl,
    setAnchorEl,
  };
};
