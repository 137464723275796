import { GraphQlRequestService } from '../../../api/graphql-request-service';
import { FilterTypes } from '../../common/components/filter/filterbar/types';
import { Role, RoleId } from '../types';
import { handleGQLErrors } from '../utils';
import { UserRolePreferencesSettings, UserRolePreferencesSettingsSettings } from './types';

export interface UserRolePreferencesService<T extends FilterTypes> {
  getLatestVersion: () => Promise<UserRolePreferencesSettings<T> | null>;
  getByVersion: (version: string) => Promise<UserRolePreferencesSettings<T> | null>;
  save: (preferences: UserRolePreferencesSettingsSettings<T>) => Promise<UserRolePreferencesSettings<T> | null>;
}

export class BackendUserRolePreferencesService<T extends FilterTypes> implements UserRolePreferencesService<T> {
  constructor(
    readonly graphQlRequestService: GraphQlRequestService,
    readonly effectiveRoleId: RoleId,
    readonly simulatedRole: Role | null
  ) {}

  public getLatestVersion = async () => {
    const result = await handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.rolePreferencesLatestVersion({
        roleId: this.effectiveRoleId,
        simulateRole: this.simulatedRole?.id ?? null,
      })
    );
    return result.rolePreferencesLatestVersion;
  };

  public getByVersion = async (version: string) => {
    const result = await handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.rolePreferencesVersion({
        version,
        roleId: this.effectiveRoleId,
        simulateRole: this.simulatedRole?.id ?? null,
      })
    );
    return result.rolePreferencesVersion;
  };

  public save = async (
    preferences: UserRolePreferencesSettingsSettings<T>
  ): Promise<UserRolePreferencesSettings<T> | null> => {
    const result = await handleGQLErrors(
      this.graphQlRequestService.graphQlSdk.createNewRolePreferences({
        preferences,
        roleId: this.effectiveRoleId,
        simulateRole: this.simulatedRole?.id ?? null,
      })
    );
    return result.createNewRolePreferences;
  };
}
