import { DataFields, DataFieldWithDataType, DataTypes } from '../../types';
import { DATAFIELD_WITH_DATATYPE_TAG_PREFIX } from './constants';

export const getFilterFieldFromTag = (tag: string): DataFieldWithDataType => {
  const colonIndex = tag.indexOf(':');
  const dotIndex = tag.indexOf('.');
  const prefixIndex = tag.indexOf(DATAFIELD_WITH_DATATYPE_TAG_PREFIX);
  // TODO: Improve this using regex later and add support for multiple tags
  const validTag = prefixIndex === 0 && colonIndex !== -1 && dotIndex !== -1 && dotIndex > colonIndex;
  if (validTag) {
    const [dataType, dataField] = tag.split(':')[1].split('.');
    return { dataType: dataType as DataTypes, dataField: dataField as DataFields };
  } else {
    throw new Error(`Invalid tag found in getFilterFieldFromTag function - ${tag}`);
  }
};
