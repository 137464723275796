import { getAttritionTranslationKey } from '../../pages/dashboard/dashboard-custom-translations';
import { CompanyStore } from '../company/company-store';
import { Dashboards } from '../constants/constants';
import { InitialDependencyStore } from '../startup/initial-dependency-store';
import { ApiDashboard, ApiDashboardList } from './api-interfaces';
import { errorHandler, getGraphqlResult } from './utils';

export class DashboardApiService {
  private companyStore: CompanyStore;
  private initialDependencyStore: InitialDependencyStore;

  constructor(companyStore: CompanyStore, initialDependencyStore: InitialDependencyStore) {
    this.companyStore = companyStore;
    this.initialDependencyStore = initialDependencyStore;
  }

  private defaultDashboardVideoLink = () =>
    this.companyStore.isJapaneseEnterpriseClient() ? 'IZh3iIHQINA' : 'Lj2sZdQ3ZYc';

  public async listDashboards(): Promise<ApiDashboardList> {
    try {
      const result = getGraphqlResult(this.initialDependencyStore.listAllDashboards());

      const dashboards: ApiDashboard[] = result.map((dashboard) => {
        return {
          id: dashboard.id as Dashboards, // TODO errorhandling
          nameKey:
            dashboard.id === Dashboards.ATTRITION ? getAttritionTranslationKey(dashboard.nameKey) : dashboard.nameKey,
          descriptionKey: dashboard.descriptionKey,
          enabled: dashboard.enabled,
          imageLink: dashboard.imagePath,
          link: dashboard.link,
          pages: dashboard.pages.map((page) => {
            return {
              pageId: page.pageId,
              pageNumber: page.pageNumber,
              nameKey:
                page.pageId === 'attrition-overview' || page.pageId === 'gender-diversity-attrition'
                  ? getAttritionTranslationKey(page.nameKey)
                  : page.nameKey,
              descriptionKey: page.descriptionKey,
              enabled: page.enabled,
              imageLink: page.imagePath,
              link: page.link,
            };
          }),
          videoLink: this.defaultDashboardVideoLink(),
          connectMoreToolsLink: '',
        };
      });
      return { dashboards };
    } catch (e) {
      return errorHandler(e, { request: 'listAllDashboards' });
    }
  }
}
