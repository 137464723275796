import { toJS } from 'mobx';
import stringHash from 'string-hash';
import { deepClone } from '../utilFunctions/utils';
import { CacheItem, ICache } from './cache';

export class InMemoryCache implements ICache {
  private cache: Record<string, CacheItem> = {};

  public get(key: string): CacheItem {
    return toJS(this.cache[key], { recurseEverything: true });
  }

  public add(key: string, data: any): void {
    this.cache[key] = {
      key,
      data,
      createdAt: new Date(),
    };
  }

  public clear = () => {
    this.cache = {};
  };

  public getFromCacheOrRequest = async <T>(cacheKey: string, f: () => Promise<T>): Promise<T> => {
    const hashedKey = String(stringHash(cacheKey));
    const cachedResponse = this.get(hashedKey);
    if (cachedResponse) {
      return deepClone(cachedResponse.data);
    } else {
      const response = await f();
      // TODO: Don't cache failed requests
      if (response) {
        this.add(hashedKey, deepClone(response));
      }
      return response;
    }
  };
}
