import { ErrorTypes } from '../sentry/types';
import { rootStore } from '../store/root-store';
import { LatestDomainPreferences, LatestDomainSettings } from './api-interfaces';
import { errorHandler, getErrorCodeFromError, getGraphqlResult, GraphQlRequestErrorCode } from './utils';
import { ApiCompany, DomainPreferences } from './zod-schemas';

class CompanyApiService {
  public listAvailableDomains = async (): Promise<string[]> => {
    const { graphQlRequestService, permissionsStore } = rootStore;
    const currentlySimulatingRole = permissionsStore.currentlySimulatingRole();
    try {
      const result = await graphQlRequestService.graphQlSdk.listAllAllowedDomains({
        roleId: permissionsStore.effectiveRoleId(),
        simulateRole: currentlySimulatingRole?.id || null,
      });
      return getGraphqlResult(result.listAllAllowedDomains);
    } catch (e) {
      if (currentlySimulatingRole) {
        console.warn(
          `Error when fetching listDomains but we are currently simulating a role. Maybe it is a non-user role, so we will retry without simulation to make role simulation possible for non-user roles`,
          e
        );
        try {
          const result = await graphQlRequestService.graphQlSdk.listAllAllowedDomains({
            roleId: permissionsStore.getUserRoleId(),
            simulateRole: null,
          });
          return getGraphqlResult(result.listAllAllowedDomains);
        } catch (e) {
          console.error(`Error when fetching listDomains `, e);
          throw e;
        }
      }
      console.error(`Error when fetching listDomains `, e);
      throw e;
    }
  };

  public getDomainSettings = async (domain: string): Promise<LatestDomainSettings | null> => {
    const { graphQlRequestService } = rootStore;
    try {
      const result = await graphQlRequestService.graphQlSdk.domainSettingsLatestVersion({
        domain,
        simulateRole: rootStore.permissionsStore.currentlySimulatingRole()?.id || null,
      });
      return getGraphqlResult(result.domainSettingsLatestVersion);
    } catch (e) {
      if (rootStore.permissionsStore.currentlySimulatingRole()?.id) {
        // We are doing role-simulation, it will prevent testing specific roles that don't have Login permissions to load the domainsettings
        // We either need to have a dedicated permission-type to get domain settings or have to fallback to get domainsettings without role-simulation here
        console.debug(
          `Getting domainsettings for ${domain} using role-simulation failed. Falling back to getting domainsettings without simulation`
        );
        const fallbackResult = await graphQlRequestService.graphQlSdk.domainSettingsLatestVersion({
          domain,
          simulateRole: null,
        });

        if (fallbackResult.domainSettingsLatestVersion) {
          return getGraphqlResult(fallbackResult.domainSettingsLatestVersion);
        } else {
          console.warn(`Error when getting domain settings for ${domain} (even without rolesimulation)`, e);
          return null;
        }
      } else {
        console.warn(`Error when getting domain settings for ${domain}`, e);
        const errorCode = getErrorCodeFromError(e);
        if (errorCode === GraphQlRequestErrorCode.Err_DomainAccessDeniedWrongAuthMethod) {
          setTimeout(() => {
            //This was taken out of authStore.logOutUser to remove a cyclical dependency
            rootStore.permissionsStore.stopRoleSimulation();
            rootStore.authStore.logOutUser();
          }, 3000);
        }
        // TODO: Need to handle error in the first if clause of catch condition too
        errorHandler(e, undefined, ErrorTypes.DOMAIN_ACCESS_DENIED);
        return null;
      }
    }
  };

  public getDomainPreferences = async (domain: string): Promise<LatestDomainPreferences | null> => {
    const { graphQlRequestService } = rootStore;
    try {
      const result = await graphQlRequestService.graphQlSdk.domainPreferencesLatestVersion({
        domain,
        simulateRole: rootStore.permissionsStore.currentlySimulatingRole()?.id || null,
      });
      return getGraphqlResult(result.domainPreferencesLatestVersion);
    } catch (e) {
      if (rootStore.permissionsStore.currentlySimulatingRole()?.id) {
        // We are doing role-simulation, it will prevent testing specific roles that don't have Login permissions to load the domainsettings
        // We either need to have a dedicated permission-type to get domain settings or have to fallback to get domainsettings without role-simulation here
        console.debug(
          `Getting domainPreferences for ${domain} using role-simulation failed. Falling back to getting domainPreferences without simulation`
        );
        const fallbackResult = await graphQlRequestService.graphQlSdk.domainPreferencesLatestVersion({
          domain,
          simulateRole: null,
        });

        if (fallbackResult.domainPreferencesLatestVersion) {
          return getGraphqlResult(fallbackResult.domainPreferencesLatestVersion);
        } else {
          console.warn(`Error when getting domain preferences for ${domain} (even without rolesimulation)`, e);
          return null;
        }
      } else {
        console.warn(`Error when getting domain preferences for ${domain}`, e);
        return null;
      }
    }
  };

  public updateDomainSettings = async (domain: string, settings: ApiCompany): Promise<LatestDomainSettings> => {
    try {
      const { graphQlRequestService } = rootStore;
      const result = await graphQlRequestService.graphQlSdk.createNewDomainSettings({
        domain,
        settings,
        simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
      });
      return getGraphqlResult(result.createNewDomainSettings);
    } catch (error) {
      return errorHandler(error, {
        request: 'updateDomainSettings',
      });
    }
  };

  public updateDomainPreferences = async (
    domain: string,
    preferences: DomainPreferences
  ): Promise<LatestDomainPreferences> => {
    try {
      const { graphQlRequestService } = rootStore;
      const result = await graphQlRequestService.graphQlSdk.createNewDomainPreferences({
        domain,
        preferences,
        simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
      });
      return getGraphqlResult(result.createNewDomainPreferences);
    } catch (error) {
      return errorHandler(error, {
        request: 'updateDomainPreferences',
      });
    }
  };
}

export const companyApiService = new CompanyApiService();
