import { GraphQlRequestService } from '../../../api/graphql-request-service';
import { Domains, DOMAIN_KEY } from '../../constants';

interface CompanyService {
  getDomains(): Promise<Domains[]>;
  getDomain(): Domains | null;
}

export class BackendCompanyService implements CompanyService {
  constructor(readonly graphQlRequestService: GraphQlRequestService) {}

  public getDomains = async (): Promise<Domains[]> => {
    const result = await this.graphQlRequestService.graphQlSdk.currentUserUserRole();
    // TODO: confirm with Valentin if this makes sense and whether `null` means that we should allow the user to select all domains?
    return result.currentUserUserRole?.executorRoles?.map((r) => r.domain as Domains) ?? [];
  };

  public getDomain = (): Domains | null => null;
}

export class LocalStorageCompanyService implements CompanyService {
  constructor(readonly availableDomains: Domains[]) {}

  public getDomain = (): Domains | null => {
    const stored: string | null = localStorage.getItem(DOMAIN_KEY);
    return this.availableDomains.find((d) => d === stored) ?? null;
  };

  public getDomains = () => Promise.resolve(this.availableDomains);
}
