import { CircularProgress, CircularProgressProps } from '@mui/material';
import React, { Component } from 'react';
import { Flex } from '../layout';
import { styled } from '../styled';

const LoadingWrapper = styled(Flex)`
  margin: 8px;
  justify-content: center;
  align-content: middle;
`;

interface LoadingProps {
  color?: 'primary' | 'secondary';
  sx?: CircularProgressProps['sx'];
}

export default class Loading extends Component<LoadingProps> {
  public render() {
    const { color, sx } = this.props;
    return (
      <LoadingWrapper>
        <CircularProgress color={color ? color : 'primary'} sx={sx} disableShrink />
      </LoadingWrapper>
    );
  }
}
