import { rootStore } from '../store/root-store';
import { GraphQlRequestService } from './graphql-request-service';
import { getGraphqlResult } from './utils';
import { UserRolePreferencesSettings } from './zod-schemas';

export class UserRolePreferencesService {
  graphQlRequestService: GraphQlRequestService;

  constructor(graphqlRequestService: GraphQlRequestService) {
    this.graphQlRequestService = graphqlRequestService;
  }

  public getLatestUserRolePreferences = async () => {
    return rootStore.initialDependencyStore.getLatestUserRolePreferences();
  };

  public getSpecificVersionUserRolePreferences = async (version: string) => {
    return getGraphqlResult(
      await this.graphQlRequestService.graphQlSdk.rolePreferencesVersion({
        roleId: rootStore.permissionsStore.effectiveRoleId(),
        version,
        simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
      })
    );
  };

  public saveUserRolePreferences = async (preferences: UserRolePreferencesSettings) => {
    return getGraphqlResult(
      await this.graphQlRequestService.graphQlSdk.createNewRolePreferences({
        roleId: rootStore.permissionsStore.effectiveRoleId(),
        preferences,
        simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
      })
    );
  };
}
