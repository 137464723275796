import { CompanyStore } from './company/company-store';
import { environmentService } from './environment/environment-service';
import { IPermissionsStore } from './permissions/permissions-store';

export class EnabledFeaturesService {
  /** Use this for features that are in testing stage and not fully rolled out to clients, and so
   * we deploy them on limited environments or only for panalytSuperAdmins.
   * For features that are meant to be accessible to limited user types, use permissions-store
   * For features that are enabled on specific dashboards, use dashboard-configurations-store
   */

  private permissionsStore: IPermissionsStore;
  private companyStore: CompanyStore;
  constructor(permissionsStore: IPermissionsStore, companyStore: CompanyStore) {
    this.permissionsStore = permissionsStore;
    this.companyStore = companyStore;
  }

  public isSwitchRoleEnabled = (): boolean => {
    return this.permissionsStore.userPermissionsCanManageAllDomains;
  };

  public isPermissionsUiEnabled = (): boolean => {
    return (
      this.permissionsStore.canManageAllDomains ||
      (this.companyStore.domainSettingsObject?.settings.canUsePermissionsUi ?? false)
    );
  };

  public isDomainPreferencesEnabled = (): boolean => {
    return this.permissionsStore.canManageAllDomains;
  };

  public isDomainSettingsEnabled = (): boolean => {
    return this.permissionsStore.canManageAllDomains;
  };

  public isSessionRecordingEnabled = (): boolean => {
    return this.permissionsStore.canManageAllDomains && !environmentService.isDemo;
  };

  public isCrextaPermissionsView = (): boolean => {
    return this.companyStore.isCrextaDomain;
  };

  public isAliasEnabled = (): boolean => {
    return this.permissionsStore.canManageAliases;
  };
}
