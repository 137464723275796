import { HierarchyItem } from '../../common/components/filter/types';
import { SqlQueryDataJson } from '../types';

export type CustomSqlQueryValueBackendType =
  | { __typename?: 'BigDecimalValue'; bigDecimal: any }
  | { __typename?: 'BooleanValue'; boolean: boolean }
  | { __typename?: 'Confidential'; reason: string | null }
  | { __typename?: 'DateValue'; date: string }
  | { __typename?: 'DoubleValue'; double: number }
  | { __typename?: 'IntValue'; int: number }
  | { __typename?: 'StringValue'; string: string }
  | { __typename?: 'TimestampValue'; timestamp: any }
  | { __typename?: 'JsonValue'; json: SqlQueryDataJson }
  | null;

export interface FilterDataManager {
  queryKey: ReadonlyArray<unknown>;
  fetch: () => Promise<CustomSqlQueryValueBackendType[][] | undefined>;
  process: (data: CustomSqlQueryValueBackendType[][] | undefined) => HierarchyItem[];
  count: (hierarchyItem: HierarchyItem[]) => number;
}

export enum EMPLOYMENT_TEMPORALITY {
  PRESENT = 'PRESENT',
  PAST = 'PAST',
}

export enum Ordering {
  GT = 1,
  LT = -1,
  EQ = 0,
}

export interface Ord<A> {
  compare(x: A, y: A): Ordering;
}
