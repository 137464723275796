import { MetricCategoryId, MetricGroupId } from '../../../graphql/generated/graphql-sdk';
import { Languages } from '../../constants';

export enum AliasTypes {
  DATAFIELD = 'datafield',
  METRIC_CATEGORY_ID = 'metricCategoryId',
  METRIC_GROUP_ID = 'metricGroupId',
}

export interface Alias {
  key: string;
  type: AliasTypes;
  translations: Array<{
    language: Languages;
    text: string;
  }>;
}

export type AliasForDataField = Alias;

export interface AliasForMetricGroupId extends Alias {
  metricGroupId: MetricGroupId;
}

export interface AliasForMetricCategoryId extends Alias {
  metricCategoryId: MetricCategoryId;
}
