import { action, computed, observable } from 'mobx';
import { DataFieldWithDataType } from '../../../common-types';
import { Dashboards, DataTypes, EmployeeDataFields } from '../constants/constants';
import {
  trackSettingCurrencyToggle,
  trackSettingLabelToggle,
  trackSettingPercentageToggle,
} from '../helpers/trackers/sidebarTracker';
import { localStore } from '../local-store';
import { SortType, SortTypes } from '../utilFunctions/sorters';

enum DashboardDisplaySettings {
  LABELS = 'labels',
  GRIDLINES = 'gridlines',
}
export class DashboardSettingsStore {
  @computed
  public get currencyDimension(): DataFieldWithDataType {
    return {
      dataType: DataTypes.EMPLOYEE,
      dataField: this.isLocalCurrency ? EmployeeDataFields.LOCAL_SALARY : EmployeeDataFields.BASE_SALARY,
    };
  }

  @observable
  public labels: boolean = false;

  @observable
  public labelData: any = {};

  @observable
  public percentage: boolean = false;

  @observable
  public gridlines: boolean = false;

  @observable
  public percentageData: any = {};

  @observable
  public sortOrderData: { [key: string]: SortTypes } = {};

  @observable
  public isLocalCurrency: boolean = false;

  @observable
  public showSparkChart: boolean = true;
  public constructor() {
    this.labels = localStore.get(DashboardDisplaySettings.LABELS)
      ? JSON.parse(localStore.get(DashboardDisplaySettings.LABELS) as string)
      : false;
    this.gridlines = localStore.get(DashboardDisplaySettings.GRIDLINES)
      ? JSON.parse(localStore.get(DashboardDisplaySettings.GRIDLINES) as string)
      : false;
    localStore.set(DashboardDisplaySettings.LABELS, JSON.stringify(this.labels));
    localStore.set(DashboardDisplaySettings.GRIDLINES, JSON.stringify(this.gridlines));
  }

  public getSortTypeForChartId = (chartId: string): SortTypes => {
    let sortType: any;
    if (!(chartId in this.sortOrderData)) {
      sortType = SortType.asc;
    } else {
      sortType = (this.sortOrderData[chartId] in SortType && SortType[this.sortOrderData[chartId]]) || SortType.asc;
    }
    return sortType as SortTypes;
  };

  @action
  public toggleLabel = () => {
    this.labels = !this.labels;
    localStore.set(DashboardDisplaySettings.LABELS, JSON.stringify(this.labels));
    this.labelData = this.toggleAllKeysForHashmapWithValue(this.labelData, this.labels);
    trackSettingLabelToggle();
  };

  @action
  public isPercentageEnabled = () => {
    const { pathname } = window.location;
    const allowedDashboard = [
      // 'dashboard/people-snapshot',
      // 'dashboard/joiners-leavers',
      'dashboard/evaluation',
      'dashboard/survey',
    ];
    const isAllowed = allowedDashboard.some((route) => pathname.includes(route));

    return isAllowed;
  };

  @action
  public isLabelsDisabled = (dashboard: Dashboards | undefined) => {
    if (dashboard === Dashboards.PEOPLE_BALANCE_SHEET) return true;

    return false;
  };

  @action
  public isGridlinesDisabled = (dashboard: Dashboards | undefined) => {
    if (dashboard === Dashboards.PEOPLE_BALANCE_SHEET) return true;

    return false;
  };

  @action
  public toggleSparkChart = () => {
    this.showSparkChart = !this.showSparkChart;
  };

  @action
  public tooglePercentage = () => {
    this.percentage = !this.percentage;
    this.percentageData = this.toggleAllKeysForHashmapWithValue(this.percentageData, this.percentage);
    trackSettingPercentageToggle();
  };

  @action
  public toggleGridlines = () => {
    this.gridlines = !this.gridlines;
    localStore.set(DashboardDisplaySettings.GRIDLINES, JSON.stringify(this.gridlines));
  };

  @action
  public setLabelsData = (data: any) => {
    this.labelData = data;
  };

  @action
  public setPercentageData = (data: any) => {
    this.percentageData = data;
  };

  @action
  public setSortOrderData = (data: any) => {
    this.sortOrderData = data;
  };

  @action
  public toggleisLocalCurrency() {
    this.isLocalCurrency = !this.isLocalCurrency;

    trackSettingCurrencyToggle();
  }

  @action
  public resetDashboardSettings = () => {
    this.labels = false;
    this.percentage = false;
  };

  public showLabelsForChart = (chartId: string) => Boolean(this.labels || this.labelData[chartId]);

  private toggleAllKeysForHashmapWithValue = (hashmap: { [key: string]: any }, value: any) => {
    return Object.keys(hashmap).reduce((acc, key) => {
      return { ...acc, [key]: value };
    }, {});
  };
}
