import { List } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import NavBarItem, { NavBarItemObj } from './NavBarItem';

interface NavTraySectionProps {
  loadFn?: () => void;
  toggleMenu: () => void;
  propertyBase?: string;
  color: string;
  subItems?: NavBarItemObj[];
  name: string;
  backgroundColor?: string;
  openByDefault?: boolean;
  onClick?: () => void;
  className?: string;
  icon?: any;
}

const StyledList = styled(List as any)`
  padding: 0px !important;
  background-color: ${(props: any) => props.backgroundColor};
` as typeof List;

const NavTraySection = observer((props: NavTraySectionProps) => {
  const { toggleMenu, subItems, name, color, openByDefault, onClick, className, icon } = props;
  return (
    <StyledList style={{ background: color, color: '#ffffff' }} onClick={onClick}>
      <NavBarItem
        className={className}
        toggleMenu={toggleMenu}
        key={name}
        name={name}
        subItems={subItems}
        root
        level={0}
        color={color}
        openByDefault={openByDefault}
        icon={icon}
      />
    </StyledList>
  );
});

export default NavTraySection;
