import { baseAuthedTracker } from './baseTracker';

const TIME_SLIDER_OPENED_EVENT = '[Time Slider] Opened';
const TIME_SLIDER_CLOSED_EVENT = '[Time Slider] Closed';
const TIME_SLIDER_CHANGED_EVENT = '[Time Slider] Changed';
const TIME_SLIDER_VALUE_APPLIED_EVENT = '[Time Slider] Value Applied';

/**
 * Call this when user open TimeSlider
 */
export const trackTimeSliderOpen = (actionData: any = {}) => {
  baseAuthedTracker(TIME_SLIDER_OPENED_EVENT, actionData);
};

/**
 * Call this when user hide TimeSlider
 */
export const trackTimeSliderClose = (actionData: any = {}) => {
  baseAuthedTracker(TIME_SLIDER_CLOSED_EVENT, actionData);
};

/**
 * Call this when user hide TimeSlider
 */
export const trackTimeSliderChange = (actionData: any = {}) => {
  baseAuthedTracker(TIME_SLIDER_CHANGED_EVENT, {
    ...actionData,
  });
};

/**
 * Call this when user hide TimeSlider
 */
export const trackTimeSliderValueApply = (timeSliderValues: any, actionData: any = {}) => {
  baseAuthedTracker(TIME_SLIDER_VALUE_APPLIED_EVENT, {
    ...actionData,
    timeSliderValues,
  });
};
