export enum SortTypes {
  ASC = 'asc',
  DESC = 'desc',
  HIGH = 'high',
  LOW = 'low',
}

export enum GenderValues {
  Male = 'Male',
  Female = 'Female',
}

export enum RegretNonRegretValues {
  Regret = 'Regret',
  NonRegret = 'Non-Regret',
}

export enum VoluntaryInvoluntaryValues {
  Voluntary = 'Voluntary',
  Involuntary = 'Involuntary',
}

export enum ManagerOrIcValues {
  Manager = 'MANAGER',
  Ic = 'IC',
  EO = 'EO',
}

export enum RegionalOrLocalValues {
  Regional = 'Regional',
  Local = 'Local',
}

export enum LocalOrNonLocalValues {
  Local = 'Local',
  NonLocal = 'Non-Local',
}

export enum MartialStatusValues {
  Married = 'Married',
  Single = 'Single',
}

export enum ApplicationStatusValues {
  Active = 'ACTIVE',
  Rejected = 'REJECTED',
  Hired = 'HIRED',
}

export enum RejectionReasonValues {
  Rejected = 'Rejected',
  Withdrawn = 'Withdrawn',
}

export enum ApplicationCurrentStateValues {
  Applied = 'Applied',
  Offered = 'Offered',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Screening = 'Screening',
  Joined = 'Joined',
  Interview = 'Interview',
  FirstInterview = '1st Interview',
  FirstInterviewPassed = '1st Interview Passed',
  FirstInterviewFailed = '1st Interview Failed',
  SecondInterview = '2nd Interview',
  SecondInterviewPassed = '2nd Interview Passed',
  SecondInterviewFailed = '2nd Interview Failed',
  ThirdInterview = '3rd Interview',
  ThirdInterviewPassed = '3rd Interview Passed',
  ThirdInterviewFailed = '3rd Interview Failed',
  FourthInterview = '4th Interview',
  FourthInterviewPassed = '4th Interview Passed',
  FourthInterviewFailed = '4th Interview Failed',
  FifthInterview = '5th Interview',
  FifthInterviewPassed = '5th Interview Passed',
  FifthInterviewFailed = '5th Interview Failed',
  CeoInterview = 'CEO Interview',
  CeoInterviewPassed = 'CEO Interview Passed',
  CeoInterviewFailed = 'CEO Interview Failed',
  FinalInterview = 'Final Interview',
  FinalInterviewPassed = 'Final Interview Passed',
  FinalInterviewFailed = 'Final Interview Failed',
}

export enum HireStatusValues {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  ACCEPTED = 'ACCEPTED',
  Rejcted = 'Rejcted',
  UNRESOLVED = 'UNRESOLVED',
  DEPRECATED = 'DEPRECATED',
}

export enum JobStatusValues {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}

export interface ManualDataRefetchCountHandle {
  current: number;
  increment: () => void;
}
