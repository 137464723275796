// This file is used for fixed values of fields, for eg - 'Male' and 'Female' in gender

export enum GenderValues {
  Male = 'Male',
  Female = 'Female',
}

export enum RegretNonRegretValues {
  Regret = 'Regret',
  NonRegret = 'Non-Regret',
}

export enum VoluntaryInvoluntaryValues {
  Voluntary = 'Voluntary',
  Involuntary = 'Involuntary',
}

export enum ManagerOrIcValues {
  Manager = 'MANAGER',
  Ic = 'IC',
  EO = 'EO',
}

export enum RegionalOrLocalValues {
  Regional = 'Regional',
  Local = 'Local',
}

export enum LocalOrNonLocalValues {
  Local = 'Local',
  NonLocal = 'Non-Local',
}

export enum MartialStatusValues {
  Married = 'Married',
  Single = 'Single',
}

export enum ApplicationStatusValues {
  Active = 'ACTIVE',
  Rejected = 'REJECTED',
  Hired = 'HIRED',
}

export enum RejectionReasonValues {
  Rejected = 'Rejected',
  Withdrawn = 'Withdrawn',
}
