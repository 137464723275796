import { action, observable } from 'mobx';
import React from 'react';
import { toAdvancedDimension } from '../../../../common-types';
import i18n from '../../../../i18n';
import {
  ApiDataQueryType,
  ApiDataViewQueryWithTypeInfo,
  ApiMasterDataAdvancedQuery,
} from '../../../common/api/api-interfaces';
import { CompanySettingsStore } from '../../../common/company-settings-store';
import {
  Dashboards,
  DataTypes,
  EmployeeDataFields,
  LeaverDateNormalizedField,
  Operations,
} from '../../../common/constants/constants';
import { Granularity } from '../../../common/date-manager/date-manager-constants';
import { dateManagerService } from '../../../common/date-manager/date-manager-service';
import { getVersionFilterItems } from '../../../common/filter/common-filters';
import {
  combineFilters,
  convertFilterItemsToJoinersViewDataType,
  getApplicableVersionForDate,
  getPeriodStartDateFromStartDate,
  getVersionFilterFromDate,
} from '../../../common/filter/utils';
import { ReportStore } from '../../../common/report/report-store';
import { TimeSliderStore } from '../../../common/timeslider/timeslider-store';
import { Filter } from '../../../common/v2/common/components/filter/filterbar/types';
import { ApiMasterDataQueryFilterItem } from '../../../common/v2/types';
import { getAttritionTranslationKey } from '../dashboard-custom-translations';
import { localStore } from './../../../common/local-store';
import { attritionSecondCheckboxConfigs } from './constants';
import { AttritionSecondCheckboxConfig, AttritionSecondCheckboxField, AttritionTypes, attritionTypes } from './types';

const { TOTAL, THREE_MONTH, ONE_YEAR, THREE_YEAR, REGRET, NON_REGRET, VOLUNTARY, INVOLUNTARY } = attritionTypes;
export const attritionTypeToDisplayLabelMap: Record<string, string> = {
  [THREE_MONTH]: 'pages:dashboards.attrition.dashboard.3Month',
  [ONE_YEAR]: 'pages:dashboards.attrition.dashboard.1Year',
  [THREE_YEAR]: 'pages:dashboards.attrition.dashboard.3Year',
  [TOTAL]: 'pages:dashboards.attrition.dashboard.total',
  [REGRET]: 'pages:dashboards.attrition.dashboard.radioButtonRegret',
  [NON_REGRET]: 'pages:dashboards.attrition.dashboard.radioButtonNonRegret',
  [VOLUNTARY]: 'pages:dashboards.attrition.dashboard.radioButtonVoluntary',
  [INVOLUNTARY]: 'pages:dashboards.attrition.dashboard.radioButtonInvoluntary',
};

export enum AttritionRadioValues {
  ANNUALISED_ATTRITION = 'annualisedAttrition',
  MONTHLY_ATTRITION = 'monthlyAttrition',
  TTM_ATTRITION = 'trailingTwelveMonthsAttrition',
  LEAVERS = 'leavers',
  JOINERS = 'joiners',
  LAST_HEADCOUNT = 'lastHeadcount',
}

export class AttritionStore {
  public attritionOverviewId = 'attrition-1';
  public attrition2Id = 'attrition-2';

  @observable
  public primaryAttritionFilter: ApiMasterDataQueryFilterItem = {
    operation: Operations.LESS_THAN_OR_EQUAL_TO,
    property: 'DAYS_IN_COMPANY',
    values: ['Total'],
    dataType: DataTypes.EMPLOYEE,
  };

  @observable
  public secondaryAttritionFilter: ApiMasterDataQueryFilterItem | null = null;

  @observable
  public primaryAttritionRadioValue: string = localStore.get('primaryAttritionRadioValue') ?? 'annualisedAttrition';

  @observable
  public secondaryAttritionRadioValue: string = 'Total';

  private companySettingsStore: CompanySettingsStore;
  private timeSliderStore: TimeSliderStore;
  private reportStore: ReportStore;

  constructor(companySettingsStore: CompanySettingsStore, timeSliderStore: TimeSliderStore, reportStore: ReportStore) {
    this.companySettingsStore = companySettingsStore;
    this.timeSliderStore = timeSliderStore;
    this.reportStore = reportStore;
  }

  public attritionTenureFilters: ApiMasterDataQueryFilterItem[] = [
    {
      operation: Operations.LESS_THAN_OR_EQUAL_TO,
      property: 'DAYS_IN_COMPANY',
      values: ['Total'],
      dataType: DataTypes.EMPLOYEE,
    },
    {
      operation: Operations.LESS_THAN_OR_EQUAL_TO,
      property: 'DAYS_IN_COMPANY',
      values: [90],
      dataType: DataTypes.EMPLOYEE,
    },
    {
      operation: Operations.LESS_THAN_OR_EQUAL_TO,
      property: 'DAYS_IN_COMPANY',
      values: [365],
      dataType: DataTypes.EMPLOYEE,
    },
    {
      operation: Operations.LESS_THAN_OR_EQUAL_TO,
      property: 'DAYS_IN_COMPANY',
      values: [3 * 365],
      dataType: DataTypes.EMPLOYEE,
    },
  ];

  public attritionValueMap: () => Record<string, string> = () => {
    return {
      [AttritionTypes.ANNUALISED_ATTRITION]: i18n.t(
        getAttritionTranslationKey('common:commonValues.measures.annualisedAttrition')
      ),
      [AttritionTypes.MONTHLY_ATTRITION]: i18n.t(
        getAttritionTranslationKey('common:commonValues.measures.monthlyAttrition')
      ),
      [AttritionTypes.TTM_ATTRITION]: i18n.t(
        getAttritionTranslationKey('common:commonValues.measures.trailingTwelveMonthsAttrition')
      ), // [AttritionTypes.STARTING_POP_ATTRITION]: i18n.t('common:commonValues.measures.startingPopAttrition'),
      leavers: i18n.t('common:commonValues.measures.leavers'),
      joiners: i18n.t('common:commonValues.measures.joiners'),
      lastHeadcount: i18n.t('common:commonValues.measures.headcount'),
    };
  };

  private getAttritionSecondCheckboxFilters = (
    dataField: AttritionSecondCheckboxField
  ): ApiMasterDataQueryFilterItem[] => {
    const getFilterForVal = (val: string): ApiMasterDataQueryFilterItem => {
      return { operation: Operations.EQUAL, property: dataField, values: [val], dataType: DataTypes.EMPLOYEE };
    };
    const config = attritionSecondCheckboxConfigs[dataField];
    return config.values.map((v) => getFilterForVal(v.value));
  };

  public attritionSecondCheckboxConfig = (): AttritionSecondCheckboxConfig | null => {
    const field = this.companySettingsStore.attritionSecondCheckboxField();
    return field ? attritionSecondCheckboxConfigs[field] : null;
  };

  public attritionSecondCheckboxFilters = (): ApiMasterDataQueryFilterItem[] => {
    const field = this.companySettingsStore.attritionSecondCheckboxField();
    return field
      ? this.getAttritionSecondCheckboxFilters(field)
      : this.getAttritionSecondCheckboxFilters(EmployeeDataFields.REGRET_ATTRITION);
    // I don't wana return null for this value as a lot of code expects this to return
    // with valid filters so returning regret attrition filters even if no config present
  };

  public primaryAttritionFilters = () => [...this.attritionTenureFilters, ...this.attritionSecondCheckboxFilters()];

  @action
  public handlePrimaryAttritionRadioValue = (value: string) => {
    this.primaryAttritionRadioValue = value;
    localStore.set('primaryAttritionRadioValue', value);
    this.secondaryAttritionRadioValue = 'Total';
  };

  @action
  public handleSecondaryAttritionRadioValue = (_e: React.ChangeEvent<{}>, value: string) => {
    this.secondaryAttritionRadioValue = value;
    if (this.primaryAttritionFilter.property === 'DAYS_IN_COMPANY') {
      const filter = this.attritionSecondCheckboxFilters().find((f: ApiMasterDataQueryFilterItem) =>
        f.values.includes(value)
      );
      if (filter) {
        this.secondaryAttritionFilter = filter;
      }
    } else {
      const filter = this.attritionTenureFilters.find((f: ApiMasterDataQueryFilterItem) =>
        f.values.find((v) => String(v) === String(value))
      );
      if (filter) {
        this.secondaryAttritionFilter = filter;
      }
    }
  };

  @action
  public setPrimaryAttritionQualifier = (newValue: any) => (this.primaryAttritionFilter = newValue);

  @action
  public setSecondaryAttritionQualifier = (newValue: any) => (this.secondaryAttritionFilter = newValue);

  public getJoinerFilters: (periodStartDate: string, periodEndDate: string) => ApiMasterDataQueryFilterItem[] = (
    periodStartDate: string,
    periodEndDate: string
  ) => {
    const joinerFilters: ApiMasterDataQueryFilterItem[] = [
      {
        operation: Operations.GREATER_THAN_OR_EQUAL_TO,
        property: 'START_DATE_NORMALIZED',
        values: [periodStartDate],
        dataType: DataTypes.JOINERS_VIEW,
      },
      {
        operation: Operations.LESS_THAN_OR_EQUAL_TO,
        property: 'START_DATE_NORMALIZED',
        values: [periodEndDate],
        dataType: DataTypes.JOINERS_VIEW,
      },
      {
        operation: Operations.EQUAL,
        property: 'EMPLOYMENT_TEMPORALITY',
        values: ['PRESENT', 'PAST'],
        dataType: DataTypes.JOINERS_VIEW,
      },
    ];
    return joinerFilters;
  };

  public getBaseLeaverFilters = (
    periodStartDate: string,
    periodEndDate: string,
    leaverDateNormalizedField: LeaverDateNormalizedField
  ) => {
    const leaverFilters: ApiMasterDataQueryFilterItem[] = [
      {
        operation: Operations.GREATER_THAN_OR_EQUAL_TO,
        property: leaverDateNormalizedField.dataField,
        values: [periodStartDate],
        dataType: leaverDateNormalizedField.dataType,
      },
      {
        operation: Operations.LESS_THAN_OR_EQUAL_TO,
        property: leaverDateNormalizedField.dataField,
        values: [periodEndDate],
        dataType: leaverDateNormalizedField.dataType,
      },
      {
        operation: Operations.EQUAL,
        property: 'EMPLOYMENT_TEMPORALITY',
        values: ['PRESENT', 'PAST'],
        dataType: DataTypes.EMPLOYEE,
      },
    ];
    return leaverFilters;
  };

  public getLeaverFilters = (
    periodStartDate: string,
    periodEndDate: string,
    primaryAttritionFilter: ApiMasterDataQueryFilterItem,
    secondaryAttritionFilter: ApiMasterDataQueryFilterItem,
    leaverDateNormalizedField: LeaverDateNormalizedField
  ) => {
    const leaverFilters: ApiMasterDataQueryFilterItem[] = this.getBaseLeaverFilters(
      periodStartDate,
      periodEndDate,
      leaverDateNormalizedField
    );

    if (primaryAttritionFilter.values[0] !== 'Total') {
      leaverFilters.push(primaryAttritionFilter);
    }
    if (secondaryAttritionFilter.values[0] !== 'Total') {
      leaverFilters.push(secondaryAttritionFilter);
    }
    return leaverFilters;
  };

  private leaversDataViewFilterItems = (filters: Filter[]): ApiMasterDataQueryFilterItem[] => {
    const { startDate, endDate, granularity, firstMonthOfYear } = this.timeSliderStore.timeSliderConfig;
    const { leaverDateNormalizedField } = this.companySettingsStore;
    const { parseApiDate } = dateManagerService;
    const version = getApplicableVersionForDate(parseApiDate(endDate), firstMonthOfYear);
    const periodStartDate = getPeriodStartDateFromStartDate(parseApiDate(startDate), granularity, firstMonthOfYear);
    const leaverFilterItems: ApiMasterDataQueryFilterItem[] = [
      ...this.getLeaverFilters(
        periodStartDate,
        endDate,
        this.primaryAttritionFilter,
        this.secondaryAttritionFilter as ApiMasterDataQueryFilterItem,
        leaverDateNormalizedField()
      ),
      getVersionFilterFromDate(parseApiDate(version), firstMonthOfYear),
    ];

    return [...combineFilters(filters), ...leaverFilterItems];
  };

  private leaversTtmDataViewFilterItems = (filters: Filter[]): ApiMasterDataQueryFilterItem[] => {
    const { endDate, firstMonthOfYear } = this.timeSliderStore.timeSliderConfig;
    const { parseApiDate, formatDateApi } = dateManagerService;
    const { leaverDateNormalizedField } = this.companySettingsStore;
    const version = getApplicableVersionForDate(parseApiDate(endDate), firstMonthOfYear);
    const twelveMonthStartDate = formatDateApi(
      parseApiDate(endDate).subtract(11, Granularity.MONTH).startOf(Granularity.MONTH)
    );
    const leaverFilterItems: ApiMasterDataQueryFilterItem[] = [
      ...this.getLeaverFilters(
        twelveMonthStartDate,
        endDate,
        this.primaryAttritionFilter,
        this.secondaryAttritionFilter as ApiMasterDataQueryFilterItem,
        leaverDateNormalizedField()
      ),
      getVersionFilterFromDate(parseApiDate(version), firstMonthOfYear),
    ];
    return [...combineFilters(filters), ...leaverFilterItems];
  };

  private leaversStartingPopFilterItems = (filters: Filter[]): ApiMasterDataQueryFilterItem[] => {
    const { startDate, granularity, firstMonthOfYear } = this.timeSliderStore.timeSliderConfig;
    const { parseApiDate } = dateManagerService;
    const periodStartDate = getPeriodStartDateFromStartDate(parseApiDate(startDate), granularity, firstMonthOfYear);
    const startDateFilter = {
      dataType: DataTypes.EMPLOYEE,
      operation: Operations.GREATER_THAN_OR_EQUAL_TO,
      property: EmployeeDataFields.START_DATE_NORMALIZED,
      values: [periodStartDate],
    };
    return [...this.leaversDataViewFilterItems(filters), startDateFilter];
  };

  private getJoinersDataViewQuery = (dashboard: Dashboards, filters: Filter[]): ApiMasterDataAdvancedQuery => {
    const { parseApiDate } = dateManagerService;
    const { startDate, endDate, granularity, firstMonthOfYear } = this.timeSliderStore.timeSliderConfig;
    const version = getApplicableVersionForDate(parseApiDate(endDate), firstMonthOfYear);
    const periodStartDate = getPeriodStartDateFromStartDate(parseApiDate(startDate), granularity, firstMonthOfYear);
    const joinerFilterItems: ApiMasterDataQueryFilterItem[] = [...this.getJoinerFilters(periodStartDate, version)];
    const filterItems = [...combineFilters(convertFilterItemsToJoinersViewDataType(filters)), ...joinerFilterItems];
    const dataViewFields = this.reportStore.getDataViewFields(dashboard);
    const query: ApiMasterDataAdvancedQuery = {
      dataType: DataTypes.JOINERS_VIEW,
      dimensions: dataViewFields.map((dataViewField) => ({
        dataType: dataViewField.dataType === DataTypes.EMPLOYEE ? DataTypes.JOINERS_VIEW : dataViewField.dataType,
        property: dataViewField.dataField,
      })),
      filterItems,
    };
    return query;
  };

  private headcountDataViewFilterItems = (filters: Filter[]): ApiMasterDataQueryFilterItem[] => {
    const { endDate, firstMonthOfYear } = this.timeSliderStore.timeSliderConfig;
    const { parseApiDate } = dateManagerService;
    const version = getApplicableVersionForDate(parseApiDate(endDate), firstMonthOfYear);
    return [...combineFilters(filters), ...getVersionFilterItems(version)];
  };

  public getDataViewQuery = (dashboard: Dashboards, filters: Filter[]): ApiDataViewQueryWithTypeInfo => {
    const dataKey = this.primaryAttritionRadioValue;
    let query: ApiMasterDataAdvancedQuery;
    if (dataKey === 'joiners') {
      query = this.getJoinersDataViewQuery(dashboard, filters);
    } else {
      const dataKeyToDataViewQueryFilterItemsMap: Record<string, ApiMasterDataQueryFilterItem[]> = {
        [AttritionTypes.ANNUALISED_ATTRITION]: this.leaversDataViewFilterItems(filters),
        [AttritionTypes.MONTHLY_ATTRITION]: this.leaversDataViewFilterItems(filters),
        [AttritionTypes.TTM_ATTRITION]: this.leaversTtmDataViewFilterItems(filters),
        [AttritionTypes.STARTING_POP_ATTRITION]: this.leaversStartingPopFilterItems(filters),
        leavers: this.leaversDataViewFilterItems(filters),
        lastHeadcount: this.headcountDataViewFilterItems(filters),
      };
      const filterItems = dataKeyToDataViewQueryFilterItemsMap[dataKey];
      const dataViewFields = this.reportStore.getDataViewFields(dashboard);
      query = {
        dataType: DataTypes.EMPLOYEE,
        dimensions: dataViewFields.map((dataViewField) => toAdvancedDimension(dataViewField)),
        filterItems,
      };
    }
    return { type: ApiDataQueryType.ApiMasterDataAdvancedQuery, query };
  };

  public isAttritionRate = (dataKey: string) => {
    return (
      dataKey === AttritionTypes.ANNUALISED_ATTRITION ||
      dataKey === AttritionTypes.MONTHLY_ATTRITION ||
      dataKey === AttritionTypes.TTM_ATTRITION ||
      dataKey === AttritionTypes.STARTING_POP_ATTRITION
    );
  };
}
