import React from 'react';
import { ErrorBoundary } from './ErrorBoundary';
import { rootStore } from './common/store/root-store';

export const DetailedErrorBoundary = (props: { children?: React.ReactNode }) => {
  const { permissionsStore } = rootStore || {};
  const executorRole = permissionsStore.getExecutorRole();

  const tags: Record<string, string | null> = {
    'panalyt.executorRole.id': executorRole.id,
    'panalyt.executorRole.name': executorRole.name,
    'panalyt.executorRole.domain': executorRole.domain,
  };
  return <ErrorBoundary tags={tags}>{props.children}</ErrorBoundary>;
};
