import { action, observable } from 'mobx';
import { DataFieldWithDataType } from '../../../common-types';
import { CompanySettingsStore } from '../company-settings-store';
import { CompanyStore } from '../company/company-store';
import { Dashboards, DataTypes, Domains, EmployeeDataFields as EDF } from '../constants/constants';
import { EmployeeDataStore } from '../employee-data/employee-data-store';
import { GetUserVisibleNonConfidentialNonNullFieldsForDataTypeService } from '../services/GetNonNullFieldsForDataTypeService';
import { getDataViewFieldsFromSettings, isHiddenField } from './../company-data-view-settings';

enum DateRangeOptions {
  DEFAULT = 'default',
  DATESHORT = 'mmmYY',
}

export class ReportStore {
  @observable
  public reportFieldsOrder: DataFieldWithDataType[] = [];

  @observable
  public isTimeSliderUpdated = false;

  @observable
  public dataViewFields: { [key in Dashboards]?: Set<DataFieldWithDataType> } = {};

  @observable
  public selectedDateRange: Record<DateRangeOptions, boolean> = {
    [DateRangeOptions.DEFAULT]: true,
    [DateRangeOptions.DATESHORT]: false,
  };

  private companyStore: CompanyStore;
  private employeeDataStore: EmployeeDataStore;
  private companySettingsStore: CompanySettingsStore;

  constructor(
    companyStore: CompanyStore,
    employeeDataStore: EmployeeDataStore,
    companySettingsStore: CompanySettingsStore
  ) {
    this.companyStore = companyStore;
    this.employeeDataStore = employeeDataStore;
    this.companySettingsStore = companySettingsStore;
  }

  // Can be used to extend column selector with additional data types instead of only allowing to manipulate EMPLOYEE there
  public dataViewAdditionalDataTypesMap: Partial<Record<Dashboards, DataTypes[]>> = {
    [Dashboards.PAYROLL]: [DataTypes.PAYROLL],
    [Dashboards.SURVEY]: [DataTypes.QUESTIONANSWER],
  };

  private defaultFields = new Set([
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
  ]);

  @action
  public getSelectedDateRange = (): DateRangeOptions => {
    return Object.keys(this.selectedDateRange)
      .filter((k) => this.selectedDateRange[k as DateRangeOptions])
      .shift() as DateRangeOptions;
  };

  @action
  public setIsTimeSliderUpdated = (isTimeSliderUpdated: boolean) => {
    this.isTimeSliderUpdated = isTimeSliderUpdated;
  };

  @action
  public setInitialDataViewFields = async (dashboard: Dashboards) => {
    const fields = this.dataViewFields[dashboard];
    if (fields && fields.size > 0) {
      return;
    }
    const { domain } = this.companyStore;
    const { nonNullFields } = this.employeeDataStore;
    const clonedNonNullFields = [...nonNullFields];
    const additionalNonNullDataTypes = this.dataViewAdditionalDataTypesMap[dashboard];
    if (additionalNonNullDataTypes) {
      for await (const dataType of additionalNonNullDataTypes) {
        const dataFields = await GetUserVisibleNonConfidentialNonNullFieldsForDataTypeService(dataType);
        clonedNonNullFields.push(...dataFields);
      }
    }
    const isUsingEffectiveLeaverDate = this.companySettingsStore.isUsingEffectiveLeaverDate();

    const selectedFields: DataFieldWithDataType[] = getDataViewFieldsFromSettings({
      domain: domain as Domains,
      location: dashboard,
      defaultFields: this.defaultFields,
      nonNullFields: new Set(clonedNonNullFields) as Set<DataFieldWithDataType>,
      isJapaneseClient: this.companyStore.isJapaneseEnterpriseClient(),
      isUsingEffectiveLeaverDate,
    });

    this.dataViewFields[dashboard] = new Set(selectedFields);
  };

  @action
  public setDataViewFields = (dashboard: Dashboards, fields: DataFieldWithDataType[]) => {
    const { domain } = this.companyStore;
    this.dataViewFields[dashboard] = new Set(fields.filter((f) => !isHiddenField(f, domain as Domains)));
  };

  public getDataViewFields = (dashboard: Dashboards): DataFieldWithDataType[] =>
    Array.from(this.dataViewFields[dashboard] ?? this.defaultFields);
}
