import { TimeRange, TimeRangeType } from '../../../api/timeranges/types';
import { TimestampUTC } from '../../../types';

export interface MenuHandle {
  opened: boolean;
  anchorEl: ParentNode | null;
  open: () => void;
  close: () => void;
  setAnchorEl: (el: ParentNode | null) => void;
}

export enum Granularity { // These should be the same as moment's precision
  MONTH = 'month',
  YEAR = 'year',
  FINQUARTER = 'finquarter',
  FINYEAR = 'finYear',
}

export enum Months {
  January = 'January',
  February = 'February',
  March = 'March',
  April = 'April',
  May = 'May',
  June = 'June',
  July = 'July',
  August = 'August',
  September = 'September',
  October = 'October',
  November = 'November',
  December = 'December',
}

export enum Presets {
  LAST3MONTHS = 'last3Months',
  LAST6MONTHS = 'last6Months',
  LAST12MONTHS = 'last12Months',
  YTD = 'ytd',
  LASTYEAR = 'lastYear',
  FYTD = 'fytd', // Financial Year to Date
  LASTFINANCIALYEAR = 'lastFinancialYear',
  ALLTIME = 'allTime',
}

export interface TimeSliderGranularity {
  id: string;
  label: string;
  value: Granularity;
}

export interface TimeSliderPreset {
  id: Presets;
  label: string;
}

export interface GranularityInfo {
  defaultPreset: TimeSliderPreset;
  availablePresets: TimeSliderPreset[];
  granularity: TimeSliderGranularity;
}

// TODO: ideally we should use a type parameter to ensure that all time ranges are of the same underlying type (e.g. Date, number or Quarter)
export interface TimeSliderState {
  start: TimeRangeType;
  end: TimeRangeType;
  range: TimeRange;
  initialRange: TimeRange;
  selectedGranularity: TimeSliderGranularity;
  granularities: TimeSliderGranularity[];
  selectedPreset: TimeSliderPreset;
  availableTimeRanges: Record<Granularity, TimeRange>;
  initialState: Omit<TimeSliderState, 'initialState'>;
  granularityInfo: Record<Granularity, GranularityInfo>;
}

export interface TimeSliderStateWithPersistence extends TimeSliderState {
  persistenceKey: string;
}

type TimeSliderRangeActionType = 'update-range';
type TimeSliderGranularityActionType = 'update-granularity';
type TimeSliderResetActionType = 'reset';
type TimeSliderPresetActionType = 'update-preset';

export interface TimeSliderResetAction {
  type: TimeSliderResetActionType;
}

export interface TimeSliderPresetAction {
  type: TimeSliderPresetActionType;
  granularity: TimeSliderGranularity;
  preset: TimeSliderPreset;
  current: TimestampUTC;
}

export interface TimeSliderRangeAction {
  type: TimeSliderRangeActionType;
  start: TimeRangeType;
  end: TimeRangeType;
}

export interface TimeSliderGranularityAction {
  type: TimeSliderGranularityActionType;
  granularity: TimeSliderGranularity;
  current: TimestampUTC;
}

export type TimeSliderAction =
  | TimeSliderRangeAction
  | TimeSliderGranularityAction
  | TimeSliderResetAction
  | TimeSliderPresetAction;

export type TimeSliderHandle = [TimeSliderState, React.Dispatch<TimeSliderAction>];

export type TimeSliderReducer<S extends TimeSliderState, A extends TimeSliderAction> = (state: S, action: A) => S;
