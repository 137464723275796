import { GraphQLClient, RequestMiddleware, ResponseMiddleware } from 'graphql-request';
import { GraphQLClientResponse } from 'graphql-request/build/esm/types';
import { observable, when } from 'mobx';
import { match, P } from 'ts-pattern';
import { AuthService } from '../auth/auth-service';
import { AuthStore } from '../auth/auth-store';
import { environmentService } from '../environment/environment-service';
import { Sdk, getSdk } from '../graphql/generated/graphql-sdk';
import { trackAPIError, trackError } from '../sentry/sentry';

export class GraphQlRequestService {
  @observable
  public graphQlClient: GraphQLClient | null = null;

  @observable
  public graphQlClientBatching: GraphQLClient | null = null;

  @observable
  public graphQlClientIgnoringErrors: GraphQLClient | null = null;

  private authService: AuthService;

  constructor(authStore: AuthStore, authService: AuthService) {
    this.authService = authService;
    when(
      () => authStore.isLoggedIn,
      () => this.setup()
    );
  }

  // This should only be executed once the user is logged in successfully
  // Otherwise the bearer token will be undefined
  public setup = async () => {
    const url = environmentService.getGraphqlUrl();
    const requestMiddleware: RequestMiddleware = async (req) => {
      const token = await this.authService.getAccessToken();
      if (token === undefined) {
        throw new Error('Bearer token undefined when trying to make graphql request.');
      }
      req.headers = {
        ...req.headers,
        authorization: `Bearer ${token}`,
      };
      return req;
    };
    const responseMiddleware: ResponseMiddleware = (resOrError: GraphQLClientResponse<unknown> | Error): void => {
      match(resOrError)
        .with({ name: P.string, message: P.string }, (e: Error) => {
          trackError(`Error(s) when executing graphql query`, { errors: [e.message] });
        })
        .with({ status: P.number }, (r: GraphQLClientResponse<unknown>) => {
          if (r.status >= 400) {
            if (r.errors && r.errors.length === 1) {
              const error = r.errors[0];
              const errorMessage = error.message;
              const errorCode = error.extensions.errorCode;
              const errorReference = error.extensions.errorReference;
              trackAPIError(Error(errorMessage), {}, { statusCode: r.status, errorCode, errorReference });
            } else {
              const errorMessages = r.errors?.map((e) => e.message);
              const errorCodes = r.errors?.map((e) => e.extensions.errorCode);
              const errorReferences = r.errors?.map((e) => e.extensions.errorReference);
              trackAPIError(Error(errorMessages?.join(',')), {}, { statusCode: r.status, errorCodes, errorReferences });
            }
          } else if (r.errors) {
            const errorMessages = r.errors.map((e) => e.message);
            const errorCodes = r.errors.map((e) => e.extensions.errorCode);
            const errorReferences = r.errors.map((e) => e.extensions.errorReference);
            trackError(errorMessages?.join(','), {}, { errorCodes, errorReferences });
          }
        })
        .otherwise(() => {
          // Do nothing the response is fine
        });
    };
    const graphQLClient = new GraphQLClient(url, {
      headers: {
        'X-PANALYT-CSRF-PROTECTION': environmentService.getCSRFProtectionToken(),
      },
      requestMiddleware,
      responseMiddleware,
    });
    this.graphQlClient = graphQLClient;

    const graphQLClientBatching = new GraphQLClient(url + '?batching=true', {
      headers: {
        'X-PANALYT-CSRF-PROTECTION': environmentService.getCSRFProtectionToken(),
      },
      requestMiddleware,
      responseMiddleware,
    });
    this.graphQlClientBatching = graphQLClientBatching;
    const graphQLClientIgnoringErrors = new GraphQLClient(url, {
      headers: {
        'X-PANALYT-CSRF-PROTECTION': environmentService.getCSRFProtectionToken(),
      },
      requestMiddleware,
      responseMiddleware,
      errorPolicy: 'ignore',
    });
    this.graphQlClientIgnoringErrors = graphQLClientIgnoringErrors;
  };

  public get graphQlSdk(): Sdk {
    if (!this.graphQlClient) {
      throw new Error('GraphQl client not initialized');
    }
    return getSdk(this.graphQlClient);
  }

  // Use this one if you are okay with receiving only partial results for a query
  public get graphQlSdkIgnoringErrors(): Sdk {
    if (!this.graphQlClientIgnoringErrors) {
      throw new Error('GraphQlIgnoringErrors client not initialized');
    }
    return getSdk(this.graphQlClientIgnoringErrors);
  }
}
