import { availableLanguagesToIso6291Map, languages } from './constants';
import { LanguageObject } from './types';

export interface LanguageService {
  getSelectedLanguage(): LanguageObject;
}

export class LocalStorageLanguageService implements LanguageService {
  public getSelectedLanguage = (): LanguageObject => {
    const stored: string | null = localStorage.getItem('i18nextLng');
    const languageId = stored ? availableLanguagesToIso6291Map[stored] : languages[0].id; // TODO: double check if that's a good default
    return languages.find((lng) => lng.id === languageId) ?? languages[0];
  };
}
