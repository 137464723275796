import { ApiValueDataType } from '../types';
import { toValue } from '../utils';
import { Employee } from './types';

export const showEmployee = (emp: Employee) =>
  `${emp.localFullName ?? emp.fullName ?? emp.id}${emp.jobTitle ? ` - ${emp.jobTitle}` : ''}`;

export const employeeTypeDataMapper = (data: ApiValueDataType[][] | null): Employee[] => {
  return (
    data?.map((employee) => ({
      id: toValue(employee[0]) as string,
      companyEmail: toValue(employee[1]) as string,
      firstName: toValue(employee[2]) as string,
      lastName: toValue(employee[3]) as string,
      fullName: toValue(employee[4]) as string,
      localFullName: toValue(employee[5]) as string,
      localFirstName: toValue(employee[6]) as string,
      localLastName: toValue(employee[7]) as string,
      preferredName: toValue(employee[8]) as string,
      localPreferredName: toValue(employee[9]) as string,
      jobTitle: toValue(employee[10]) as string,
    })) ?? []
  );
};
