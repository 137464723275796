import { RequestService } from '../api/request-service';
import { CompanyStore } from '../company/company-store';
import { Domains } from '../constants/constants';
import { environmentService } from '../environment/environment-service';
import { AuthService } from './auth-service';

export class ForgotPasswordService {
  private companyStore: CompanyStore;
  private requestService: RequestService;
  private authService: AuthService;

  constructor(companyStore: CompanyStore, requestService: RequestService, authService: AuthService) {
    this.companyStore = companyStore;
    this.requestService = requestService;
    this.authService = authService;
  }

  public async sendForgotPasswordEmail(email: string) {
    const url = environmentService.formatUrlNewBackendPublic('send-reset-password-mail');
    url.searchParams.set('email', email);
    return this.requestService.post(url.toString(), this.companyStore.domain as Domains, '', {}); // TODO provide unauthenticated requests
  }
}
