import faviconURL from '../../assets/image/favicon.png';
import qaFaviconURL from '../../assets/image/panalytQA.png';
import { environmentService } from '../common/environment/environment-service';
import { Environments } from '../common/environment/environments';

declare global {
  interface Console {
    log2: typeof console.log;
  }
}

export const bootstrapApp = () => {
  // Proably not the best place to keep the favicons
  // Don't know where else would be the best place to add this piece of code to update favicons.
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  const env = environmentService.getEnvironment();

  if (favicon) {
    if (env.name === Environments.PANACLOUD_QA) {
      favicon.href = qaFaviconURL;
    } else {
      favicon.href = faviconURL;
    }
  }

  console.log2 = function (...args: any[]) {
    try {
      console.log(...JSON.parse(JSON.stringify(args)));
    } catch (e) {
      // sometimes there could be errors if the items in args[] are not serializable in JSON
      console.log(...args);
    }
  };

  if (!environmentService.isLocalhost) {
    console.log = () => null;
    console.log2 = () => null;
  }
};
