import mixpanel from 'mixpanel-browser';
import { environmentService } from '../environment/environment-service';

mixpanel.init(environmentService.environment.mixpanelToken, { api_host: 'https://panacloud-proxymixp.panalyt.com' });

const actions = {
  identify: (id: string) => {
    if (environmentService.isProd) {
      mixpanel.identify(id);
    }
  },
  track: (name: string, props: Record<string, any>) => {
    if (environmentService.isProd) {
      mixpanel.track(name, props);
    }
  },
  register: (props: Record<string, any>) => {
    if (environmentService.isProd) {
      mixpanel.register(props);
    }
  },
  people: () => mixpanel.people,
};

export const Mixpanel = actions;
