import { queryClient } from '../../app';
import { ApiMasterDataAdvancedQuery, ApiMasterDataQuery } from '../api/api-interfaces';
import { DataFields, DataTypes, EmployeeDataFields, Operations } from '../constants/constants';
import { Granularity } from '../date-manager/date-manager-constants';
import { COUNT_MEASURE, getVersionFilter, PRESENT_TEMPORALITY_FILTER } from '../filter/common-filters';
import { TimeSliderConfig } from '../filter/filter-store';
import { VersionId } from '../v2/api/types';
import { ServiceInputs } from './ByDimensionService';
import { DataMapAndLabelsWithForecast } from './getForecastResult';
import { ApiMasterDataAdvancedQuery as V2ApiMasterDataAdvancedQuery, ApiMasterDataQueryFilterItem } from '../v2/types';

export interface Service<Inputs, Response> {
  (
    inputs: Inputs,
    filters: ApiMasterDataQueryFilterItem[],
    latestVersions: Partial<Record<DataTypes, VersionId>>
  ): Promise<Response>;
  cacheKey: string;
}

export const getServiceCacheKey = (service: Service<any, any>, inputs: any, filters: ApiMasterDataQueryFilterItem[]) =>
  JSON.stringify([inputs, filters, service.toString(), service.cacheKey]);

interface OverTimeChartDatamapsAndLabels {
  primaryDataMap: Record<string, any>;
  primaryDates: string[];
  benchmarkDataMap: Record<string, any> | null;
  benchmarkDates: string[];
  forecastDataMap?: DataMap;
}

export type DataMap<DataShape = any> = Record<string, DataShape>;
export interface DataMapAndLabels<DataShape = any> {
  labels: string[];
  dataMap: DataMap<DataShape>;
}

interface OvertimeServiceResponseDataObj extends DataMapAndLabelsWithForecast {
  inputs: { timeSliderConfig: TimeSliderConfig; [key: string]: any };
  // TODO: Ideally this should be set up as a generic which accepts inputProps
  // But it is too much work and refactor to do this correctly right now
}

export interface OvertimeServiceResponseData {
  primaryResponse: OvertimeServiceResponseDataObj;
  benchmarkResponse?: OvertimeServiceResponseDataObj;
  granularity: Granularity;
}

export const getDataMapsAndLabelsForOverTimeChart = (
  data: OvertimeServiceResponseData
): OverTimeChartDatamapsAndLabels => {
  const { primaryResponse, benchmarkResponse } = data;
  let { dataMap: primaryDataMap, labels: primaryDates, forecastDataMap } = primaryResponse;
  let benchmarkDataMap: Record<string, any> | null = null;
  let benchmarkDates: string[] = [];
  if (benchmarkResponse) {
    benchmarkDataMap = benchmarkResponse.dataMap;
    benchmarkDates = benchmarkResponse.labels;
  }
  return { primaryDates, primaryDataMap, benchmarkDates, benchmarkDataMap, forecastDataMap };
};

export const getBenchmarkWrapperServiceInputs = <Inputs, Response>(
  service: V2Service<Inputs, Response, ApiMasterDataQueryFilterItem>
) => ({
  service,
  serviceCacheKey: service.cacheKey,
});

export interface V2Service<Inputs, Response, T extends ApiMasterDataQueryFilterItem> {
  (inputs: Inputs, filters: T[], latestVersions: Partial<Record<DataTypes, VersionId>>): Promise<Response>;
  cacheKey: string;
}

export const getReactQueryWrappedService = <Inputs, Response, T extends ApiMasterDataQueryFilterItem>(
  service: V2Service<Inputs, Response, T>
): V2Service<Inputs, Response, T> => {
  const reactQueryWrappedService: V2Service<Inputs, Response, T> = async (inputs, filters, latestVersions) => {
    return queryClient.fetchQuery({
      queryKey: [inputs, filters, service.cacheKey],
      queryFn: () => service(inputs, filters, latestVersions),
    });
  };
  reactQueryWrappedService.cacheKey = service.cacheKey;
  return reactQueryWrappedService;
};

export const runServiceForDates = async (
  sDate: string,
  eDate: string,
  serviceInputs: ServiceInputs,
  timeSliderConfig: TimeSliderConfig,
  latestVersions: Partial<Record<DataTypes, VersionId>>
) => {
  return serviceInputs.service(
    {
      ...serviceInputs.inputs,
      timeSliderConfig: {
        ...timeSliderConfig,
        startDate: sDate,
        endDate: eDate,
      },
      date: eDate,
      forecastConfig: undefined,
    },
    serviceInputs.filters,
    latestVersions
  );
};

const dataTypesRequiringVersionId = [
  DataTypes.EMPLOYEE,
  DataTypes.JOB,
  DataTypes.APPLICATION,
  DataTypes.OFFER,
  DataTypes.INTERVIEW,
  DataTypes.RECRUITERJOBS,
  DataTypes.TIMEANDATTENDANCEMONTHLY,
  DataTypes.PAYROLL,
  DataTypes.MANAGERENPS,
];

export const oldQueryToAdvancedQuery = (
  query: ApiMasterDataQuery,
  latestVersionId?: VersionId
): ApiMasterDataAdvancedQuery => {
  const finalDimensions =
    query.dataType === DataTypes.EMPLOYEE && !query.dimensions?.includes(EmployeeDataFields.VERSION_ID)
      ? [...(query.dimensions ?? []), EmployeeDataFields.VERSION_ID]
      : query.dimensions;

  const finalMeasures =
    query.dataType === DataTypes.EMPLOYEE &&
    !query.measures?.find(
      (m) =>
        m.dataType === DataTypes.EMPLOYEE &&
        m.property === EmployeeDataFields.EMPLOYEE_ID &&
        m.operation === Operations.COUNT
    )
      ? [...(query.measures ?? []), COUNT_MEASURE]
      : query.measures;

  const versionIdFilter =
    dataTypesRequiringVersionId.includes(query.dataType as DataTypes) &&
    !query.filterItems?.find(
      (fi) =>
        fi.dataType === DataTypes.EMPLOYEE &&
        fi.property === EmployeeDataFields.VERSION_ID &&
        fi.operation === Operations.EQUAL
    ) &&
    !query.dimensions?.includes(EmployeeDataFields.VERSION_ID)
      ? latestVersionId
        ? [getVersionFilter(latestVersionId)]
        : [] // TODO: should we throw here?
      : [];
  const temporalityFilter =
    query.dataType === DataTypes.EMPLOYEE &&
    !query.filterItems?.find(
      (fi) => fi.dataType === DataTypes.EMPLOYEE && fi.property === EmployeeDataFields.EMPLOYMENT_TEMPORALITY
    )
      ? [PRESENT_TEMPORALITY_FILTER]
      : [];

  const finalFilters = [...(query.filterItems ?? []), ...versionIdFilter, ...temporalityFilter];

  const advancedQuery: ApiMasterDataAdvancedQuery = {
    dataType: query.dataType as DataTypes,
    dimensions:
      finalDimensions?.map((d) => ({ dataType: query.dataType as DataTypes, property: d as DataFields })) ?? [],
    filterItems: finalFilters,
    measures: finalMeasures,
    limitedToPermittedPopulation: query.limitedToPermittedPopulation,
    limit: query.limit,
    offset: query.offset,
    disableNestLoop: true,
  };
  return advancedQuery;
};

export const advancedQueryToCustomSqlQuery = async (query: ApiMasterDataAdvancedQuery): Promise<string> => {
  // Without await import, whole pgsql-ast-library gets shipped into the entrypoint chunk which is not desirable
  const QueryToASTConverter = (await import('../api/query-to-sql-converter')).default;

  // Converting to SQL queries here, because they implement a different (better) way in calculating the population count
  // This way works for non-employee datatypes as well, as long as the requesting role is e.g. superadmin. It will NOT work with population restrictions.
  const converter = new QueryToASTConverter(query as V2ApiMasterDataAdvancedQuery);
  const sql = converter.querytoSql();
  return sql;
};
