import { action, computed, observable } from 'mobx';
import i18n from '../../../i18n';
import { Granularity } from '../date-manager/date-manager-constants';
import { dateManagerService } from '../date-manager/date-manager-service';
import { TimeSliderStore } from '../timeslider/timeslider-store';
import { range } from '../utilFunctions/utils';

// export type ForecastType = 'linear' | 'actual' | 'none';

export interface ForecastType {
  type: string;
  label: string;
}

export interface ForecastTimeOption {
  label: string;
  getForecastTillDate: (lastDate: string) => string;
}

export interface ForecastConfig {
  forecastType: ForecastType | null;
  forecastTimeOption: ForecastTimeOption;
}

export class ForecastStore {
  private timeSliderStore: TimeSliderStore;
  public constructor(timeSliderStore: TimeSliderStore) {
    this.timeSliderStore = timeSliderStore;
  }
  public availableForecastTypes: ForecastType[] = [
    {
      type: 'linear',
      label: i18n.t('common:forecast.type.linear'),
    },
    {
      type: 'actual',
      label: i18n.t('common:forecast.type.actual'),
    },
  ];

  @computed
  public get forecastTimeOptions(): ForecastTimeOption[] {
    const { formatDateApi, formatDateShort, parseApiDate } = dateManagerService;
    const fixedTimeOptions = [
      {
        label: `3 ${i18n.t('common:commonValues.misc.months')}`,
        getForecastTillDate: (lastDate: string) =>
          formatDateApi(parseApiDate(lastDate).add(3, Granularity.MONTH).endOf(Granularity.MONTH)),
      },
      {
        label: `6 ${i18n.t('common:commonValues.misc.months')}`,
        getForecastTillDate: (lastDate: string) =>
          formatDateApi(parseApiDate(lastDate).add(6, Granularity.MONTH).endOf(Granularity.MONTH)),
      },
    ];

    const { endDate } = this.timeSliderStore.timeSliderConfig;
    if (endDate) {
      const monthlyTimeOptions = range(1, 12).map((num) => {
        const currentDate = parseApiDate(endDate).add(num, Granularity.MONTH).endOf(Granularity.MONTH);
        return {
          label: i18n.t('common:forecast.toEndOf', {
            forecastEndDate: formatDateShort(currentDate),
          }),
          getForecastTillDate: () => formatDateApi(currentDate),
        };
      });
      const forecastTimeOptions = [...fixedTimeOptions, ...monthlyTimeOptions];
      return forecastTimeOptions;
    }
    return fixedTimeOptions;
  }

  @observable
  public forecastType: ForecastType | null = null;

  @observable
  public forecastTimeOption: ForecastTimeOption = this.forecastTimeOptions[0];

  @action
  public setForecastTimeOption = (forecastTimeOption: ForecastTimeOption) => {
    this.forecastTimeOption = forecastTimeOption;
  };

  @action
  public toggleForecastType = (ft: ForecastType) => {
    if (this.isSameForecast(ft, this.forecastType)) {
      this.forecastType = null;
    } else {
      const forecastType = this.availableForecastTypes.find((ft2) => this.isSameForecast(ft, ft2));
      if (forecastType) {
        this.forecastType = ft;
      }
    }
  };

  public isSameForecast = (forecastTypeA: ForecastType | null, forecastTypeB: ForecastType | null) => {
    return forecastTypeA && forecastTypeB && forecastTypeA.type === forecastTypeB.type;
  };

  @action
  public setForecastType = (forecastType: ForecastType) => {
    this.forecastType = forecastType;
  };

  @action
  public resetForecast = () => {
    this.forecastType = null;
    this.forecastTimeOption = this.forecastTimeOptions[0];
  };

  @computed
  public get forecastConfig(): ForecastConfig | null {
    return this.forecastType
      ? {
          forecastType: this.forecastType,
          forecastTimeOption: this.forecastTimeOption,
        }
      : null;
    // Its easier to check for existence if it becomes null when not selected
  }

  @computed
  public get forecastTillDate(): string {
    const forecastTillDate = this.forecastTimeOption.getForecastTillDate(this.timeSliderStore.timeSliderConfig.endDate);
    return forecastTillDate;
  }
}
