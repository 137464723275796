import moment, { Moment } from 'moment';
import { Granularity, Months } from './date-manager-constants';

const getMonthNumberFromMonth = (mth: Months) => {
  return moment().month(mth).month() + 1; // 1 indexed
};

export const getFiscalYear = (date: Moment, firstMonthOfYear: Months) => {
  const firstMonthNum = getMonthNumberFromMonth(firstMonthOfYear);
  const dateCopy = date.clone();
  const month = dateCopy.month() + 1;
  let year = dateCopy.year();
  if (month < firstMonthNum) {
    year -= 1; // Current fiscal year started in the previous calendar year
  }
  return year;
};

export const getStartOfFiscalYear = (date: Moment, firstMonthOfYear: Months) => {
  const firstMonthNum = getMonthNumberFromMonth(firstMonthOfYear);
  const dateCopy = date.clone();
  const month = dateCopy.month() + 1;
  let year = dateCopy.year();
  if (month < firstMonthNum) {
    year -= 1; // Current fiscal year started in the previous calendar year
  }
  return moment()
    .month(firstMonthNum - 1)
    .year(year)
    .startOf(Granularity.MONTH);
};

export const getEndOfFiscalYear = (date: Moment, firstMonthOfYear: Months) => {
  const startOfFiscalYear = getStartOfFiscalYear(date, firstMonthOfYear);
  const endOfFiscalYear = startOfFiscalYear
    .add(1, Granularity.YEAR)
    .subtract(1, Granularity.MONTH)
    .endOf(Granularity.MONTH);
  return endOfFiscalYear;
};

export const getFiscalQuarter = (date: Moment, firstMonthOfYear: Months) => {
  const firstMonthNum = getMonthNumberFromMonth(firstMonthOfYear);
  const month = date.month() + 1;
  const deltaFromStartMonth = month >= firstMonthNum ? month - firstMonthNum + 1 : 12 - firstMonthNum + month + 1;
  return Math.ceil(deltaFromStartMonth / 3);
};

const getStartOfProvidedQuarter = (date: Moment, firstMonthOfYear: Months, quarterNum: number) => {
  const startOfFiscalYear = getStartOfFiscalYear(date, firstMonthOfYear);
  const startOfQuarterDate = startOfFiscalYear.add((quarterNum - 1) * 3, Granularity.MONTH).startOf(Granularity.MONTH);
  return startOfQuarterDate;
};

export const getEndOfProvidedQuarter = (date: Moment, firstMonthOfYear: Months, quarterNum: number) => {
  const endOfQuarterDate = getStartOfProvidedQuarter(date, firstMonthOfYear, quarterNum)
    .add(2, Granularity.MONTH)
    .endOf(Granularity.MONTH);
  return endOfQuarterDate;
};

export const getStartOfQuarter = (date: Moment, firstMonthOfYear: Months) => {
  const dateCopy = date.clone();
  const quarterNum = getFiscalQuarter(dateCopy, firstMonthOfYear);
  return getStartOfProvidedQuarter(dateCopy, firstMonthOfYear, quarterNum);
};

export const getEndOfQuarter = (date: Moment, firstMonthOfYear: Months) => {
  const dateCopy = date.clone();
  const quarterNum = getFiscalQuarter(dateCopy, firstMonthOfYear);
  return getEndOfProvidedQuarter(dateCopy, firstMonthOfYear, quarterNum);
};

export const getNumDaysInTimePeriod = (startDate: Moment, endDate: Moment) =>
  endDate.diff(startDate, Granularity.DAY) + 1;

export const getNumMonthsInTimePeriod = (startDate: Moment, endDate: Moment) =>
  endDate.diff(startDate, Granularity.MONTH) + 1;
