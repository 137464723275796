import { appCache, ICache } from '../cache/cache';
import { Domains } from '../constants/constants';
import { AliasService, IAliasService } from './alias-service';
import {
  NewApiAlias,
  NewApiAliasForDataField,
  NewApiAliasForMetricCategoryId,
  NewApiAliasForMetricGroupId,
} from './api-interfaces';

export class CachedAliasService implements IAliasService {
  private cache: ICache;
  private aliasService: AliasService;

  constructor(underlying: AliasService, cache: ICache = appCache) {
    this.cache = cache;
    this.aliasService = underlying;
  }

  public clearCache() {
    this.cache.clear();
  }

  public getAliasesForDataFields(domain: string): Promise<NewApiAliasForDataField[]> {
    const cacheKey = JSON.stringify(['getAliasesForDataFields', domain]);
    return this.cache.getFromCacheOrRequest<NewApiAliasForDataField[]>(cacheKey, () =>
      this.aliasService.getAliasesForDataFields(domain as Domains)
    );
  }

  public getAliasesForMetricGroupId(domain: string): Promise<NewApiAliasForMetricGroupId[]> {
    const cacheKey = JSON.stringify(['getAliasesForMetricGroupId', domain]);
    return this.cache.getFromCacheOrRequest<NewApiAliasForMetricGroupId[]>(cacheKey, () =>
      this.aliasService.getAliasesForMetricGroupId(domain as Domains)
    );
  }

  public getAliasesForMetricCategoryId(domain: string): Promise<NewApiAliasForMetricCategoryId[]> {
    const cacheKey = JSON.stringify(['getAliasesForMetricCategoryId', domain]);
    return this.cache.getFromCacheOrRequest<NewApiAliasForMetricCategoryId[]>(cacheKey, () =>
      this.aliasService.getAliasesForMetricCategoryId(domain as Domains)
    );
  }

  public getAliases(domain: string): Promise<NewApiAlias[]> {
    const cacheKey = JSON.stringify(['getAliases', domain]);
    return this.cache.getFromCacheOrRequest<NewApiAlias[]>(cacheKey, () =>
      this.aliasService.getAliases(domain as Domains)
    );
  }

  public async setAliasForDataField(domain: Domains, alias: NewApiAliasForDataField): Promise<void> {
    return this.aliasService.setAliasForDataField(domain, alias);
  }

  public async setAliasForMetricGroupId(domain: Domains, alias: NewApiAliasForMetricGroupId): Promise<void> {
    return this.aliasService.setAliasForMetricGroupId(domain, alias);
  }

  public async setAliasForMetricCategoryId(domain: Domains, alias: NewApiAliasForMetricCategoryId): Promise<void> {
    return this.aliasService.setAliasForMetricCategoryId(domain, alias);
  }

  public async deleteAliasForDataField(domain: Domains, alias: NewApiAliasForDataField): Promise<void> {
    return this.aliasService.deleteAliasForDataField(domain, alias);
  }

  public async deleteAliasForMetricGroupId(domain: Domains, alias: NewApiAliasForMetricGroupId): Promise<void> {
    return this.aliasService.deleteAliasForMetricGroupId(domain, alias);
  }

  public async deleteAliasForMetricCategoryId(domain: Domains, alias: NewApiAliasForMetricCategoryId): Promise<void> {
    return this.aliasService.deleteAliasForMetricCategoryId(domain, alias);
  }
}
