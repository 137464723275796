import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar, SnackbarContent, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CommonSnackbarProps {
  open: boolean;
  onClose?: () => void;
  message: any;
  background?: string;
  autoHide?: boolean;
  notCloseable?: boolean;
}

const StyledSnackBarContent = styled(SnackbarContent as any)<{
  background: string;
}>`
  && {
    background-color: ${(props) => props.background};
    & span {
      display: flex;
      align-items: center;
    }
    max-width: 50vw;
  }
`;

export const CommonSnackbar = (props: CommonSnackbarProps) => {
  const { open, onClose, message, background, notCloseable, autoHide = true } = props;
  const { t } = useTranslation();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={autoHide ? 6000 : null}
      onClose={(_, reason) => {
        if (reason === 'timeout') {
          onClose && onClose();
        }
      }}
    >
      <StyledSnackBarContent
        aria-describedby="client-snackbar"
        message={<span id="client-snackbar">{message}</span>}
        background={background}
        action={[
          !notCloseable && (
            <IconButton
              key="close"
              aria-label={t('common:commonValues.misc.close')}
              color="inherit"
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ),
        ]}
      />
    </Snackbar>
  );
};

export const customSnackbarAction = (snackbarId: string | number) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <CancelIcon
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    />
  );
};
