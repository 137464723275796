import { draw } from 'patternomaly';
import datConfidentialtyIcon from '../../../assets/image/dataConfidentiality.png';
import { DataFieldWithDataType } from '../../../common-types';
import { $UndefinedValueIndicator$ } from '../api/api-interfaces';
import { BENCHMARK, FORECAST } from '../benchmark/benchmark-store';
import {
  ByDimensionChartSortValueGetter,
  ByDimensionChartStrategy,
  DatasetInfo,
} from '../components/charts/ByDimensionChart';
import { AxisTypes, CONFIDENTIAL_VALUE } from '../constants/constants';
import { getChartColorsForByDimChart } from '../filter/utils';
import { ForecastConfig } from '../forecast/forecast-store';
import { forecastedResultStackId, nonForecastedResultStackId } from '../forecast/forecast-utils';
import { axisColorMap, benchmarkColor, chartColors } from '../theme/color-constants';
import { sortChartLabelsNew, SortTypes } from './sorters';
import { ratioWithoutFormatting, sum } from './utils';

export type ValueGetter = (props: ValueGetterParams) => number | string;
export interface BuildChartDataInputs {
  data: any;
  strategy?: ByDimensionChartStrategy;
  baseDim: DataFieldWithDataType;
  chartType: string;
  dataKey: string; // This will also need to support multiple datakeys eg - median base and headcount
  dataKeys?: string[];
  secondaryKeys?: string[];
  datasetLabel?: string;
  datasetLabels?: string[]; // datasetLabels need to come filtered to the ones I need to use
  datasetInfos?: DatasetInfo[];
  sortType?: SortTypes;
  benchmarkEnabled?: boolean;
  percentage?: boolean;
  valueGetter?: ValueGetter;
  sortValueGetter?: ByDimensionChartSortValueGetter<ByDimensionChartStrategy>;
  forecastConfig?: ForecastConfig | null;
}

export interface ValueGetterParams {
  dataMap: any;
  l1: string;
  l2?: string;
  l3?: string;
  defaultValue?: string | number;
}

const buildConfidentialityDataset = (data: any, secondaryValues: any, chartType: string) => {
  const confidentialityMapping = (datum: any) => (datum === CONFIDENTIAL_VALUE ? 0 : datum);
  const newSecondaryValues: Record<string, any> = {};
  Object.keys(secondaryValues).forEach((key) => {
    newSecondaryValues[key] = secondaryValues[key].map(confidentialityMapping);
  });
  if (chartType !== 'doughnut') {
    const confIcon = new Image(30, 30);
    confIcon.src = datConfidentialtyIcon;
    return {
      label: CONFIDENTIAL_VALUE,
      fill: false,
      pointStyle: data.map((datum: any) => (datum === CONFIDENTIAL_VALUE ? confIcon : 'circle')),
      pointWidth: data.map((datum: any) => (datum === CONFIDENTIAL_VALUE ? 5 : 0)),
      data: data.map(confidentialityMapping),
      secondaryValues,
      radius: 0,
      showLine: false,
      type: 'line',
    };
  }
  return null;
};

export const buildChartDataFromResponseData = (buildChartDataInputs: BuildChartDataInputs): any => {
  const {
    data,
    strategy,
    baseDim,
    chartType,
    datasetLabel,
    datasetInfos,
    dataKey,
    secondaryKeys,
    sortType,
    benchmarkEnabled = false,
    percentage = false,
    valueGetter,
    sortValueGetter,
    forecastConfig,
    dataKeys,
  } = buildChartDataInputs;
  const finalValueGetter: ValueGetter = ({ dataMap, l1, l2, l3 }) => {
    return (
      (valueGetter &&
        valueGetter({
          dataMap,
          l1,
          l2,
          l3: dataKey,
        })) ||
      defaultValueGetter({
        dataMap,
        l1,
        l2,
        l3: dataKey,
        defaultValue: 0,
      }) ||
      0
    );
  };
  const { primaryResponse, benchmarkResponse } = data;
  const { dataMap, labels: primaryLabels, forecastDataMap } = primaryResponse;
  const { dataMap: benchmarkDataMap, labels: benchmarkLabels } = benchmarkResponse || { dataMap: {}, labels: [] };
  let datasets: any[] = [];
  const dataLabels = Array.from(new Set([...primaryLabels, ...benchmarkLabels]));
  const labels = sortChartLabelsNew({
    labels: dataLabels,
    dataMap,
    baseDim,
    chartType,
    dataKey,
    sortType,
    strategy,
    sortValueGetter,
  });

  const hasBenchmark = benchmarkEnabled && benchmarkResponse !== null;
  const hasForecast = forecastConfig && forecastDataMap;

  if ((hasBenchmark || hasForecast) && strategy === 'basic' && chartType !== 'doughnut') {
    const secondDataMap = hasForecast ? forecastDataMap : benchmarkDataMap;
    const secondDatasetLabel = hasForecast ? FORECAST : BENCHMARK;
    const colors = getChartColorsForByDimChart(baseDim, chartType, labels);
    datasets = [dataMap, secondDataMap].map((dMap, index) => {
      const d: any[] = [];
      const secondaryValues: Record<string, any> = {};
      let backgroundColor;
      let newDatasetLabel;
      if (index === 0) {
        backgroundColor = colors;
        newDatasetLabel = datasetLabel;
      } else {
        backgroundColor = benchmarkColor;
        newDatasetLabel = `${secondDatasetLabel} - ${datasetLabel}`;
      }

      const total = labels.reduce((acc, l1) => {
        const value = (dMap[l1] && dMap[l1][dataKey]) || 0;
        return acc + value;
      }, 0);

      labels.forEach((l1: string) => {
        const value = (dMap[l1] && dMap[l1][dataKey]) || 0;

        if (percentage) {
          d.push((value * 100) / total);
        } else {
          d.push(value);
        }

        secondaryKeys &&
          secondaryKeys.forEach((key) => {
            if (!secondaryValues[key]) {
              secondaryValues[key] = [];
            }
            const secondaryValue = (dMap[l1] && dMap[l1][key]) || 0;
            secondaryValues[key].push(secondaryValue);
          });
      });

      const dataset = {
        key: newDatasetLabel,
        label: newDatasetLabel,
        fill: false,
        backgroundColor,
        borderColor: backgroundColor,
        data: d,
        secondaryValues,
      };
      return dataset;
    });
  } else if (hasBenchmark && strategy === 'stacked' && chartType !== 'doughnut') {
    datasetInfos?.forEach(({ datasetLabel: label, datasetKey, datasetColor }, i: number) => {
      const color = datasetColor || chartColors[i];
      const d: any[] = [];
      const total = labels.reduce((acc, l1) => {
        const value = (dataMap[l1] && dataMap[l1][datasetKey] && dataMap[l1][datasetKey][dataKey]) || 0;

        return acc + value;
      }, 0);

      labels.forEach((l1: string) => {
        const value = (dataMap[l1] && dataMap[l1][datasetKey] && dataMap[l1][datasetKey][dataKey]) || 0;

        if (percentage) {
          d.push((value * 100) / total);
        } else {
          d.push(value);
        }
      });
      datasets.push({
        label,
        stack: 'primary',
        backgroundColor: color,
        borderColor: color,
        data: d,
      });
    });

    datasetInfos &&
      datasetInfos.forEach(({ datasetLabel: label, datasetKey, datasetColor }, i: number) => {
        const color = datasetColor || chartColors[i];
        const d: any[] = [];
        const total = labels.reduce((acc, l1) => {
          const value =
            (benchmarkDataMap[l1] && benchmarkDataMap[l1][datasetKey] && benchmarkDataMap[l1][datasetKey][dataKey]) ||
            0;

          return acc + value;
        }, 0);

        labels.forEach((l1: string) => {
          const value =
            (benchmarkDataMap[l1] && benchmarkDataMap[l1][datasetKey] && benchmarkDataMap[l1][datasetKey][dataKey]) ||
            0;

          if (percentage) {
            d.push((value * 100) / total);
          } else {
            d.push(value);
          }
        });
        const pattern = draw('dot', color);
        datasets.push({
          label: `${BENCHMARK} - ${label}`,
          stack: 'benchmark',
          backgroundColor: pattern,
          borderColor: pattern,
          data: d,
        });
      });
  } else if ((hasBenchmark || hasForecast) && (strategy === 'grouped' || strategy === 'stacked')) {
    datasetInfos &&
      datasetInfos.forEach(
        ({ datasetLabel: label, datasetKey, datasetColor, usesRightAxis, valueFormatter }, i: number) => {
          const color = datasetColor || chartColors[i];
          const dataKeyToUse = dataKeys ? dataKeys[i] : dataKey;
          const d: any[] = [];
          const total = labels.reduce((acc, l1) => {
            const value = dataMap[l1]?.[datasetKey]?.[dataKeyToUse] || 0;
            return acc + value;
          }, 0);

          labels.forEach((l1: string) => {
            const value = dataMap[l1]?.[datasetKey]?.[dataKeyToUse] || 0;
            if (percentage) {
              d.push((value * 100) / total);
            } else {
              d.push(value);
            }
          });

          datasets.push({
            label,
            backgroundColor: color,
            borderColor: color,
            data: d,
            stack: strategy === 'stacked' ? nonForecastedResultStackId : undefined,
            ...(usesRightAxis && { yAxisID: 'y1' }),
            ...(valueFormatter && { valueFormatter: valueFormatter }),
          });
        }
      );
    // Generate Benchmark or forecast data sets
    const secondDataMap = hasForecast ? forecastDataMap : benchmarkDataMap;
    const secondDatasetLabel = hasForecast ? FORECAST : BENCHMARK;
    datasetInfos &&
      datasetInfos.forEach(
        ({ datasetLabel: label, datasetKey, datasetColor, usesRightAxis, valueFormatter }, i: number) => {
          const dataKeyToUse = dataKeys ? dataKeys[i] : dataKey;
          const color = datasetColor || chartColors[i];
          const d: any[] = [];
          const total = labels.reduce((acc, l1) => {
            const value = secondDataMap[l1]?.[datasetKey]?.[dataKeyToUse] || 0;
            return acc + value;
          }, 0);

          labels.forEach((l1: string) => {
            const value = secondDataMap[l1]?.[datasetKey]?.[dataKeyToUse] || 0;
            if (percentage) {
              d.push((value * 100) / total);
            } else {
              d.push(value);
            }
          });
          const pattern = draw('diagonal', color);
          datasets.push({
            label: `${secondDatasetLabel} - ${label}`,
            backgroundColor: pattern,
            borderColor: pattern,
            data: d,
            stack: strategy === 'stacked' ? forecastedResultStackId : undefined,
            ...(usesRightAxis && { yAxisID: 'y1' }),
            ...(valueFormatter && { valueFormatter: valueFormatter }),
          });
        }
      );
  } else if (strategy === 'basic') {
    const colors = getChartColorsForByDimChart(baseDim, chartType, labels);
    const d: any[] = [];
    const total = labels.reduce((acc, l1) => {
      const value = (dataMap[l1] && dataMap[l1][dataKey]) || 0;
      return acc + value;
    }, 0);

    const secondaryValues: Record<string, any> = {};
    labels.forEach((l1: string) => {
      const value = (dataMap[l1] && dataMap[l1][dataKey]) || 0;
      if (percentage) {
        d.push((value * 100) / total);
      } else {
        d.push(value);
      }

      secondaryKeys &&
        secondaryKeys.forEach((key) => {
          if (!secondaryValues[key]) {
            secondaryValues[key] = [];
          }
          const secondaryValue = (dataMap[l1] && dataMap[l1][key]) || 0;
          secondaryValues[key].push(secondaryValue);
        });
    });
    const dataset = {
      key: datasetLabel,
      label: datasetLabel,
      fill: false,
      backgroundColor: colors,
      borderColor: colors,
      data: d,
      secondaryValues,
    };
    const confidentialityDataset = buildConfidentialityDataset(d, secondaryValues, chartType);
    datasets.push(dataset);
    if (confidentialityDataset) {
      datasets.push(confidentialityDataset);
    }
  } else if (strategy === 'stacked' && chartType === 'doughnut') {
    labels &&
      labels.forEach((l1: string) => {
        const total =
          datasetInfos?.reduce((sum: number, { datasetKey }) => {
            const value = finalValueGetter({ dataMap, l1, l2: datasetKey, l3: dataKey });
            if (!isNaN(Number(value))) {
              return sum + Number(value);
            }
            return sum;
          }, 0) || 0;
        const d: any[] = [];
        datasetInfos &&
          datasetInfos.forEach(({ datasetKey }) => {
            const value = finalValueGetter({ dataMap, l1, l2: datasetKey, l3: dataKey });
            if (percentage) {
              d.push((Number(value) * 100) / total);
            } else {
              d.push(value);
            }
          });
        datasets.push({
          label: l1,
          backgroundColor: chartColors.slice(0, d.length),
          borderColor: chartColors.slice(0, d.length),
          data: d,
          yAxisID: 'y',
        });
      });
    // Since labels here are different we will return early.
    const chartDataa: Chart.ChartData = {
      labels: datasetInfos && datasetInfos.map(({ datasetLabel: label }) => label),
      datasets,
    };
    return chartDataa;
  } else if (strategy === 'stacked') {
    if (datasetInfos) {
      datasetInfos.forEach(({ datasetLabel: label, datasetKey, datasetColor }, i: number) => {
        const color = datasetColor || chartColors[i];
        const d: any[] = [];
        labels.forEach((l1: string) => {
          const total = sum(
            datasetInfos.map(({ datasetKey }) => {
              return Number(finalValueGetter({ dataMap, l1, l2: datasetKey, l3: dataKey }));
            })
          );
          const value = finalValueGetter({ dataMap, l1, l2: datasetKey, l3: dataKey });
          if (percentage) {
            d.push(ratioWithoutFormatting(Number(value) * 100, total));
          } else {
            d.push(value);
          }
        });
        datasets.push({
          label,
          backgroundColor: color,
          borderColor: color,
          data: d,
          yAxisID: 'y',
        });
      });
    }
  } else if (strategy === 'grouped') {
    datasetInfos &&
      datasetInfos.forEach(
        ({ datasetLabel: label, datasetKey, datasetColor, usesRightAxis, valueFormatter }, i: number) => {
          const dataKeyToUse = dataKeys ? dataKeys[i] : dataKey;
          const color = datasetColor || chartColors[i];
          const d: any[] = [];
          const total = labels.reduce((acc, l1) => {
            const value = dataMap[l1]?.[datasetKey]?.[dataKeyToUse] || 0;
            return acc + value;
          }, 0);

          labels.forEach((l1: string) => {
            const value = dataMap[l1]?.[datasetKey]?.[dataKeyToUse] || 0;
            if (percentage) {
              d.push(ratioWithoutFormatting(Number(value) * 100, total));
            } else {
              d.push(value);
            }
          });

          datasets.push({
            label,
            backgroundColor: color,
            borderColor: color,
            data: d,
            ...(usesRightAxis && { yAxisID: 'y1' }),
            ...(valueFormatter && { valueFormatter: valueFormatter }),
          });
        }
      );
  } else if (strategy === 'groupedAndStacked') {
    datasetInfos?.forEach(({ datasetLabel: label, datasetKey, datasetColor }, i: number) => {
      const color = datasetColor || chartColors[i];
      Object.entries(dataMap as object)
        .reverse()
        .forEach(([key, value]) => {
          const d: any[] = [];

          labels.forEach((l1: string) => {
            const total = sum(
              datasetInfos.map(({ datasetKey }) => {
                return Number(value[l1]?.[datasetKey] ?? 0);
              })
            );
            const dataValue = value[l1]?.[datasetKey] ?? 0;
            if (percentage) {
              d.push(ratioWithoutFormatting(Number(dataValue) * 100, total));
            } else {
              d.push(dataValue);
            }
          });

          datasets.push({
            label: `${key} ${label}`,
            data: d,
            yAxisID: 'y',
            stack: key,
            backgroundColor: color,
            borderColor: color,
          });
        });
    });
  } else if (strategy === 'groupedWithMarker') {
    datasetInfos?.forEach(({ datasetLabel, datasetKey, usesRightAxis, datasetColor, hideLabel }, i: number) => {
      const color = datasetColor || chartColors[i];
      const d: any[] = [];
      const total = labels.reduce((acc, l1) => {
        const value = (dataMap[l1] && dataMap[l1][datasetKey]) || 0;

        return acc + value;
      }, 0);

      labels.forEach((l1: string) => {
        const value = (dataMap[l1] && dataMap[l1][datasetKey]) || 0;
        if (percentage) {
          d.push((value * 100) / total);
        } else {
          d.push(value);
        }
      });

      if (usesRightAxis) {
        datasets.push({
          yAxisID: 'y1',
          label: datasetLabel,
          backgroundColor: hideLabel ? 'rgba(0,0,0, 0)' : axisColorMap[AxisTypes.RIGHT],
          borderColor: axisColorMap[AxisTypes.RIGHT],
          barPercentage: 0.6,
          borderWidth: { top: 7, right: 0, bottom: 0, left: 0 },
          data: d,
          order: 0,
        });
      } else {
        datasets.push({
          label: datasetLabel,
          backgroundColor: color,
          borderColor: color,
          data: d,
          order: 1,
        });
      }
    });
  }

  // code to remove zero values from datasets and labels
  const labelsArrWithNonZeroFlag = labels.map((label, i) => {
    const isNonZeroForSomeDataset = datasets.some((d) => (d.data?.[i] ? Boolean(d.data[i]) : false));
    return { label, nonZero: isNonZeroForSomeDataset };
  });
  const nonZeroLabels = labelsArrWithNonZeroFlag.filter((l) => l.nonZero).map((l) => l.label);
  const datasetsForNonZeroLabels = datasets.map((dataset) => {
    const newDataset = { ...dataset };
    Object.entries(newDataset).forEach(([key, value]) => {
      const isValueAnArrayMappedFromLabels = Array.isArray(value) && value.length === labels.length;
      // eg - data, secondaryValues, backgroundColor, etc
      if (isValueAnArrayMappedFromLabels) {
        // We recompute it using nonZeroLabels
        const filteredValue = value.filter((_, i) => labelsArrWithNonZeroFlag[i].nonZero);
        newDataset[key] = filteredValue;
      }
    });
    return newDataset;
  });
  const chartData: Chart.ChartData = {
    labels: nonZeroLabels,
    datasets: datasetsForNonZeroLabels,
  };

  return chartData;
};

export const isValue = (val: any) => typeof val !== 'object';
const defaultValueGetter: (props: ValueGetterParams) => string | number = ({
  dataMap,
  l1,
  l2,
  l3,
  defaultValue,
}: ValueGetterParams) => {
  if (!dataMap) {
    return defaultValue || $UndefinedValueIndicator$;
  } else {
    if (l1 && isValue(dataMap[l1])) {
      return dataMap[l1];
    } else if (l1 && l2 && isValue(dataMap[l1][l2])) {
      return dataMap[l1][l2];
    } else if (l1 && l2 && l3 && isValue(dataMap[l1][l2][l3])) {
      return dataMap[l1][l2][l3];
    } else {
      return defaultValue || $UndefinedValueIndicator$;
    }
  }
};
