import { observer } from 'mobx-react';
import React from 'react';
import LoadingOverlay from '../../common/components/utils/LoadingOverlay';
import { rootStore } from '../../common/store/root-store';

export const DomainDependenciesGuard: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  const { domainDependenciesReady, couldNotFetchDomainDependencies } = rootStore.domainDependencyStore;

  // We rely on error handling in the MainContentGuard
  if (!domainDependenciesReady() && !couldNotFetchDomainDependencies) {
    return <LoadingOverlay />;
  } else {
    return <>{children}</>;
  }
});
