import { InMemoryCache } from './in-memory-cache';

export interface CacheItem {
  key: string;
  data: any;
  createdAt: Date;
}

export interface ICache {
  get(key: string): CacheItem;
  add(key: string, value: any): void;
  clear(): void;
  getFromCacheOrRequest<T>(cacheKey: string, f: () => Promise<T>): Promise<T>;
}

export const appCache = new InMemoryCache();
