import { createBrowserHistory } from 'history';
import { RequestService } from './api/request-service';
import { trackRouteChange } from './helpers/trackers/routeTracker';

export class RouteStore {
  public browserHistory = createBrowserHistory();

  constructor(requestService: RequestService) {
    this.browserHistory.listen((location) => {
      trackRouteChange(location);

      requestService.staleRequestsMap.forEach((request) => request.abort('Request is stale'));
    });
  }
}
