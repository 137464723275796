import { EnabledFeaturesService } from './enabled-features-service';
import { IPermissionsStore } from './permissions/permissions-store';

export class FeatureAccessService {
  /** Use this to decide feature access for a given role based on combination of
   * enabled-features-service and user permissions
   */

  public enabledFeaturesService: EnabledFeaturesService;
  public permissionsStore: IPermissionsStore;
  constructor(enabledFeaturesService: EnabledFeaturesService, permissionsStore: IPermissionsStore) {
    this.enabledFeaturesService = enabledFeaturesService;
    this.permissionsStore = permissionsStore;
  }

  public canViewUserPermissions = () => {
    return this.enabledFeaturesService.isPermissionsUiEnabled() && this.permissionsStore.canReadUserPermissions();
  };

  public canWriteUserPermissions = () => {
    return this.enabledFeaturesService.isPermissionsUiEnabled() && this.permissionsStore.canReadUserPermissions();
  };

  public canViewRoleSettings = () => {
    if (this.enabledFeaturesService.isCrextaPermissionsView()) {
      return false;
    } else {
      return this.enabledFeaturesService.isPermissionsUiEnabled() && this.permissionsStore.canReadRoleSettings();
    }
  };

  public canWriteRoleSettings = () => {
    if (this.enabledFeaturesService.isCrextaPermissionsView()) {
      return false;
    } else {
      return this.enabledFeaturesService.isPermissionsUiEnabled() && this.permissionsStore.canWriteRoleSettings();
    }
  };
}
