export const screenSize: Record<string, number> = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptop13: 1200,
  laptopL: 1440,
  desktopS: 1700,
  desktopL: 2560,
};

export const device: Record<string, string> = {
  mobileS: `(min-width: ${screenSize.mobileS}px)`,
  mobileM: `(min-width: ${screenSize.mobileM}px)`,
  mobileL: `(min-width: ${screenSize.mobileL}px)`,
  tablet: `(min-width: ${screenSize.tablet}px)`,
  laptop: `(min-width: ${screenSize.laptop}px)`,
  laptop13: `(min-width: ${screenSize.laptop13}px)`,
  laptopL: `(min-width: ${screenSize.laptopL}px)`,
  desktopS: `(min-width: ${screenSize.desktopS}px)`,
  desktopL: `(min-width: ${screenSize.desktopL}px)`,
};
